//@@ Constants
import { WEB_INSIGHTS_TYPE } from '@src/views/apps/reports/constants'

//@@ Utils
import { useInsightsActions } from '@src/views/apps/reports/features/WebInsights/hooks/useInsightsActions'

//@@ Components
import { ReportWrapper } from '@src/views/apps/reports/features/WebInsights/components/ReportWrapper/ReportWrapper'
import { ReportFormWrapper } from '@src/views/apps/reports/features/WebInsights/components/ReportFormWrapper/ReportFormWrapper'
import { IndividualSettings } from '@src/views/apps/reports/features/WebInsights/features/Individual/components/IndividualSettings'
import { IndividualResults } from '@src/views/apps/reports/features/WebInsights/features/Individual/components/IndividualResults'

export const IndividualReport = () => {
  const { isShown, handleShowToggle } = useInsightsActions()

  return (
    <>
      <ReportFormWrapper type={WEB_INSIGHTS_TYPE.individual}>
        <ReportWrapper onSaveClick={handleShowToggle}>
          <IndividualSettings />
        </ReportWrapper>
        {isShown && <IndividualResults />}
      </ReportFormWrapper>
    </>
  )
}
