//@@ Libs
import moment from 'moment'
import phoneNumberFormatter from 'phone-number-formats'

//@@ Constants
import { DefaultRoute } from '../router/routes'
import { ADMIN_ROLE_NAME } from '@configs/environment'
import { DEFAULT_SHORT_DATE_FORMAT } from 'constants/datetime'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === ADMIN_ROLE_NAME) return '/churches'
  if (userRole === 'client') return '/access-control'
  return DefaultRoute // '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const slugify = (text) => {
  return text
    .toString() // Cast to string (optional)
    .normalize('NFKD') // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
    .toLowerCase() // Convert the string to lowercase letters
    .trim() // Remove whitespace from both sides of a string (optional)
    .replace(/\s+/g, '_') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '_') // Replace multiple - with single -
}

export const createActionTypeFactoryFn = (prefix) => (actionType) => `${prefix}/${actionType}`

export const transformToOptions = (values) => {
  if (!Array.isArray(values)) {
    return
  }
  return values.map((v) => ({ value: v, label: v }))
}

export const transformToRawOption = (value) => {
  return { value, label: value }
}

export const getOptionFromRawValue = ({ rawValue, options }) => {
  return options?.find(({ value }) => value === rawValue) ?? null
}

export const getOptionsFromRawValues = ({ rawValue, options }) => {
  if (rawValue[0] === "N\\A") {
    return [{ value: 'N\\A', label: 'N\\A' }]
  }
  return options?.filter(({ value }) => rawValue?.includes(value))
}

export const getRawValueFromOption = (option) => option.value

export const getRawValuesFromOptions = (options) => options.map(({ value }) => value)

export const rawDateRangeToDate = (dates) => dates.map((v) => new Date(v))

export const dateRangeToRaw = (dates) => dates.map((v) => moment(v).utc().toISOString())

export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return '-'
  }
  return new phoneNumberFormatter(phoneNumber).format({ type: 'international', separator: '.' }).string
}

export const getReadableDate = (date) => moment(date).format(DEFAULT_SHORT_DATE_FORMAT)

export function arrayToCsv(data) {
  return data
    .map(
      (row) => {
        return row
          .map(String) // convert every value to String
          .map((v) => v.replaceAll('"', '""')) // escape double colons
          .join(',')
      } // comma-separated
    )
    .join('\r\n') // rows starting on new lines
}
