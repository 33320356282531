//@@ Libs
import {Col, Label, Row} from 'reactstrap'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'

//@@ Utils
import {getRawValuesFromOptions, selectThemeColors} from '@utils'
import {useIndividualSelect} from '@src/views/apps/reports/hooks/useIndividualSelect'

//@@ Components
import {AdaptiveLabel} from '@src/views/apps/reports/components/AdaptiveLabel/AdaptiveLabel'

export const FormForTheseSelect = ({label, onSettingChange, categoriesOptions, categoriesValue, individual }) => {
    const {handleQueryChange, loadOptions, profiles} = useIndividualSelect()

    const parseSelectValue = (profile) => ({
        value: profile.id,
        label: `${profile.first_name} ${profile.last_name}`
    })

    return (
        <Row className="mb-2">
            <AdaptiveLabel className="align-self-start">{label}</AdaptiveLabel>
            <Col sm="8" xs={12}>
                <AdaptiveLabel fullWidth disabled={!!individual?.length}>
                    Categories Of People
                  <span className="requiredFormLabelAsterisk">*</span>
                </AdaptiveLabel>
                <Select
                    isMulti
                    isDisabled={!!individual?.length}
                    closeMenuOnSelect={false}
                    isClearable={false}
                    theme={selectThemeColors}
                    className="react-select"
                    classNamePrefix="select"
                    placeholder="search"
                    options={categoriesOptions}
                    value={categoriesValue.length === 0 ? [categoriesOptions[0]] : categoriesValue}
                    onChange={(options) => {
                        onSettingChange({name: 'membership', value: getRawValuesFromOptions(options)})
                    }}
                />

                <AdaptiveLabel disabled={!!categoriesValue.length} className="mt-2">
                    Or Individuals
                </AdaptiveLabel>
                <AsyncSelect
                    isMulti
                    isDisabled={!!categoriesValue.length}
                    cacheOptions
                    closeMenuOnSelect={false}
                    noOptionsMessage={() => 'Type To Search'}
                    isClearable={false}
                    name="individual"
                    theme={selectThemeColors}
                    className="react-select"
                    classNamePrefix="select"
                    placeholder="search"
                    loadOptions={loadOptions}
                    defaultOptions={false}
                    value={individual?.length ? individual.map(parseSelectValue) : undefined}
                    onInputChange={handleQueryChange}
                    onChange={(options) => {
                        onSettingChange({name: 'individual', value: options.map(option => profiles[option.value])})
                    }}
                />
            </Col>
        </Row>
    )
}
