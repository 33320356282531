//@@ Libs
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'

//@@ Utils
import {api} from '@src/services/api'
import {transformToOptions} from '@utils'

//@@ Constants
import {GENERAL_MEMBERSHIP_VALUES} from '@src/views/apps/reports/constants'
import {getJWTToken} from "@src/utility/auth/tokens"

export const useSettingsForm = (report) => {
    //@@ Church select
    const churchID = useSelector((state) => state.auth.userData.churchID)
    //@@ Loading state
    const [isLoading, setIsLoading] = useState(false)
    //@@ Settings dictionaries state
    const [settingsDictionaries, setSettingsDictionaries] = useState({
        membership: [],
        uniqueEvents: [],
        gender: [],
        maritalStatuses: []
    })
    //@@ Settings state
    const [settings, setSettings] = useState({
        showMeCategory: null,
        showMeFirstLast: null,
        membership: [],
        individual: null,
        uniqueEvent: null,
        betweenDates: [],
        compareDates: [],
        characteristics: [],
        participatedIn: null
    })

    //@@ Load Dictionaries
    useEffect(() => {
        setIsLoading(true)
        getJWTToken().then(token => Promise.all([
            api.post('get-membership', {
                    churchID
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }),
            api.post('get-events', {
                    churchID
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }),
            api.post('get-gender', {
                    churchID
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }),
            api.post('get-marital-statuses', {
                    churchID
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
        ]))
            .then(([{data: membership}, {data: uniqueEvents}, {data: gender}, {data: maritalStatuses}]) => {
                const membershipDict = membership.filter(Boolean)

                setSettingsDictionaries({
                    ...settingsDictionaries,
                    membership: transformToOptions([...GENERAL_MEMBERSHIP_VALUES, ...membershipDict]),
                    uniqueEvents: transformToOptions(uniqueEvents.filter(Boolean).filter((v) => typeof v === 'string')),
                    gender: transformToOptions(gender.filter(Boolean)),
                    maritalStatuses: transformToOptions(maritalStatuses.filter(Boolean))
                })
                setIsLoading(false)
            })
            .catch(() => setIsLoading(false))
    }, [])

    //@@ If on concrete report page set settings from report data
    useEffect(() => {
        if (report?.settings) {
            setSettings({...settings, ...report.settings})
        }
    }, [report])

    //@@ Settings change handler
    const handleSettingChange = ({name, value}) => {
        if (name === 'membership') {
            settings.membership = value.slice(-1) // Keep just the last one
            setSettings({...settings})
            return
        }
        setSettings({...settings, [name]: value})
    }

    //@@ Characteristics option settings change
    const handleCharacteristicChange = (option, index) => {
        const characteristics = cloneDeep(settings.characteristics)

        characteristics[index] = {characteristic: option.value, value: null}
        setSettings({...settings, characteristics})
    }

    //@@ Characteristics value change
    const handleCharacteristicValueChange = (option, index) => {
        const characteristics = cloneDeep(settings.characteristics)

        characteristics[index].value = 'value' in option ? option.value : {...characteristics[index].value, ...option}

        setSettings({...settings, characteristics})
    }

    //@@ Add characteristic to settings
    const handleCharacteristicAdd = () => {
        setSettings({...settings, characteristics: [...settings.characteristics, {characteristic: null, value: null}]})
    }

    //@@ Remove characteristic from settings
    const handleCharacteristicRemove = (index) => {
        setSettings({
            ...settings,
            characteristics: [...settings.characteristics.slice(0, index), ...settings.characteristics.slice(index + 1)]
        })
    }

    return {
        state: {settingsDictionaries, isLoading, settings, churchID},
        methods: {
            handleCharacteristicChange,
            handleCharacteristicAdd,
            handleCharacteristicValueChange,
            handleCharacteristicRemove,
            handleSettingChange
        }
    }
}
