//@@ Libs
import { Button, Col, Input, Label, Modal, ModalBody, Row } from 'reactstrap'
import { useState } from 'react'

export const SaveModal = ({ isOpen, reportName, onClose, onConfirm }) => {
  //@@ Report name state
  const [name, setName] = useState(reportName ?? '')

  return (
    <Modal isOpen={isOpen} toggle={onClose} className="modal-dialog-centered modal-sm">
      <ModalBody className="px-sm-5 pt-50 pb-5">
        <div className="text-center my-2">
          <h1 className="mb-1">Save Report</h1>
        </div>
        <Row>
          <Col xs={12}>
            <Label>Report Name</Label>
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          </Col>
        </Row>

        <Row className="pt-75">
          <Col xs={12} className="text-center mt-2 pt-50">
            <Button type="submit" className="me-1" color="primary" onClick={() => onConfirm(name)}>
              Submit
            </Button>
            <Button type="reset" color="secondary" outline onClick={onClose}>
              Discard
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}
