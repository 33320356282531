//@@ Libs
import { AtSign, Bookmark, BookOpen, DollarSign, Heart, UserPlus, Users } from 'react-feather'

//@@ Models
import { GoalCategory, GoalTimePeriod, GoalType } from '@src/models'

//@@ Initial Goal form
export const initialEditGoal = {
  goalName: '',
  category: null,
  goalType: null,
  timePeriod: null,
  start: '',
  end: '',
  targetValue: 0
}

//@@ Goal Options
export const GOAL_OPTIONS = [
  {label: 'Giving', value: GoalCategory.GIVING},
  {label: 'Sunday Morning Attendance', value: GoalCategory.ATTENDANCE},
  {label: 'Cell Group', value: GoalCategory.CELLGROUP},
  {label: 'Bible Study', value: GoalCategory.BIBLESTUDY},
  {label: 'Membership', value: GoalCategory.MEMBERSHIP},
  {label: 'Ministry', value: GoalCategory.MINISTRY},
  {label: 'Social Media', value: GoalCategory.SOCIALMEDIA},
  {label: 'Salvations', value: GoalCategory.SALVATIONS},
  {label: 'Baptisms', value: GoalCategory.BAPTISMS}
]

//@@ Goal Type Icons
export const GOAL_TYPE_ICONS = {
  [GoalCategory.GIVING]: DollarSign,
  [GoalCategory.ATTENDANCE]: Bookmark,
  [GoalCategory.CELLGROUP]: Users,
  [GoalCategory.BIBLESTUDY]: BookOpen,
  [GoalCategory.MEMBERSHIP]: UserPlus,
  [GoalCategory.MINISTRY]: Heart,
  [GoalCategory.SOCIALMEDIA]: AtSign,
  [GoalCategory.SALVATIONS]: AtSign,
  [GoalCategory.BAPTISMS]: AtSign
}

//@@ Giving Goal Type options
export const GIVING_GOAL_TYPE_OPTIONS = [
  {label: 'Dollar Amount', value: GoalType.NUMBER},
  {label: '% of Congregation Participating', value: GoalType.ENGAGEMENT},
  {label: 'Engagement Level', value: GoalType.ENGAGEMENTLEVEL}
]

//@@ Goal Type options
export const GOAL_TYPE_OPTIONS = (type = null) => {
  const options = [
    {label: type && type === GoalCategory.GIVING ? 'Dollar Amount' : 'Number', value: GoalType.NUMBER},
    {
      label: '% of Congregation Participating',
      value: GoalType.ENGAGEMENT
    }
  ]

  if (![GoalCategory.SALVATIONS, GoalCategory.BAPTISMS, GoalCategory.MEMBERSHIP].includes(type)) {
    options.push({label: 'Engagement Level', value: GoalType.ENGAGEMENTLEVEL})
  }

  return options
}

//@@ Period options
export const TIME_PERIOD_OPTIONS = [
  {label: 'Daily', value: GoalTimePeriod.DAILY},
  {label: 'Weekly', value: GoalTimePeriod.WEEKLY},
  {label: 'Monthly', value: GoalTimePeriod.MONTHLY},
  {label: '3 Months', value: GoalTimePeriod.THREEMONTH},
  {label: 'Yearly', value: GoalTimePeriod.YEARLY},
  {label: 'Custom', value: GoalTimePeriod.CUSTOM}
]

//@@ Subtype options
export const GOAL_TYPE_SUBTYPES = {
  [GoalCategory.GIVING]: [],
  [GoalCategory.ATTENDANCE]: [],
  [GoalCategory.CELLGROUP]: [],
  [GoalCategory.BIBLESTUDY]: [],
  [GoalCategory.MEMBERSHIP]: [],
  [GoalCategory.MINISTRY]: [],
  [GoalCategory.SOCIALMEDIA]: [],
  [GoalCategory.SALVATIONS]: [],
  [GoalCategory.BAPTISMS]: []
}
