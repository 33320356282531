//@@ Libs
import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

//@@ Utils
import {getRawValuesFromOptions} from '@utils'
import {getSourceActionsDictionary, getSourceProperties, transformGivingProperty} from '@src/views/apps/reports/utils'
import {api} from '@src/services/api'

//@@ Store
import {authUserSelector} from '@store/selectors'
import {getJWTToken} from "@src/utility/auth/tokens"

export const useSourcesForm = (report) => {
    //@@ Sources state
    const [startSource, setStartSource] = useState({source: null, action: null, properties: []})
    const [endSource, setEndSource] = useState({source: null, action: null, properties: []})
    //@@ Sources data
    const [sources, setSources] = useState([])
    //@@ Sources dictionaries
    const [dictionaries, setDictionaries] = useState({
        sourcesDictionary: [],
        startSource: {actionDictionary: [], propertiesDictionary: []},
        endSource: {actionDictionary: [], propertiesDictionary: []}
    })

    //@@ UseChurchId Hook
    const churchID = useSelector(authUserSelector)?.churchID

    //@@ If on concrete report page set sources settings from report data
    useEffect(() => {
        if (report?.settings && sources.length) {
            const startSource = sources.find(({source}) => source === report.settings.startSource?.source)
            const endSource = sources.find(({source}) => source === report.settings.endSource?.source)

            setDictionaries((v) => ({
                ...v,
                startSource: report.settings?.startSource
                    ? {
                        actionDictionary:
                            startSource?.actions?.map(({action, actionName}) => ({
                                value: action,
                                label: actionName
                            })) ?? null,
                        propertiesDictionary: startSource?.actions
                            ?.find(({action}) => action === report.settings.startSource?.action)
                            .properties.map(({property, propertyName}) => ({
                                value: property,
                                label:
                                    report.settings.startSource?.action === 'giving'
                                        ? transformGivingProperty(propertyName)
                                        : propertyName
                            }))
                    }
                    : v.startSource,
                endSource: report.settings?.endSource
                    ? {
                        actionDictionary:
                            endSource?.actions?.map(({action, actionName}) => ({
                                value: action,
                                label: actionName
                            })) ?? null,
                        propertiesDictionary: endSource?.actions
                            ?.find(({action}) => action === report.settings.endSource?.action)
                            .properties.map(({property, propertyName}) => ({
                                value: property,
                                label:
                                    report.settings.endSource?.action === 'giving'
                                        ? transformGivingProperty(propertyName)
                                        : propertyName
                            }))
                    }
                    : v.endSource
            }))
            if (report?.settings?.startSource) {
                setStartSource(report.settings.startSource)
            }
            if (report?.settings?.endSource) {
                setEndSource(report.settings.endSource)
            }
        }
    }, [report, sources])

    //@@ Load sources
    useEffect(() => {
        getJWTToken().then(token => api.post('get-sources-tree', {churchID},
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })).then(({data}) => {
            setSources(data)
            setDictionaries((v) => ({
                ...v,
                sourcesDictionary:
                    data.map(({sourceName, source}) => ({value: source, label: sourceName}))

            }))
        })
    }, [])

    //@@ Change source
    const handleChangeSource = (option, type) => {
        const rawValue = option.value
        const field = type === 'start' ? 'startSource' : 'endSource'
        const setField = type === 'start' ? setStartSource : setEndSource

        setDictionaries((v) => ({
            ...v,
            [field]: {
                actionDictionary: getSourceActionsDictionary({sources, rawValue}),
                propertiesDictionary: []
            }
        }))
        setField({
            source: rawValue,
            action: null,
            properties: []
        })
    }

    //@@ Change action
    const handleChangeAction = (option, type) => {
        const rawValue = option.value
        const field = type === 'start' ? 'startSource' : 'endSource'
        const source = type === 'start' ? startSource : endSource
        const setField = type === 'start' ? setStartSource : setEndSource

        setDictionaries({
            ...dictionaries,
            [field]: {
                ...dictionaries[field],
                propertiesDictionary: getSourceProperties({sources, rawValue, source: source.source})
            }
        })
        setField({
            ...source,
            action: rawValue,
            properties: []
        })
    }

    //@@ Change source properties
    const handleChangeProperties = (options, type) => {
        const rawValue = getRawValuesFromOptions(options)
        const source = type === 'start' ? startSource : endSource
        const setField = type === 'start' ? setStartSource : setEndSource

        setField({...source, properties: rawValue})
    }

    return {
        startSource,
        endSource,
        dictionaries,
        sources,
        handleChangeSource,
        handleChangeAction,
        handleChangeProperties
    }
}
