//@@ Libs
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

//@@ Store
import { useGetChurchProfilesQuery, useGetChurchProfileInfoQuery } from '@src/redux/singleSourceApiSlice'
import { handlePageSlice } from '@src/views/apps/profiles/store'

export const useProfileIdParam = () => {
  //@@ Profile id from params
  const { profileId } = useParams()
  const [filteredProfiles, setFilteredProfiles] = useState([])
  const [profile, setProfile] = useState(null)
  const [isFetching, setIsFetching] = useState(true)
  //@@ Hooks
  const dispatch = useDispatch()

  //@@ Selectors
  const churchID = useSelector((state) => state.auth.userData?.churchID)
  const pageInfo = useSelector((state) => state.pageInfo)

  const templateRequestBody = useMemo(
    () => ({
      churchID,
      offset: pageInfo.offset,
      limit: pageInfo.limit
    }),
    [churchID, pageInfo.offset, pageInfo.limit]
  )
  const profileRequestBody = useMemo(
    () => ({
      churchID,
      id: profileId
    }),
    [churchID, profileId]
  )
  const profilesQuery = useGetChurchProfilesQuery(templateRequestBody)
  const profileInfoQuery = useGetChurchProfileInfoQuery(profileRequestBody)
  const { data: profiles } = profilesQuery
  const {data:profileInfo} = profileInfoQuery
  console.log(profileInfo, 'profileInfo')
  //@@ Set initial filtered profiles
  useEffect(() => {
    if (profiles) {
      console.log(profiles)
      dispatch(handlePageSlice({ total: profiles?.Total }))
      setFilteredProfiles(profiles?.Items)
      setIsFetching(false)
    }
  }, [profiles])


  // useEffect(() => {
  //   if (filteredProfiles.length > 0) {
  //     // const _profile = filteredProfiles.find((profile) => profile.id === profileId)
  //     // setProfile(profileInfo)
  //     // console.log(profile)
  //     // setIsFetching(false)
  //   }
  // }, [filteredProfiles, profileId])

  useEffect(() => {
    if (profileInfo) {
      console.log(profileInfo, 'profile info')
      setProfile(profileInfo)
      setIsFetching(false)
    }
  }, [profileInfo])

  return { profile, profilesQuery, isFetching, profileInfoQuery }
}
