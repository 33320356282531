//@@ Libs
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {Storage} from 'aws-amplify'
//@@ Constants
import {LOADING_STATE} from '@src/constants'
//@@ Services
import {api} from '@src/services/api'
import {getJWTToken} from "@src/utility/auth/tokens"

export const createProfile = createAsyncThunk('csv/createProfile', async ({church}) => {
    const token = await getJWTToken()
    const response = await api.post(`create-church-profiles`, {church}, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    return response.data
})

export const getStructure = createAsyncThunk('csv/getStructure', async ({church}, {dispatch, getState}) => {
    const token = await getJWTToken()
    const response = await api.post(`csv-datastructure`, {church}, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })

    if (!response?.data?.attributes && !getState()?.csv.profileCreated) {
        dispatch(createProfile({church}))
    }
    return response.data
})
export const saveMapping = createAsyncThunk('csv/saveMapping', async (mapping) => {
    const token = await getJWTToken()
    const response = await api.post(`csv-mapping-set`, mapping, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    return response.data.id
})
export const setFile = createAsyncThunk('csv/setFile', async (data) => {
    const token = await getJWTToken()
    const response = await api.post(`csv-upload-data`, data, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    return response.data
})

export const getMappings = createAsyncThunk('csv/getMappings', async ({churchID}) => {
    const token = await getJWTToken()
    const response = await api.post(`csv-mapping`, {church: churchID}, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    return response.data
})

export const downloadFile = createAsyncThunk('csv/donwloadFile', async ({file_key}) => {
    const token = await getJWTToken()
    return await Storage.get(file_key, {level: 'private', download: true}, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
})

export const uploadCsv = createAsyncThunk('csv/uploadCsv', async (data) => {
    const token = await getJWTToken()
    const response = await api.post(`upload-csv`, data, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    return response.data
})
const csvSlice = createSlice({
    name: 'csv',
    initialState: {
        structure: [],
        mappingId: null,
        structureRequest: null,
        mappings: [],
        mappingLoading: LOADING_STATE.IDLE,
        profileCreated: false,
        csvLoading: LOADING_STATE.IDLE
    },
    reducers: {
        resetCsvState: (state) => {
            state.structure = null
            state.mappingId = null
            state.mappings = []
            state.structureRequest = null
            state.profileCrated = false
            state.mappingLoading = LOADING_STATE.IDLE
            state.csvLoading = LOADING_STATE.IDLE
        },
        setStructure: (state, action) => {
            state.structure = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getStructure.fulfilled, (state, action) => {
                state.structure = action.payload
                state.structureRequest = true
            })
            .addCase(saveMapping.fulfilled, (state, action) => {
                state.mappingId = action.payload
            })
            .addCase(getMappings.pending, (state) => {
                state.mappingLoading = LOADING_STATE.PENDING
            })
            .addCase(getMappings.fulfilled, (state, action) => {
                state.mappings = action.payload
                state.mappingLoading = LOADING_STATE.FINISHED
            })
            .addCase(createProfile.fulfilled, (state) => {
                state.profileCreated = true
            })
            .addCase(uploadCsv.pending, (state) => {
                state.csvLoading = LOADING_STATE.PENDING
            })
            .addCase(uploadCsv.fulfilled, (state) => {
                state.csvLoading = LOADING_STATE.FINISHED
            })
    }
})
export const {resetCsvState, setStructure} = csvSlice.actions
export default csvSlice.reducer
