//@@ Libs
import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import moment from 'moment'

//@@ Constants
import { DEFAULT_FULL_DATE_FORMAT } from 'constants/datetime'

//@@ Pdf sheet style
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  churchName: { position: 'absolute', fontSize: '8px', fontWeight: '800', left: '10%', top: '5px' },
  info: {
    padding: '48px 8px',
    color: '#000000'
  },
  title: { fontWeight: 'bold', margin: '8px 0' },
  description: { fontSize: '8px', margin: '8px 0' },
  sources: { padding: '4px 8px' },
  sourceTitle: { fontWeight: 400, color: '#000000' },
  source: { fontSize: '8px', color: '#000000' }
})

export const PdfReport = ({
  isMobile = false,
  isPrint = false,
  header,
  title,
  description,
  printDataSource,
  sources
}) => {
  const additionalPaddingForSafari = isPrint

  return (
    <Document>
      <Page style={{ ...styles.page, padding: additionalPaddingForSafari ? '32px 24px' : '0px' }} dpi={72} size="A4">
        <View fixed>
          <Image src={require('@src/assets/images/backgrounds/header-bg.png').default} />
          <Text style={styles.churchName}>{header}</Text>
          <View style={styles.info} fixed>
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.description}>{description}</Text>
            <Text style={styles.description}>Run Date: {moment().format(DEFAULT_FULL_DATE_FORMAT)}</Text>
          </View>

          <Image src={printDataSource} />
          <View style={styles.sources}>
            {!!sources?.length && <Text style={styles.sourceTitle}>Sources:</Text>}
            {sources.map((source) => {
              return (
                <Text style={styles.source} key={source}>
                  {source}
                </Text>
              )
            })}
          </View>
        </View>
        <View fixed>
          <Image src={require('@src/assets/images/backgrounds/pdf-footer.png').default} />
        </View>
      </Page>
    </Document>
  )
}
