//@@ Libs
import { Col, Row } from 'reactstrap'
import Select from 'react-select'

//@@ Utils
import { selectThemeColors } from '@utils'

//@@ Components
import { AdaptiveLabel } from '@src/views/apps/reports/components/AdaptiveLabel/AdaptiveLabel'

export const FormSelect = ({ label, value, options, onChange, isMulti = false, isRequired = false }) => {
  return (
    <Row className="mb-2">
      <AdaptiveLabel>
        {label}
        {isRequired && <span className="requiredFormLabelAsterisk">*</span>}
      </AdaptiveLabel>
      <Col sm="8" xs={12}>
        <Select
          isMulti={isMulti}
          isClearable={false}
          theme={selectThemeColors}
          className="react-select"
          classNamePrefix="select"
          value={value}
          placeholder="search"
          options={options}
          onChange={onChange}
        />
      </Col>
    </Row>
  )
}
