// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DataStore, SortDirection } from 'aws-amplify'
import { ChurchModel } from '@src/models'

export const getData = createAsyncThunk('datatables/getData', async (params, {}) => {
  const { sortColumn, sort, page, perPage, q } = params
  try {
    const models = await DataStore.query(
      ChurchModel,
      (model) => {
        // @todo make search case insensitive
        const filteredModel = model.or((m) => {
          return m.name('contains', q).fullAddress('contains', q).shortName('contains', q).web('contains', q)
        })
        return filteredModel
      },
      {
        sort: (model) => {
          const awsSortingDir = sort === 'desc' ? SortDirection.DESCENDING : SortDirection.ASCENDING
          const sortedModel = model[sortColumn](awsSortingDir)
          return sortedModel
        },
        page,
        limit: perPage
      }
    )
    return { data: models }
  } catch (e) {
    console.error(e)
  }
})

export const datatablesSlice = createSlice({
  name: 'church-datatable',
  initialState: {
    data: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
    })
  }
})

export default datatablesSlice.reducer
