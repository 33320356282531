//@@ Libs
import {useEffect, useState} from 'react'

export const useProfileEngagement = ({profile, startedAt}) => {
    //@@ State
    const [isLoading, setIsLoading] = useState(true)

    const [info, setInfo] = useState({
        attendance: 0,
        bible_study: 0,
        cell_group: 0,
        giving: 0,
        ministry: 0,
        social_media: 0
    })

    useEffect(() => {
        if (!profile) return
        setInfo(() => ({
            attendance: profile.attendance_individual,
            bible_study: profile.bible_study_individual,
            cell_group: profile.cell_group_individual,
            giving: profile.giving_engagement_individual,
            ministry: profile.ministries_individual,
            social: profile.social_media_individual
        }))
        setIsLoading(false)
    }, [profile])


    return {isLoading, levels: info}
}
