//@@ Libs
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

//@@ Store
import { handleMenuHidden, handleContentWidth, handleMenuCollapsed } from '@store/layout'

//@@ Hooks
import { useRTL } from '@hooks/useRTL'
import { useSkin } from '@hooks/useSkin'
import { useLayout } from '@hooks/useLayout'
import { useNavbarType } from '@hooks/useNavbarType'
import { useFooterType } from '@hooks/useFooterType'
import { useNavbarColor } from '@hooks/useNavbarColor'
import { useRouterTransition } from '@hooks/useRouterTransition'
import { useLocation } from 'react-router-dom'

export const useLayoutState = () => {
  //@@ Hooks
  const { skin, setSkin } = useSkin()
  const [isRtl, setIsRtl] = useRTL()
  const { navbarType, setNavbarType } = useNavbarType()
  const { footerType, setFooterType } = useFooterType()
  const { navbarColor, setNavbarColor } = useNavbarColor()
  const { layout, setLayout, setLastLayout } = useLayout()
  const { transition, setTransition } = useRouterTransition()

  //@@ States
  const [isMounted, setIsMounted] = useState(false)
  const [navbarScrolled, setNavbarScrolled] = useState(false)
  const [menuVisibility, setMenuVisibility] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  //@@ Store Vars
  const dispatch = useDispatch()
  const layoutStore = useSelector((state) => state.layout)

  //@@ Vars
  const contentWidth = layoutStore.contentWidth
  const isHidden = layoutStore.menuHidden
  const location = useLocation()
  const menuCollapsed = layoutStore.menuCollapsed

  //@@ Handles Content Width
  const setContentWidth = (val) => dispatch(handleContentWidth(val))

  //@@ Handles Content Width
  const setIsHidden = (val) => dispatch(handleMenuHidden(val))

  //@@ Toggles Menu Collapsed
  const setMenuCollapsed = (val) => dispatch(handleMenuCollapsed(val))

  return {
    state: {
      skin,
      navbarScrolled,
      contentWidth,
      isHidden,
      navbarColor,
      isMounted,
      navbarType,
      footerType,
      isRtl,
      layout,
      transition,
      location,
      menuCollapsed,
      menuVisibility,
      windowWidth
    },
    methods: {
      setIsMounted,
      setNavbarScrolled,
      setSkin,
      setContentWidth,
      setIsHidden,
      setIsRtl,
      setLayout,
      setTransition,
      setNavbarType,
      setNavbarColor,
      setFooterType,
      setLastLayout,
      setMenuCollapsed,
      setMenuVisibility,
      setWindowWidth
    }
  }
}
