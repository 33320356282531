//@@ Components
import { AccordionHeader } from '@components/apps/AccordionHeader/AccordionHeader'

export const ReportHeader = ({ title, description, menu }) => {
  return (
    <AccordionHeader
      title={title}
      description={<span dangerouslySetInnerHTML={{ __html: description }} />}
      backLinkText="Reports"
      backLinkUrl="/reports"
      menu={menu}
    />
  )
}
