const contentStyles = {
  width: '100%',
  height: '100%'
}

const imageSizes = {
  lg: {
    height: '110px',
    width: '110px'
  },
  sm: {
    height: '40px',
    width: '40px'
  }
}

export { contentStyles, imageSizes }
