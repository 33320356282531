//@@ Libs
import { useContext, useEffect } from 'react'
import { Col, Row, Spinner } from 'reactstrap'

//@@ Context
import { FormContext } from '@src/views/apps/reports/features/WebInsights/formContext'

//@@ Components
import UILoader from '@components/ui-loader'
import { FormSourceActions } from '@src/views/apps/reports/components/FormElements/FormSourceActions'
import { FormBetweenRange } from '@src/views/apps/reports/components/FormElements/FormBetweenRange'

export const CohortForm = () => {
  //@@ Import form settings context
  const {
    isLoading,
    settings,
    settingsDictionaries,
    onChangeSource,
    onChangeAction,
    onChangeProperties,
    onSettingChange
  } = useContext(FormContext)

  //@@ Select default Web Metrics source
  useEffect(() => {
    const metricOption = settingsDictionaries.sourcesDictionary.find(({ value }) => value === 'Web Metrics')
    onChangeSource(metricOption, 'start')
    onChangeSource(metricOption, 'end')
  }, [])

  return (
    <UILoader loader={<Spinner />} blocking={isLoading}>
      <FormSourceActions
        isSourceHidden
        firstLabel="Show Me The People Who Did This"
        lastLabel="With These"
        sourcesDictionary={settingsDictionaries.sourcesDictionary}
        actionDictionary={settingsDictionaries.startSourceActionDictionary}
        propertiesDictionary={settingsDictionaries.startSourcePropertiesDictionary}
        action={settings.startSource.action}
        source={settings.startSource.source}
        properties={settings.startSource.properties}
        onChangeSource={(opt) => onChangeSource(opt, 'start')}
        onChangeAction={(opt) => onChangeAction(opt, 'start')}
        onChangeProperties={(opt) => onChangeProperties(opt, 'start')}
      />

      <FormSourceActions
        isSourceHidden
        firstLabel="Then Came Back And Did This"
        lastLabel="With These"
        sourcesDictionary={settingsDictionaries.sourcesDictionary}
        actionDictionary={settingsDictionaries.endSourceActionDictionary}
        propertiesDictionary={settingsDictionaries.endSourcePropertiesDictionary}
        action={settings.endSource.action}
        source={settings.endSource.source}
        properties={settings.endSource.properties}
        onChangeSource={(opt) => onChangeSource(opt, 'end')}
        onChangeAction={(opt) => onChangeAction(opt, 'end')}
        onChangeProperties={(opt) => onChangeProperties(opt, 'end')}
      />

      <Row className="mt-2">
        <Col xs={6}>
          <FormBetweenRange
            fullWidth
            label="That Happened Between"
            settingDates={settings.betweenDates}
            onSettingChange={onSettingChange}
          />
        </Col>
      </Row>
    </UILoader>
  )
}
