//@@ Libs
import { Button, Col, Row } from 'reactstrap'

//@@ Constants
import { DATABASE_ACTION_LABEL } from '@src/views/apps/reports/constants'

// Common database component
// @@@ Get callbacks list for specified action: Array<{type: DATABASE_ACTION_TYPE, onClick: () => void}>
// Used as view
export const ReportActions = ({ actions, disabled }) => {
  return (
    <Row>
      <Col xs={12} className="d-flex justify-content-center flex-wrap mt-3">
        {actions.map(({ type, onClick }) => {
          return (
            <Button key={type} disabled={disabled} className="database-app__action-btn" color="info" onClick={onClick}>
              {DATABASE_ACTION_LABEL[type]}
            </Button>
          )
        })}
      </Col>
    </Row>
  )
}
