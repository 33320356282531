//@@ Libs
import moment from 'moment'

//@@ Utils
import { formatPhoneNumber, getReadableDate } from '@utils'

//@@ Constants
import { PLATFORM_CATEGORIES } from 'constants/apps'
import { DEFAULT_SHORT_DATE_FORMAT } from 'constants/datetime'
import startCase from 'lodash/startCase'
import { TIMELINE_WEB_TRACKING_EVENT_TYPE } from '@components/apps/UserTimeline/constants'

//@@ Get profile full name
const getProfileFullName = (profile) => {
  return `${profile?.first_name ?? ''} ${profile?.last_name ?? ''}`
}

//@@ Get profile full name with suffix
const getProfileFullNameWithSuffix = (profile) => {
  return `${profile?.first_name ?? ''} ${profile?.last_name ?? ''} ${profile?.member_suffix ?? ''}`
}

//@@ Get profile initials
const getProfileNameInitials = (profile) => {
  return `${profile?.first_name?.slice(0, 1)?.toUpperCase() ?? ''}${profile?.last_name?.slice(0, 1)?.toUpperCase() ?? ''
    }`
}

//@@ Get profile address
const getProfileAddress = (profile) => {
  if (!profile?.address) {
    return '-'
  }
  return `${profile.address} ${profile.city ? `, ${profile.city}` : ''} ${profile.state ? `, ${profile.state}` : ''} ${profile.country ? `, ${profile.country}` : ''
    } ${profile.zip ? `, ${profile.zip}` : ''}`
}
const getNameOfTheSource = (last_touch_source, last_touch_eventtype) => {
  if (Object.values(TIMELINE_WEB_TRACKING_EVENT_TYPE).includes(last_touch_eventtype)) {
    return 'Church Website'
  }
  return startCase(last_touch_source)
}
//@@ Get profile last touch status
const getProfileLastTouchStatus = (profile) => {
  const event =
    profile?.last_touch_event ??
    PLATFORM_CATEGORIES.find(({ value }) => profile?.last_touch_eventCategory === value)?.label
  const date = profile?.last_touch_date ? moment(profile.last_touch_date).format(DEFAULT_SHORT_DATE_FORMAT) : ''
  const capitalizedSource = getNameOfTheSource(profile?.last_touch_eventSource, profile?.last_touch_eventType)
  if (!event && !date) {
    return ''
  }

  return `Last Touch ${event ? `on ${event}` : ''} ${date ? `on ${date}` : ''} ${capitalizedSource ? ` via ${capitalizedSource}` : ''}`
}

//@@ Get social media profile nickname
const getNickname = (type, url) => {
  //@@ TODO it`s for mocking
  if (type === 'YouTube') {
    return 'roserich'
  }
  if (type === 'Instagram' || type === 'Facebook') {
    return url.match(/@.+/)?.[0]?.replace('@', '')
  }
  return url.match(/\/\w+(\/)?$/)?.[0]?.replaceAll('/', '')
}

//@@ Get social media profile url
const getParsedUrl = (type, url) => {
  if (type === 'Instagram' || type === 'Facebook') {
    const nickname = getNickname(type, url)
    return `${type === 'Instagram' ? 'https://www.instagram.com' : 'https://www.facebook.com'}/${nickname}`
  }
  return url
}

//@@ Get baptism date
const getBaptizedDate = (profile, timeline) => {
  if (profile?.baptism_date) {
    return getReadableDate(profile.baptism_date)
  }

  const baptizedTimelineEvent = timeline?.find(({ event }) => event === 'Got Baptized')

  if (baptizedTimelineEvent) {
    return getReadableDate(baptizedTimelineEvent.date)
  }

  return '-'
}

//@@ Get baptism date
const getSalvationDate = (profile, timeline) => {
  if (profile?.salvation_date) {
    return getReadableDate(profile.salvation_date)
  }

  const salvationTimelineEvent = timeline?.find(({ event }) => event === 'Got Saved')

  if (salvationTimelineEvent) {
    return getReadableDate(salvationTimelineEvent.date)
  }

  return '-'
}

//@@ Get Profile Phone Number
const getProfilePhoneNumber = (profile) => {
  const fields = ['phone', 'home_phone', 'cell_phone']
  const matchedField = profile ? Object.keys(profile)?.find((key) => fields.includes(key) && profile[key]) : null
  if (!matchedField) {
    return '-'
  }
  return formatPhoneNumber(profile?.[matchedField])
}

export {
  getProfileFullName,
  getProfileFullNameWithSuffix,
  getProfileNameInitials,
  getProfileAddress,
  getProfileLastTouchStatus,
  getNickname,
  getParsedUrl,
  getBaptizedDate,
  getSalvationDate,
  getProfilePhoneNumber
}
