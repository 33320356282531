//@@ Libs
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  UncontrolledTooltip
} from 'reactstrap'
import { AlertCircle, ChevronLeft, Menu, Share2 } from 'react-feather'
import { Link } from 'react-router-dom'

//@@ Hooks
import { useLayout } from '@hooks/useLayout'

//@@ Constants
import { COPYRIGHT } from '@src/constants/main'
import { LAYOUT_TYPE } from '@src/constants'

export const AccordionHeader = ({ title, description, backLinkText, backLinkUrl, menu }) => {
  const { layout } = useLayout()

  return (
    <Row>
      <Col xs={12}>
        <Card>
          <CardHeader className="border-bottom shadow-none">
            <div className="d-flex align-items-start">
              <Link
                to={backLinkUrl}
                className={`d-flex me-2 align-items-center ${layout === LAYOUT_TYPE.vertical ? 'font-medium-2' : ''}`}
              >
                <ChevronLeft size={20} />
                {backLinkText}
              </Link>
              <div
                className={`d-flex align-items-center fw-bold ${
                  layout === LAYOUT_TYPE.vertical ? 'font-medium-2' : ''
                }`}
              >
                {title}
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <div className="d-flex align-items-start pt-2 justify-content-between">
              <div className="d-flex align-items-start">
                <Share2 className="me-1 flex-shrink-0" size={22} />
                <div className="d-flex flex-column">
                  <h3 className={`${layout === LAYOUT_TYPE.horizontal ? 'font-medium-2' : ''}`}>
                    {COPYRIGHT} {title}
                  </h3>
                  <div className={`${layout === LAYOUT_TYPE.vertical ? 'font-medium-1' : ''}`}>{description}</div>
                </div>
              </div>
              {!!menu && (
                <UncontrolledDropdown>
                  <DropdownToggle className="hide-arrow me-1" tag="a" href="/" onClick={(e) => e.preventDefault()}>
                    <Menu className="text-body" size={22} />
                  </DropdownToggle>
                  <DropdownMenu end>
                    {menu?.map((item, idx) => {
                      return (
                        <DropdownItem
                          key={idx}
                          tag="div"
                          onClick={item?.disabled ? undefined : item.onClick}
                          className="d-flex align-items-center"
                          disabled={item?.disabled}
                        >
                          {item?.icon && item.icon}
                          {item?.title ?? '-'}
                          {item?.disabled && item?.warning && (
                            <>
                              <AlertCircle
                                id="UnControlledWarning"
                                size={14}
                                className="text-warning ms-75 pointer-events-all"
                              />
                              <UncontrolledTooltip placement="top" target="UnControlledWarning">
                                {item.warning}
                              </UncontrolledTooltip>
                            </>
                          )}
                        </DropdownItem>
                      )
                    })}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}
