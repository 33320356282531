//@@ Libs
import { Card, CardBody, CardHeader, CardTitle, Table } from 'reactstrap'

//@@ Utils
import { getNickname, getParsedUrl } from '@src/views/apps/profiles/list/utils'

//@@ Constants
import { SOCIAL_MEDIA_LOGO } from '@src/views/apps/profiles/constants'

export const SocialMediaInfo = ({ profile }) => {
  if (!profile?.social_profiles?.length) {
    return null
  }

  //@@ TODO it`s for mocking
  const mockedProfile =
    profile.id === '110341552'
      ? {
          ...profile,
          social_profiles: profile.social_profiles.reduce((acc, v) => {
            if (v?.social_type === 'LinkedIn') {
              acc.push({ social_type: 'YouTube', url: 'https://www.youtube.com/channel/UCdKIA43AzmdTR-84VyDUeyw' })
            }
            acc.push(v)
            return acc
          }, [])
        }
      : profile

  return (
    <Card>
      <CardHeader>
        <CardTitle>SOCIAL MEDIA HANDLES</CardTitle>
      </CardHeader>
      <CardBody>
        <Table>
          <thead>
            <tr>
              <th>Source</th>
              <th>Account</th>
            </tr>
          </thead>
          <tbody>
            {mockedProfile?.social_profiles?.map(({ url, social_type }, idx) => {
              return (
                <tr key={idx}>
                  <td>
                    <img
                      className="me-1"
                      src={SOCIAL_MEDIA_LOGO?.[social_type]}
                      alt={social_type}
                      height="38"
                      width="38"
                    />
                    <span>{social_type}</span>
                  </td>
                  <td>
                    <a href={getParsedUrl(social_type, url)} target="_blank">
                      {getNickname(social_type, url)}
                    </a>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  )
}
