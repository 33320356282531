//@@ Libs
import ReactDOM from 'react-dom'
import { Suspense, lazy } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { Toaster } from 'react-hot-toast'
import 'prismjs'
import { PersistGate } from 'redux-persist/integration/react'
import 'prismjs/components/prism-jsx.min'

//@@ Store
import { store, persistor } from '@store/store'

//@@ Utils
import ability from './configs/acl/ability'
import { AbilityContext } from './utility/context/Can'
import { ThemeContext } from './utility/context/ThemeColors'
import * as serviceWorker from './serviceWorker'

//@@ Config
import themeConfig from './configs/themeConfig'
import './configs/i18n'

//@@ Components
import Spinner from './@core/components/spinner/Fallback-spinner'
import './@core/components/ripple-button'

//@@ Styles
import '@styles/react/libs/react-hot-toasts/react-hot-toasts.scss'
import 'prismjs/themes/prism-tomorrow.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'
import './index.scss'

const LazyApp = lazy(() => import('./App'))
ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<Spinner />}>
          <AbilityContext.Provider value={ability}>
            <ThemeContext>
              <LazyApp />
              <Toaster position={themeConfig.layout.toastPosition} toastOptions={{ className: 'react-hot-toast' }} />
            </ThemeContext>
          </AbilityContext.Provider>
        </Suspense>
      </PersistGate>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
