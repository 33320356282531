//@@ Libs
import { useEffect } from 'react'
import { DataStore } from 'aws-amplify'
import { useDispatch, useSelector } from 'react-redux'

//@@ Store
import { churchUserChurchIDSelector } from '@store/selectors'
import { NotificationModel } from '@src/models'
import { addNotifications } from '@src/views/apps/notifications/store'

export const useNotificationsSubscription = () => {
  //@@ Church id selector
  const churchID = useSelector(churchUserChurchIDSelector)

  //@@ Dispatch
  const dispatch = useDispatch()

  //@@ Subscribe to notifications change: add new notification on insert, remove notification on delete for all users
  useEffect(() => {
    if (!churchID) {
      return
    }

    const subscription = DataStore.observeQuery(NotificationModel, (q) => q.churchID('eq', churchID)).subscribe(
      (msg) => {
        dispatch(addNotifications({ data: msg.items }))
      }
    )

    return () => {
      return subscription.unsubscribe()
    }
  }, [churchID])
}
