//@@ Libs
import { useContext } from 'react'
import { Button, Card, CardBody, CardFooter, Col, Row } from 'reactstrap'

//@@ Constants
import {
  WEB_INSIGHTS_TYPE,
  WEB_INSIGHTS_TITLES,
  WEB_INSIGHTS_DESCRIPTION,
  WEB_INSIGHTS_DEFINITION
} from '@src/views/apps/reports/constants'

//@@ Context
import { FormContext } from '@src/views/apps/reports/features/WebInsights/formContext'

//@@ Components
import { ReportHeader } from '@src/views/apps/reports/components/Header/ReportHeader'

export const ReportWrapper = ({ children, onSaveClick }) => {
  //@@ Import form settings context
  const { type, menuItems } = useContext(FormContext)

  return (
    <>
      <ReportHeader
        title={WEB_INSIGHTS_TITLES?.[WEB_INSIGHTS_TYPE?.[type]] ?? '-'}
        description={WEB_INSIGHTS_DESCRIPTION?.[WEB_INSIGHTS_TYPE?.[type]]}
        menu={menuItems}
      />
      <Card className="border-secondary">
        <CardBody>
          {children}
          <Row>
            <Col xs={12} className="d-flex justify-content-center flex-wrap mt-3">
              <Button className="database-app__action-btn" color="info" onClick={onSaveClick}>
                Save Report Changes
              </Button>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>{WEB_INSIGHTS_DEFINITION?.[WEB_INSIGHTS_TYPE?.[type]] ?? '-'}</CardFooter>
      </Card>
    </>
  )
}
