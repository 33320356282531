import {FileText, Share2} from 'react-feather'

export const CONNECTED_SOURCES_LOGO = {
    LinkedIn: (
        <img alt="LinkedIn" src={require('@src/assets/images/icons/social/linkedin.png').default} height="38"
             width="38"/>
    ),
    Twitter: (
        <img alt="Twitter" src={require('@src/assets/images/icons/social/twitter.png').default} height="38"
             width="38"/>
    ),
    Tiktok: (
        <img alt="Tiktok" src={require('@src/assets/images/icons/social/tiktok.png').default} height="38"
             width="38"/>
    ),
    PushPay: (
        <img
            alt="Facebook"
            src={require('@src/assets/images/icons/brands/pushPay-label.png').default}
            height="38"
            width="38"
        />
    ),
    'SingleSource Analytics': <Share2 size={38}/>,
    YouTube: (
        <img alt="YouTube" src={require('@src/assets/images/icons/social/youtube.jpg').default} height="38" width="38"/>
    ),
    Facebook: (
        <img alt="Facebook" src={require('@src/assets/images/icons/social/facebook.png').default} height="38"
             width="38"/>
    ),
    ACS: (
        <img
            alt="Church Planning Center"
            src={require('@src/assets/images/icons/brands/acs-label.png').default}
            height="38"
            width="38"
        />
    ),
    'File Uploader': <FileText size={38}/>,
    Zoom: <img alt="Zoom" src={require('@src/assets/images/icons/social/zoom.png').default} height="38" width="38"/>,
    Givelify: (
        <img
            alt="Church Planning Center"
            src={require('@src/assets/images/icons/brands/givelify-label.png').default}
            height="38"
            width="38"
        />
    ),
    'Fellowship One': (
        <img
            alt="Church Planning Center"
            src={require('@src/assets/images/icons/brands/fellowship-label.png').default}
            height="38"
            width="38"
        />
    ),
    'Planning Center': (
        <img
            alt="Church Planning Center"
            src={require('@src/assets/images/icons/brands/planing-center-label.svg').default}
            height="38"
            width="38"
        />
    ),
    OneChurch: (
        <img
            alt="Church Planning Center"
            src={require('@src/assets/images/icons/brands/one-church.png').default}
            height="38"
            width="38"
        />
    ),
    Instagram: (
        <img
            alt="Instagram"
            src={require('@src/assets/images/icons/social/instagram.png').default}
            height="38"
            width="38"
        />
    ),
    'Active Campaign': (
        <img
            alt="Church Planning Center"
            src={require('@src/assets/images/icons/brands/active-campaign-label.png').default}
            height="38"
            width="38"
        />
    ),
    Brushfire: (
        <img
            alt="Church Planning Center"
            src={require('@src/assets/images/icons/brands/brushfire-label.png').default}
            height="38"
            width="38"
        />
    ),
    'Church Community Builder': (
        <img
            alt="Church Planning Center"
            src={require('@src/assets/images/icons/brands/church-community-builder-label.png').default}
            height="38"
            width="38"
        />
    )
}

export const AUTOMATION_LOGO = {
    MailChimp: (
        <img
            alt="MailChimp"
            src={require('@src/assets/images/icons/social/mailchimp.png').default}
            height="38"
            width="38"
        />
    ),
    Twillio: (
        <img alt="Twillio" src={require('@src/assets/images/icons/social/twilio.svg').default} height="38" width="38"/>
    ),
    SurveyMonkey: (
        <img
            alt="SurveyMonkey"
            src={require('@src/assets/images/icons/social/surveyMonkey.png').default}
            height="38"
            width="38"
        />
    ),
    Outlook: (
        <img alt="Outlook" src={require('@src/assets/images/icons/social/outlook.svg').default} height="38" width="38"/>
    )
}
