//@@ Auth
import { createSelector } from '@reduxjs/toolkit'

export const authUserSelector = (s) => s.auth.userData
//@@ Layout
export const layoutSelector = (s) => s.layout.layout

//@@ File uploading
export const fileUploadingState = (s) => s.datastore.fileUpload
export const fileIdSelector = (s) => s.datastore.fileID

//@@ Csv
export const csvPendingState = (s) => s.csv.csvLoading

//@@ Church
export const currentChurchSelector = (s) => s.churches.church
export const churchLogoSelector = (s) => s.churches.logoUrl
export const churchUserChurchIDSelector = createSelector(authUserSelector, (s) => s?.churchID)
