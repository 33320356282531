//@@ Libs
import startCase from 'lodash/startCase'

//@@ Source parse msg
export const sourceParsedMessage = ({ source, action, properties }, sources) => {
  const sourceData = sources.find((v) => v.source === source)

  if (!sourceData) {
    return ''
  }

  const actionData = sourceData.actions?.find((v) => v.action === action)

  if (!actionData) {
    return ''
  }

  const propertiesData = actionData.properties
    .filter(({ property }) => properties.includes(property))
    .map(({ propertyName }) => propertyName)

  return `${startCase(actionData.actionName)} ${propertiesData.join(', ')} via ${sourceData.sourceName}`
}
