import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {UserProfileModel} from '@src/models/index'
import {api} from '@src/services/api/index'
import {createActionTypeFactoryFn} from '@src/utility/Utils'
import {DataStore} from 'aws-amplify'
import {omit} from 'lodash'
import {getJWTToken} from "@src/utility/auth/tokens"

/** This store slice handles all user entities in the app. */

const mapUsersArrById = (usersArr) => usersArr.reduce((acc, user) => ({...acc, [user.id]: user}), {})

export const USERS_SLICE_NAME = 'users'
const createUsersActionType = createActionTypeFactoryFn(USERS_SLICE_NAME)

// adds users array to the store
const ADD_USERS_ACTION_TYPE = createUsersActionType('addUsers')
export const addUsersAC = (usersArr) => ({type: ADD_USERS_ACTION_TYPE, payload: {usersArr}})

export const getAllUsers = createAsyncThunk(createUsersActionType('getAllUsers'), async () => {
    const users = await DataStore.query(UserProfileModel)
    return {users: mapUsersArrById(users)}
})

export const getUser = createAsyncThunk(createUsersActionType('getUser'), async ({userId}) => {
    const userData = await DataStore.query(UserProfileModel, userId)
    return {userData}
})

export const updateUser = createAsyncThunk(createUsersActionType('updateUser'), async ({userId, userData}) => {
    const original = await DataStore.query(UserProfileModel, userId)
    const updatedUserData = await DataStore.save(
        UserProfileModel.copyOf(original, (updated) => {
            for (const key in userData) {
                updated[key] = userData[key]
            }
        })
    )

    return {userData: updatedUserData}
})

export const deleteUser = createAsyncThunk(createUsersActionType('deleteUser'), async ({userId}) => {
    const datastoreUser = await DataStore.query(UserProfileModel, userId)
    await DataStore.delete(datastoreUser)
    const token = await getJWTToken()
    await api.post('delete-user', {userName: datastoreUser.cognitoId},
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    return {userId}
})

export const createUser = createAsyncThunk(createUsersActionType('createUser'), async ({user}) => {
    await DataStore.save(new UserProfileModel(user))
    return {userData: user}
})

const usersSlice = createSlice({
    name: USERS_SLICE_NAME,
    initialState: {
        users: {}
    },
    extraReducers: (builder) => {
        builder
            .addCase(ADD_USERS_ACTION_TYPE, (state, {payload}) => {
                state.users = {...state.users, ...mapUsersArrById(payload.usersArr)}
            })
            .addCase(getAllUsers.fulfilled, (state, {payload}) => {
                state.users = payload.users
            })
            .addCase(getUser.fulfilled, (state, {payload}) => {
                state.users = {...state.users, [payload.userData.id]: payload.userData}
            })
            .addCase(updateUser.fulfilled, (state, {payload}) => {
                state.users = {...state.users, [payload.userData.id]: payload.userData}
            })
            .addCase(deleteUser.fulfilled, (state, {payload}) => {
                state.users = omit(state.users, payload.userId)
            })
            .addCase(createUser.fulfilled, (state, {payload}) => {
                state.users = {...state.users, [payload.userData.id]: payload.userData}
            })
    }
})

export default usersSlice.reducer
