//@@ Libs
import { Col, Label, Row } from 'reactstrap'
import Flatpickr from 'react-flatpickr'

//@@ Utils
import { dateRangeToRaw, rawDateRangeToDate } from '@utils'

//@@ Components
import { AdaptiveLabel } from '@src/views/apps/reports/components/AdaptiveLabel/AdaptiveLabel'

export const FormBetweenRange = ({
  label,
  settingDates,
  onSettingChange,
  name = 'betweenDates',
  isRequired = false,
  fullWidth = false
}) => {
  return (
    <Row className="mb-2">
      <AdaptiveLabel>
        {label}
        {isRequired && <span className="requiredFormLabelAsterisk">*</span>}
      </AdaptiveLabel>
      <Col sm={fullWidth ? 8 : 3} xs={12}>
        <Flatpickr
          id="range-picker"
          value={rawDateRangeToDate(settingDates)}
          className="form-control"
          options={{
            mode: 'range',
            dateFormat: 'm-d-y'
          }}
          onChange={(dates) => onSettingChange({ name, value: dateRangeToRaw(dates) })}
        />
      </Col>
    </Row>
  )
}
