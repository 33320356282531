//@@ Libs
import { Label } from 'reactstrap'
import Select from 'react-select'

//@@ Utils
import { selectThemeColors } from '@utils'

export const FormPlainSelect = ({label, value, options, onChange, isMulti = false, isRequired = false}) => {
  return (
    <>
      <Label className="pt-0 fs-6">{label} {isRequired && <span className="requiredFormLabelAsterisk">*</span>}</Label>
      <Select
        isMulti={isMulti}
        isClearable={false}
        theme={selectThemeColors}
        className="react-select"
        classNamePrefix="select"
        value={value}
        placeholder="search"
        options={options}
        onChange={onChange}
      />
    </>
  )
}
