//@@ Libs
import { Label, Row, Spinner } from 'reactstrap'

//@@ Utils
import { getOptionFromRawValue, getOptionsFromRawValues, getRawValueFromOption } from '@utils'

//@@ Constants
import { ENGAGEMENT_OPTIONS } from '@src/views/apps/reports/constants'

//@@ Components
import UILoader from '@components/ui-loader'
import { Characteristic } from '@src/views/apps/reports/components/Characteristic/Characteristic'
import { FormSelect } from '@src/views/apps/reports/components/FormElements/FormSelect'
import { FormBetweenRange } from '@src/views/apps/reports/components/FormElements/FormBetweenRange'
import { FormForTheseSelect } from '@src/views/apps/reports/components/FormElements/FormForTheseSelect'
import { AdaptiveLabel } from '@src/views/apps/reports/components/AdaptiveLabel/AdaptiveLabel'

export const EngagementForm = ({
  isLoading,
  settings,
  settingsDictionaries,
  onSettingChange,
  onCharacteristicChange,
  onCharacteristicAdd,
  onCharacteristicValueChange,
  onCharacteristicRemove
}) => {
  return (
    <>
      <UILoader loader={<Spinner />} blocking={isLoading}>
        <FormSelect
          isRequired
          value={getOptionFromRawValue({ rawValue: settings.showMeCategory, options: ENGAGEMENT_OPTIONS })}
          options={ENGAGEMENT_OPTIONS}
          label="Show Me The"
          onChange={(opt) => onSettingChange({ name: 'showMeCategory', value: getRawValueFromOption(opt) })}
        />

        <FormForTheseSelect
          label="For These"
          categoriesOptions={settingsDictionaries.membership}
          categoriesValue={getOptionsFromRawValues({
            rawValue: settings.membership,
            options: settingsDictionaries.membership
          })}
          individual={settings.individual}
          onSettingChange={onSettingChange}
        />

        <Row className="mb-2">
          <AdaptiveLabel for="with" className="align-self-start">
            With These
          </AdaptiveLabel>
          <Characteristic
            characteristics={settings.characteristics}
            settingsDictionaries={settingsDictionaries}
            onCharacteristicChange={onCharacteristicChange}
            onCharacteristicAdd={onCharacteristicAdd}
            onCharacteristicValueChange={onCharacteristicValueChange}
            onCharacteristicRemove={onCharacteristicRemove}
          />
        </Row>

        <FormBetweenRange
          isRequired
          label="That Happened Between"
          settingDates={settings.betweenDates}
          onSettingChange={onSettingChange}
        />

        <FormBetweenRange
          isRequired
          label="Compare To"
          name="compareDates"
          settingDates={settings.compareDates}
          onSettingChange={onSettingChange}
        />
      </UILoader>
    </>
  )
}
