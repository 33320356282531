//@@ Libs
import React from 'react'

//@@ Constants
import { api } from '@src/services/api'
import {getJWTToken} from "@src/utility/auth/tokens"

export const useChurchEngagement = ({ churchID }) => {
  const [levels, setLevels] = React.useState({
    attendance: 0,
    bible_study: 0,
    cell_group: 0,
    giving: 0,
    ministry: 0,
    social_media: 0
  })
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(async () => {
    if (!churchID) {
      return
    }

    try {
      setIsLoading(true)
      const token = await getJWTToken()
      const { data } = await api.post('get-church-engagement', { churchID }, {
        headers: {
            Authorization: `Bearer ${token}`
        }
      })
      setIsLoading(false)
      setLevels(data)
    } catch (e) {
      setIsLoading(false)
    }
  }, [churchID])

  return { levels, isLoading }
}
