export const SUBJECTS = {
  dashboard: 'dashboard',
  profile: 'profile',
  staff: 'staff',
  churches: 'churches',
  church: 'church',
  churchUsers: 'church-users',
  connectedSources: 'connected-sources',
  analytics: 'analytics',
  reports: 'reports',
  profileMerge: 'profile-merge',
  churchReportsList: 'church-reports-list'
}
