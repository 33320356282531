//@@ Libs
import { useMemo, useRef } from 'react'
import moment from 'moment'

//@@ Store
import { useGetUserTimelineQuery } from '@src/redux/singleSourceApiSlice'

export const useTimeline = ({ churchID, profileId, queryOptions }) => {
  //@@ End date
  const { current: endDate } = useRef(moment().utc().toISOString())

  //@@ Template request
  const templateRequestBody = useMemo(
    () => ({
      start: '2020-01-01T00:00:00.000Z',
      end: endDate,
      churchID,
      userID: profileId
    }),
    [churchID, profileId, endDate, profileId]
  )
  return useGetUserTimelineQuery(templateRequestBody, queryOptions)
}
