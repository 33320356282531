//@@ Libs
import { Card, CardBody, CardHeader, CardTitle, ListGroup, ListGroupItem, Spinner } from 'reactstrap'
import { useMemo } from 'react'
import startCase from 'lodash/startCase'

//@@ Utils
import { getJourneyParsedMsg } from '@src/views/apps/reports/utils'
import { useGetReportChart } from '@src/views/apps/reports/hooks/useGetReportChart'
import { filterEventsFromJourneyResponse } from '../utils'

//@@ Components
import UILoader from '@components/ui-loader'

export const JourneyChart = ({ printRef, settings, queryState, changeQueryState, churchID, type, sources }) => {
  //@@ Chart state
  const {
    state: { isLoading, chartData }
  } = useGetReportChart({
    initialData: [],
    dataTransform: (data) => data,
    type,
    settings,
    churchID,
    changeQueryState,
    queryState
  })

  //@@ Parse query results
  const title = useMemo(() => {
    return getJourneyParsedMsg(settings, sources)
  }, [queryState])

  return (
    <UILoader loader={<Spinner />} blocking={isLoading}>
      <Card>
        <CardHeader>
          <CardTitle>Results</CardTitle>
        </CardHeader>
        <CardBody innerRef={printRef}>
          <CardTitle className="text-primary">{title}</CardTitle>

          <ListGroup tag="div">
            {chartData.length
              ? chartData
                  ?.filter(({ eventSource, eventCategory }) => {
                    return filterEventsFromJourneyResponse({
                      eventSource,
                      eventCategory,
                      settings
                    })
                  })
                  .map((data, i) => {
                    return (
                      <ListGroupItem key={i} tag="div" className="font-medium-1">
                        <span className="me-75">
                          {settings?.showMeFirstLast === 'First' ? i + 1 : chartData.length - i}.
                        </span>
                        {`${startCase(data.eventCategory)} (${startCase(data.eventSource)})`}
                      </ListGroupItem>
                    )
                  })
              : !isLoading && <h2 className="text-center text-warning">No Data Found</h2>}
          </ListGroup>
        </CardBody>
      </Card>
    </UILoader>
  )
}
