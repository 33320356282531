//@@ Libs
import {useCallback, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import debounce from 'lodash/debounce'

//@@ Utils
import {api} from '@src/services/api'
import {getJWTToken} from "@src/utility/auth/tokens"

export const useIndividualSelect = () => {
    // An object whose keys are profile IDs and values are the corresponding profile objects
    const [profiles, setProfiles] = useState({})

    // Just in case, clear the profiles object when the component unmounts
    useEffect(() => {
        return () => {
            setProfiles({})
        }
    }, [])
    //@@ Select church
    const church = useSelector((state) => state.churches.church)

    //@@ Load individual profiles options for select
    const loadOptions = useCallback(
        debounce((searchName, setOptions) => {
            getJWTToken().then(token => api.post('search-profiles', {
                    searchName,
                    churchID: church?.id
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then(({data}) => {
                setOptions(
                    data.map((profile) => ({
                        label: `${profile.first_name} ${profile.last_name} ${profile.member_suffix || ''}`,
                        value: profile.id
                    }))
                )
                setProfiles(profiles => {
                    for (const profile of data) {
                        profiles[profile.id] = profile
                    }
                    return {...profiles}
                })
            }))
        }, 800),
        []
    )

    return {loadOptions, profiles}
}
