// ** Redux Imports
import rootReducer from './rootReducer'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { logger } from 'redux-logger/src'
import { singleSourceApiSlice } from '@src/redux/singleSourceApiSlice'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'navbar',
    'layout',
    'auth',
    'pageInfo',
    'csv',
    'datastore',
    'profileResolution',
    'notifications',
    'goals',
    singleSourceApiSlice.reducerPath
  ]
}

const persistedReducer = persistReducer(persistConfig, combineReducers(rootReducer))

const isDev = process.env.NODE_ENV === 'development'

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: false
    })
    middlewares.push(singleSourceApiSlice.middleware)
    return middlewares
    // return isDev ? middlewares.concat(logger) : middlewares
  }
})

const persistor = persistStore(store)

export { store, persistor }
