//@@ Libs
import { createSlice } from '@reduxjs/toolkit'


const initialState = {
  //@todo remove this and store data in users slice to avoid data duplication
  offset: 1,
  limit: 10,
  total: 0,
  isLoading: true
}

export const profileSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    handlePageSlice: (state, action) => {
      return { ...state, ...action.payload }
    }
  }
})

export const { handlePageSlice } = profileSlice.actions

export default profileSlice.reducer