//@@ Libs
import toLower from 'lodash/lowerCase'
import startCase from 'lodash/startCase'
import moment from 'moment'
import get from 'lodash/get'

//@@ Utils
import { sourceParsedMessage } from '@src/views/apps/reports/features/Database/utils/sourceParse'

//@@ Constants
import { DEFAULT_FULL_DATE_FORMAT } from 'constants/datetime'

//@@ Unique event parsed message for query results
export const getUniqueEventParsedMsg = (showMeCategory, uniqueEvent) => {
  return `${showMeCategory ? toLower(showMeCategory) : ''} ${uniqueEvent ? uniqueEvent : ''}`
}

//@@ Selected period parsed message for query results
export const getHappenedBetweenParsedMsg = (betweenDates, prefix = 'between') => {
  if (!betweenDates?.[0] || !betweenDates?.[1]) {
    return ''
  }

  return `${prefix} ${moment(betweenDates[0]).format(DEFAULT_FULL_DATE_FORMAT)} and ${moment(betweenDates[1]).format(
    DEFAULT_FULL_DATE_FORMAT
  )}`
}

//@@ Categories parsed message for query results
export const getCategoriesParsedMsg = (membership, individual) => {
  if (!membership?.length && !individual?.length) {
    return ''
  }
  const individual_map = individual?.map(x => `${x.first_name} ${x.last_name}`)
  // must look check individual filter first, since membership will always contain "All"
  return `for these categories of people - ${
    individual_map?.length 
      ? individual_map.join(individual_map.length === 1 ? '' : ', ')
      : membership.map(toLower).join(membership.length === 1 ? '' : ', ')
  }`
}

//@@ Characteristics parsed message for query results
export const getCharacteristicsParsedMsg = (characteristics) => {
  return `${
    characteristics.length && characteristics?.some(({ value }) => !!value)
      ? `with the characteristics: ${characteristics
          .map(({ characteristic, value }) => {
            if (characteristic === 'gender' && !!value) {
              return `gender - ${value === 'M' ? 'male' : 'female'}`
            }
            if (characteristic === 'maritalStatuses' && !!value) {
              return `marital status - ${toLower(value)}`
            }
            if (characteristic === 'age' && !!value.from && !!value.to) {
              return `of age between ${value?.from} to ${value?.to}`
            }
          })
          .join(', ')}`
      : ''
  }`
}

//@@ Sources parsed message for query results
export const getSourceActionsParsedMsg = (source, type, prefix = 'starting') => {
  if (!source) {
    return ''
  }

  if (!source?.source && !source?.action && !source?.properties.length) {
    return ''
  }

  return `${type === 'end' ? ' and ' : ''}${prefix} ${
    source?.source ? `with ${startCase(source.source)} source` : ''
  } ${source?.action ? `with ${startCase(source.action)} action` : ''} ${
    source?.properties?.length ? `with the properties: ${source.properties.map(startCase).join(', ')}` : ''
  }`
}

//@@ Participated in parsed message for query results
export const getParticipatedParsedMsg = (participatedIn) => {
  if (!participatedIn) {
    return ''
  }

  return `that participated in ${startCase(participatedIn)}`
}

//@@ Journey `show me the` parsed message for query results
export const getJourneyShowMeTheParsedMsg = (showMeCategory) => {
  if (!showMeCategory) {
    return ''
  }
  return `${showMeCategory} 5 most common actions`
}

//@@ Get journey parsed message
export const getJourneyParsedMsg = (
  { showMeFirstLast, startSource, endSource, betweenDates, membership, individual, characteristics },
  sources
) => {
  return `Showing the ${showMeFirstLast.toLowerCase()} 5 most common actions that occur between ${sourceParsedMessage(
    startSource,
    sources
  )} and ${sourceParsedMessage(endSource, sources)} that occurred ${getHappenedBetweenParsedMsg(
    betweenDates
  )} ${getCategoriesParsedMsg(membership, individual)} ${getCharacteristicsParsedMsg(characteristics)}.`
}

//@@ Check required fields fill for query disabled state
export const checkRequiredFieldsFill = (required, settings) => {
  return required.every((field) => {
    const value = get(settings, field)
    if (Array.isArray(value)) {
      return !!value.length
    }
    return !!value
  })
}

//@@ Get required fields for report by type
export const getSourceRequiredFields = (required, type) => {
  const requiredSourceFields = []
  if (required.includes(`${type}Source.action`)) {
    requiredSourceFields.push('action')
  }
  if (required.includes(`${type}Source.source`)) {
    requiredSourceFields.push('source')
  }
  if (required.includes(`${type}Source.properties`)) {
    requiredSourceFields.push('properties')
  }
  return requiredSourceFields
}

//@@ Get selected sources for pdf report view
export const getSourcesForPdfReport = (startSource, endSource, sources) => {
  if (startSource === 'all' || endSource === 'all') {
    return sources.map(({ sourceName }) => startCase(sourceName))
  }
  if (!!startSource && startSource === endSource) {
    return [startCase(startSource)]
  }
  return [startSource, endSource].filter(Boolean).map(toLower).map(startCase)
}

//@@ Get Source Actions
export const getSourceActionsDictionary = ({ sources, rawValue }) => {
  if (rawValue === 'all') {
    return sources.reduce((acc, v) => {
      acc.push(
        ...v.actions.map(({ action, actionName }) => ({
          value: `${v.source}.${action}`,
          label: `${v.sourceName} - ${actionName}`
        }))
      )
      return acc
    }, [])
  }
  const source = sources.find(({ source }) => source === rawValue)

  return source ? source.actions.map(({ action, actionName }) => ({ value: action, label: actionName })) : []
}
//@@ Transform action giving property
export const transformGivingProperty = (prop) => {
  const strProp = prop.toString()
  if (strProp[0] === '>' || strProp[0] === '<') {
    return `${strProp[0]} $${strProp.slice(1)}`
  }
  if (strProp.includes('-')) {
    const values = strProp.split('-')
    return values.map((v) => `$${v}`).join(' - ')
  }
  return `$${strProp}`
}

//@@ Get giving transformed properties
export const getGivingTransformedProperties = ({ actionData }) => {
  return actionData.properties.map(({ property, propertyName }) => ({
    value: property,
    label: transformGivingProperty(propertyName)
  }))
}

//@@ Get Source Properties
export const getSourceProperties = ({ sources, rawValue, source }) => {
  const sourceItem = sources.find((v) => {
    if (source === 'all') {
      return (
        v.source === rawValue.toString().split('.')?.[0] &&
        v?.actions?.find(({ action }) => {
          return rawValue.toString().includes('.') ? action === rawValue.split('.')?.[1] : action === rawValue
        })
      )
    }
    return (
      v.source === source &&
      v?.actions?.find(({ action }) => {
        return rawValue.toString().includes('.') ? action === rawValue.split('.')?.[1] : action === rawValue
      })
    )
  })
  const actionData = sourceItem?.actions?.find(({ action }) => {
    return rawValue.toString().includes('.') ? action === rawValue.split('.')?.[1] : action === rawValue
  })
  if (!actionData) {
    return []
  }

  if (actionData?.action === 'giving') {
    return getGivingTransformedProperties({ actionData })
  }
  return actionData.properties.map(({ property, propertyName }) => ({
    value: property,
    label: propertyName
  }))
}

//@@ Serialize source
export const serializeSource = ({ sources, source }) => {
  if (source.source === 'all') {
    const [sourceValue, actionValue] = source?.action?.split('.')
    return { ...source, source: sourceValue, action: actionValue }
  }
  return source
}

//@@ Get Source by value
export const getSourceByValue = (sources, value) => {
  return sources.find(({ source }) => source === value)
}
