//@@ Libs
import { Card, CardBody, CardFooter } from 'reactstrap'
import { useState } from 'react'

//@@ Utils
import { useReportData } from '@src/views/apps/reports/hooks/useReportData'
import { useDatabaseMenu } from '@src/views/apps/reports/hooks/useDatabaseMenu'
import { useSettingsForm } from '@src/views/apps/reports/hooks/useSettingsForm'
import { useSourcesForm } from '@src/views/apps/reports/hooks/useSourcesForm'
import {
  checkRequiredFieldsFill,
  getSourceByValue,
  getSourcesForPdfReport,
  serializeSource
} from '@src/views/apps/reports/utils'

//@@ Constants
import {
  DATABASE_ACTION_TYPE,
  DATABASE_REQUIRED_FIELDS,
  METRIC_TYPE,
  METRIC_TYPE_DESCRIPTION,
  METRIC_TYPE_TITLES,
  SOURCES_BY_DATA_REPORTS
} from '@src/views/apps/reports/constants'
import { LOADING_STATE } from 'constants/api'

//@@ Components
import { ReportHeader } from '@src/views/apps/reports/components/Header/ReportHeader'
import { DatabaseFormWrapper } from '../DatabaseFormWrapper/DatabaseFormWrapper'
import { ReportActions } from '../Actions/ReportActions'
import { DatabaseChartWrapper } from '../DatabaseChartWrapper/DatabaseChartWrapper'
import { SaveModal } from '@src/views/apps/reports/components/SaveModal/SaveModal'

export const DatabaseWrapper = ({ type }) => {
  //@@ Load report data if on concrete report chart
  const report = useReportData()
  //@@ Query run state
  const [queryState, setQueryState] = useState(LOADING_STATE.IDLE)
  //@@ Sources from data
  const [sourcesFromData, setSourcesFromData] = useState([])
  //@@ Csv rows
  const [csvRows, setCsvRows] = useState([])

  //@@ Form settings hook
  const {
    state: { settingsDictionaries, isLoading, settings: commonSettings, churchID },
    methods: {
      handleCharacteristicChange,
      handleCharacteristicAdd,
      handleCharacteristicValueChange,
      handleCharacteristicRemove,
      handleSettingChange
    }
  } = useSettingsForm(report)

  //@@ Sources settings hook
  const {
    startSource,
    endSource,
    sources,
    dictionaries: {
      sourcesDictionary,
      startSource: {
        actionDictionary: startSourceActionDictionary,
        propertiesDictionary: startSourcePropertiesDictionary
      },
      endSource: { actionDictionary: endSourceActionDictionary, propertiesDictionary: endSourcePropertiesDictionary }
    },
    handleChangeSource,
    handleChangeAction,
    handleChangeProperties
  } = useSourcesForm(report)

  //@@ Database actions list
  const actions = [{ type: DATABASE_ACTION_TYPE.query, onClick: () => setQueryState(LOADING_STATE.PENDING) }]

  //@@ Required fields
  const requiredFields = DATABASE_REQUIRED_FIELDS?.[type]

  //@@ Settings
  const settings = { ...commonSettings, startSource, endSource }

  //@@ Get database menu
  const {
    state: { printRef, isSaveModalOpen, menuItems, isEdit },
    methods: { handleCloseSaveModal, handleSettingsSave }
  } = useDatabaseMenu({
    type,
    sources: SOURCES_BY_DATA_REPORTS.includes(type)
      ? sourcesFromData
      : getSourcesForPdfReport(
          getSourceByValue(sources, startSource.source)?.sourceName,
          getSourceByValue(sources, endSource.source)?.sourceName,
          sources
        ),
    printDisabled: queryState !== LOADING_STATE.FINISHED,
    csvRows
  })

  return (
    <>
      <ReportHeader
        title={METRIC_TYPE_TITLES?.[METRIC_TYPE?.[type]] ?? '-'}
        description={METRIC_TYPE_DESCRIPTION?.[METRIC_TYPE?.[type]]}
        menu={menuItems}
      />
      <Card className="border-secondary">
        <CardBody>
          <DatabaseFormWrapper
            isLoading={isLoading}
            type={type}
            settings={settings}
            settingsDictionaries={{
              ...settingsDictionaries,
              sourcesDictionary,
              startSourceActionDictionary,
              startSourcePropertiesDictionary,
              endSourceActionDictionary,
              endSourcePropertiesDictionary
            }}
            requiredFields={requiredFields}
            onChangeSource={handleChangeSource}
            onChangeAction={handleChangeAction}
            onChangeProperties={handleChangeProperties}
            onSettingChange={handleSettingChange}
            onCharacteristicChange={handleCharacteristicChange}
            onCharacteristicAdd={handleCharacteristicAdd}
            onCharacteristicValueChange={handleCharacteristicValueChange}
            onCharacteristicRemove={handleCharacteristicRemove}
          />
          <ReportActions
            disabled={
              isLoading || queryState === LOADING_STATE.PENDING || !checkRequiredFieldsFill(requiredFields, settings)
            }
            actions={actions}
          />
        </CardBody>
        <CardFooter className="font-medium-1">
          The SingleSource data model is comprised of six components: People, Actions, Sources, Characteristics,
          Properties and Time. Understanding these concepts is vital to being able to analyze the data we are
          collecting. A person is our representation of a user visiting your church, virtually or physically, that have
          been documented in a data source. A characteristic is any additional information we know about the person from
          any data source. A person can perform actions. Properties help describe a person’s actions. Time is when a
          person’s actions are documented, manually or automatically, by any data source.
        </CardFooter>
      </Card>

      {queryState !== LOADING_STATE.IDLE && (
        <DatabaseChartWrapper
          type={type}
          printRef={printRef}
          settings={{
            ...commonSettings,
            membership: !!commonSettings.membership.length ? commonSettings.membership : ['All'],
            startSource: serializeSource({ source: startSource, sources }),
            endSource: serializeSource({ source: endSource, sources })
          }}
          sources={sources}
          queryState={queryState}
          changeQueryState={setQueryState}
          churchID={churchID}
          setSourcesFromData={setSourcesFromData}
          setCsvRows={setCsvRows}
        />
      )}

      {isSaveModalOpen && (
        <SaveModal
          isOpen={isSaveModalOpen}
          reportName={report?.name}
          onClose={handleCloseSaveModal}
          onConfirm={(name) => {
            handleSettingsSave({
              name,
              type,
              settings: {
                ...commonSettings,
                membership: !!commonSettings.membership.length ? commonSettings.membership : ['All'],
                startSource: serializeSource({ source: startSource, sources }),
                endSource: serializeSource({ source: endSource, sources })
              },
              id: isEdit ? report?.id : undefined
            })
          }}
        />
      )}
    </>
  )
}
