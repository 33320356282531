//@@ Libs
import { Col, Label, Row } from 'reactstrap'

//@@ Utils
import { getOptionFromRawValue, getOptionsFromRawValues } from '@utils'
import { useLayout } from '@hooks/useLayout'

//@@ Constants
import { LAYOUT_TYPE } from '@src/constants'

//@@ Components
import { FormPlainSelect } from '@src/views/apps/reports/components/FormElements/FormPlainSelect'

export const FormSourceActions = ({
                                    actionDictionary,
                                    action,
                                    sourcesDictionary,
                                    source,
                                    propertiesDictionary,
                                    properties,
                                    onChangeAction,
                                    onChangeProperties,
                                    onChangeSource,
                                    firstLabel,
                                    lastLabel,
                                    isSourceHidden = false,
                                    requiredFields = []
                                  }) => {
  const { layout } = useLayout()

  return (
    <Row className="mb-1">
      <Col xs={12} sm={6}>
        <Row className="mb-1">
          <Col sm={4} xs={12}>
            <Label for="startingWith" className={`pt-0 fs-${layout === LAYOUT_TYPE.vertical ? 4 : 6}`}>
              {firstLabel}
            </Label>
          </Col>
          <Col sm={8} xs={12}>
            <Row>
              {!isSourceHidden && (
                <Col xs={6}>
                  <FormPlainSelect
                    isRequired={requiredFields.includes('source')}
                    label="Source"
                    options={sourcesDictionary}
                    value={getOptionFromRawValue({
                      rawValue: source,
                      options: sourcesDictionary
                    })}
                    onChange={onChangeSource}
                  />
                </Col>
              )}
              <Col xs={6}>
                <FormPlainSelect
                  label="Actions"
                  isRequired={requiredFields.includes('action')}
                  options={actionDictionary}
                  value={getOptionFromRawValue({
                    rawValue: action,
                    options: actionDictionary
                  })}
                  onChange={onChangeAction}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Col xs={12} sm={6}>
        <Row className="mb-1">
          <Col sm={4} xs={12}>
            <Label for="startingThis" className={`pt-0 fs-${layout === LAYOUT_TYPE.vertical ? 4 : 6}`}>
              {lastLabel}
            </Label>
          </Col>
          <Col sm={8} xs={12}>
            <FormPlainSelect
              isMulti
              isRequired={true}
              label="Properties"
              options={propertiesDictionary.length > 0 ? propertiesDictionary : [{ value: 'N\\A', label: 'N\\A' }]}
              value={getOptionsFromRawValues({
                rawValue: properties,
                options: propertiesDictionary
              })}
              onChange={onChangeProperties}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
