//@@ Libs
import { useRef } from 'react'
import { Button, Card, CardBody, CardHeader, CardSubtitle, CardText, CardTitle, Col, Row, Spinner } from 'reactstrap'
import { ChevronLeft, ChevronRight } from 'react-feather'
import moment from 'moment'
import take from 'lodash/take'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

//@@ Hooks
import { useSize } from '@src/utility/hooks/useSize'
import { useTimeline } from '@src/views/apps/profiles/hooks/useTimeline'

//@@ Utils
import {
  getBaptizedDate,
  getProfileFullNameWithSuffix,
  getProfileLastTouchStatus,
  getProfileNameInitials,
  getSalvationDate
} from '@src/views/apps/profiles/list/utils'
import { getReadableDate } from '@src/utility/Utils'

//@@ Hooks
import { useProfileTimeline } from '@src/views/apps/profiles/hooks/useProfileTimeline'

//@@ Store
import { profilesConflictsIds } from '@src/views/apps/notifications/store/selectors'

//@@ Constants
import { DEFAULT_SHORT_DATE_FORMAT } from 'constants/datetime'

//@@ Components
import Avatar from '@components/avatar'
import UserTimeline from '@components/apps/UserTimeline/UserTimeline'
import { BasicInfo } from '@src/views/apps/profiles/list/components/BasicInfo/BasicInfo'
import { SocialMediaInfo } from '@src/views/apps/profiles/list/components/SocialMediaInfo/SocialMediaInfo'
import { ProfileBreadCrumbs } from '@src/views/apps/profiles/list/components/ProfileBreadCrumbs'
import UILoader from '@src/@core/components/ui-loader/index'
import ProfileSubscriptions from "@src/views/apps/profiles/view/components/Subscriptions/ProfileSubscriptions"

export const ProfileInfo = ({ profile, onBackResolve, churchID }) => {
  //@@ Need it to make timeline the same height as profile
  const leftColumnRef = useRef(null)
  const leftColumnSize = useSize(leftColumnRef)
  //@@ Timeline hook
  const { data: timeline, isLoading } = useTimeline({ churchID, profileId: profile?.id })
  
  //@@ Selectors
  const conflicts = useSelector(profilesConflictsIds)
  const hasDuplicates = !!conflicts?.includes(profile?.id)

  const times =  useProfileTimeline({profile, timeline})
  //@@ Joined & First Visit church info getter
  const getDateChurchInfo = (profile) => {
    if (profile?.created_at) {
      return getReadableDate(profile?.created_at)
    }
    if (profile?.join_date) {
      return moment(profile.join_date).format(DEFAULT_SHORT_DATE_FORMAT)
    }
    return '-'
  }

  return (
    <>
      <ProfileBreadCrumbs />
      <Row>
        <Col xs={12} md={6}>
          <div ref={leftColumnRef}>
            <Card className="profile-info">
              <CardHeader className="flex-column">
                <CardTitle className="d-flex align-items-center mb-2 full-width justify-content-between">
                  <Button.Ripple className="btn-icon rounded-circle" color="flat-primary" onClick={onBackResolve}>
                    <ChevronLeft size={16} />
                  </Button.Ripple>
                  <div>{getProfileFullNameWithSuffix(profile)}</div>
                  <Button.Ripple tag={Link} to="engagement" className="btn-icon rounded-circle" color="flat-primary">
                    <ChevronRight size={16} />
                  </Button.Ripple>
                </CardTitle>
                <CardSubtitle>
                  <Avatar
                    img={profile?.avatar ?? undefined}
                    size="lg"
                    content={!profile?.avatar ? getProfileNameInitials(profile) : undefined}
                  />
                </CardSubtitle>
                <CardText>
                  <span>{profile?.birthdate ? `${moment().diff(profile.birthdate, 'year')}, ` : ''}</span>
                  <span>{profile?.gender ?? ''}</span>
                </CardText>

                <div>
                  <span className="fw-bolder me-1 text-center d-flex">{getProfileLastTouchStatus(profile)}</span>
                </div>
                <div>
                  <span className="fw-bolder">Overall Engagement Level:&nbsp;</span>
                  <span>{profile?.overall_engagement !== null ? Math.round(profile?.overall_engagement) : '-'}%</span>
                </div>
              </CardHeader>
              <CardBody>
                <div className="text-center">
                  <div>Salvation: {getSalvationDate(profile, timeline)}</div>
                  <div>Baptism: {getBaptizedDate(profile, timeline)}</div>
                  <div>Joined Church: {getDateChurchInfo(profile)}</div>
                </div>

                <BasicInfo profile={profile} />
                <ProfileSubscriptions profile={profile}/>
                <SocialMediaInfo profile={profile} />

                <div className="d-flex justify-content-end">
                  {hasDuplicates && (
                    <Button color="warning" tag={Link} to={`/profile-merge/${profile.id}`}>
                      Profile Resolution
                    </Button>
                  )}
                </div>
              </CardBody>
            </Card>
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div>
            <UILoader
              loader={
                <div className="mt-3">
                  <Spinner />
                </div>
              }
              blocking={isLoading}
              style={{ height: leftColumnSize?.height }}
              className="overflow-auto"
            >
              <UserTimeline className="minh-100" timeline={take(times, 10)} title="RECENT PROFILE TIMELINE" />
            </UILoader>
          </div>
        </Col>
      </Row>
    </>
  )
}
