import styled from "styled-components"
import {CONNECTED_SOURCES_LOGO} from "constants/assets"
import React from "react"
import CheckIcon from "@src/views/apps/profiles/view/components/Subscriptions/CheckIcon"


const GrayedDiv = styled.div`
    filter: ${(props) => (props.active ? 'grayscale(0%)' : 'grayscale(100%)')};
`
const ConditionalSocialMediaLogo = ({active, follower, source}) => {
    return (
        <tr className="border-bottom">
            <td className="d-inline-flex align-middle border-bottom-0">
                <GrayedDiv active={active} className="me-1">
                    {CONNECTED_SOURCES_LOGO[source]}
                </GrayedDiv>
                <div className="d-flex align-items-center">
                    <span>{source}</span>
                </div>
            </td>
            <td>{follower && <CheckIcon/>}</td>
        </tr>
    )
}

export default ConditionalSocialMediaLogo