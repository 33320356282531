//@@ Get Time Msg from milliseconds
export function simplifiedMilliseconds(milliseconds) {
  const totalSeconds = parseInt(Math.floor(milliseconds / 1000))
  const totalMinutes = parseInt(Math.floor(totalSeconds / 60))
  const totalHours = parseInt(Math.floor(totalMinutes / 60))
  const days = parseInt(Math.floor(totalHours / 24))

  const seconds = parseInt(totalSeconds % 60)
  const minutes = parseInt(totalMinutes % 60)
  const hours = parseInt(totalHours % 24)

  let time = '- s'
  if (days > 0) {
    time = `${days} Days, ${hours} Hours And ${minutes} Minutes`
  } else if (hours > 0) {
    time = `${hours} ${hours === 1 ? 'Hour' : 'Hours'} And ${minutes} Minutes`
  } else if (minutes > 0) {
    time = `${minutes} Minutes`
  } else if (seconds > 0) {
    time = `${seconds} Seconds`
  }
  return time
}
