//@@ Libs
import { Label } from 'reactstrap'
import classNames from 'classnames'

//@@ Hooks
import { useLayout } from '@hooks/useLayout'

//@@ Constants
import { LAYOUT_TYPE } from '@src/constants'

export const AdaptiveLabel = ({ children, className, fullWidth = false, ...props }) => {
  const { layout } = useLayout()

  return (
    <Label
      sm={fullWidth ? 12 : 4}
      xs={12}
      className={classNames('pt-0', `fs-${layout === LAYOUT_TYPE.vertical ? 4 : 6}`, className)}
      {...props}
    >
      {children}
    </Label>
  )
}
