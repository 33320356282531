//@@ Libs
import { useSelector } from 'react-redux'

//@@ Utils
import { usePrint } from '@src/views/apps/reports/hooks/usePrint'

//@@ Constants
import { WEB_INSIGHTS_DESCRIPTION, WEB_INSIGHTS_TITLES } from '@src/views/apps/reports/constants'

export const useReportMenu = ({ type, sources, printDisabled }) => {
  const church = useSelector((state) => state.churches.church)

  //@@ Use print hook
  const {
    state: { printRef, printMenuItems }
  } = usePrint({
    titles: WEB_INSIGHTS_TITLES,
    descriptions: WEB_INSIGHTS_DESCRIPTION,
    church,
    type,
    sources,
    printDisabled
  })

  //@@ Actions for dropdown menu
  const menuItems = [...printMenuItems]

  return { menuItems, printRef }
}
