//@@ Libs
import { AlertTriangle, Check, X } from 'react-feather'

//@@ Notification types
export const NOTIFICATION_TYPE = {
  PROFILE_RESOLUTION: 'PROFILE_RESOLUTION',
  GOAL_ACHIEVED: 'GOAL_ACHIEVED'
}

//@@ Icon and color map by notification
export const AVATAR_CONTENT_BY_STATUS = {
  [NOTIFICATION_TYPE.GOAL_ACHIEVED]: {
    icon: <Check size={14} />,
    color: 'light-success'
  },
  // error: {
  //   icon: <X size={14} />,
  //   color: 'light-danger'
  // },
  [NOTIFICATION_TYPE.PROFILE_RESOLUTION]: {
    icon: <AlertTriangle size={14} />,
    color: 'light-warning'
  }
}
