//@@ Libs
import { createSelector } from '@reduxjs/toolkit'

//@@ Constants
import { NOTIFICATION_TYPE } from '@src/views/apps/notifications/constants'

export const notificationsSelector = (s) => s.notifications.data
export const notificationsLoadingStateSelector = (s) => s.notifications.loadingState

export const profilesConflictsIds = createSelector(notificationsSelector, (s) => {
  return s
    .filter(({ notificationType }) => notificationType === NOTIFICATION_TYPE.PROFILE_RESOLUTION)
    .map(({ objectID }) => objectID)
})
