//@@ Libs
import { Navbar } from 'reactstrap'
import classnames from 'classnames'

//@@ Hooks
import { useSkin } from '@hooks/useSkin'
import { useNavbarType } from '@hooks/useNavbarType'

//@@ Components
import MenuComponent from '@layouts/components/menu/horizontal-menu'

export const MainMenu = ({ isHidden, bgColorCondition, navbarClasses, menu, menuData }) => {
  const { skin } = useSkin()
  const { navbarType } = useNavbarType()

  return !isHidden ? (
    <div className="horizontal-menu-wrapper">
      <Navbar
        tag="div"
        expand="sm"
        light={skin !== 'dark'}
        dark={skin === 'dark' || bgColorCondition}
        className={classnames(`header-navbar navbar-horizontal navbar-shadow menu-border`, {
          [navbarClasses[navbarType]]: navbarType !== 'static',
          'floating-nav': (!navbarClasses[navbarType] && navbarType !== 'static') || navbarType === 'floating'
        })}
      >
        {menu ? menu({ menuData }) : <MenuComponent menuData={menuData} />}
      </Navbar>
    </div>
  ) : null
}
