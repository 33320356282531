//@@ Constants
import { WEB_INSIGHTS_TYPE } from '@src/views/apps/reports/constants'

//@@ Utils
import { useInsightsActions } from '@src/views/apps/reports/features/WebInsights/hooks/useInsightsActions'

//@@ Components
import { ReportWrapper } from '@src/views/apps/reports/features/WebInsights/components/ReportWrapper/ReportWrapper'
import { ReportFormWrapper } from '@src/views/apps/reports/features/WebInsights/components/ReportFormWrapper/ReportFormWrapper'
import { FunnelForm } from '@src/views/apps/reports/features/WebInsights/features/Funnel/components/FunnelForm'
import { FunnelChart } from '@src/views/apps/reports/features/WebInsights/features/Funnel/components/FunnelChart'

export const FunnelReport = () => {
  const { isShown, handleShowToggle } = useInsightsActions()

  return (
    <>
      <ReportFormWrapper type={WEB_INSIGHTS_TYPE.funnel}>
        <ReportWrapper onSaveClick={handleShowToggle}>
          <FunnelForm />
        </ReportWrapper>{' '}
        {isShown && <FunnelChart />}
      </ReportFormWrapper>
    </>
  )
}
