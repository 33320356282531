//@@ Libs
import React, {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {Card, CardBody, CardHeader, CardTitle, ListGroup, Spinner, Table} from 'reactstrap'
import UILoader from '@src/@core/components/ui-loader/index'
import '@styles/react/apps/app-users.scss'
import {useGetChurchProfileSubscriptionsQuery} from "@store/singleSourceApiSlice"
import ConditionalSocialMediaLogo
    from "@src/views/apps/profiles/view/components/Subscriptions/ConditionalSocialMediaLogo"

const ProfileSubscriptions = ({profile}) => {
    if (!profile) return null
    const {id, first_name, last_name} = profile

    const churchID = useSelector((state) => state.auth.userData?.churchID)
    const templateRequestBody = useMemo(
        () => ({
            churchID,
            profileId: id
        }),
        [churchID, id]
    )

    const {data: profileSubscriptions, isLoading} = useGetChurchProfileSubscriptionsQuery(templateRequestBody)

    if (isLoading) {
        return (
            <UILoader
                loader={
                    <div>
                        <Spinner/>
                    </div>
                }
                blocking={isLoading}
                style={{minHeight: '30rem'}}
                className="overflow-auto"
            ></UILoader>
        )
    }
    const subscriptions = profileSubscriptions || {}
    const isSubscriberInFacebook = !!subscriptions.facebook_subscriber
    const isActiveInFacebook = isSubscriberInFacebook || !!subscriptions.facebook
    const isSubscriberInYouTube = !!subscriptions.youtube_subscriber
    const isActiveInYouTube = isSubscriberInYouTube || !!subscriptions.youtube
    const isSubscriberInInstagram = !!subscriptions.instagram_subscriber
    const isActiveInInstagram = isSubscriberInInstagram || !!subscriptions.instagram
    const isSubscriberInTwitter = !!subscriptions.twitter_subscriber
    const isActiveInTwitter = isSubscriberInTwitter || !!subscriptions.twitter
    const isSubscriberInLinkedIn = !!subscriptions.linkedin_subscriber
    const isActiveInLinkedIn = isSubscriberInLinkedIn || !!subscriptions.linkedin
    const isSubscriberInTiktok = !!subscriptions.tiktok_subscriber
    const isActiveInTiktok = isSubscriberInTiktok || !!subscriptions.tiktok


    return (
        <>
            <Card>
            <CardHeader className="text-center pb-0">
                <CardTitle tag="h4">
                    DIGITAL FOOTPRINT
                </CardTitle>
            </CardHeader>
            <UILoader loader={<Spinner/>} blocking={isLoading}>
                <CardBody>
                    <Table responsive className="">
                        <thead>
                        <tr>
                            <th>Source</th>
                            <th>Follower/Subscriber</th>
                        </tr>
                        </thead>
                        <tbody>
                            <ConditionalSocialMediaLogo active={isActiveInFacebook} follower={isSubscriberInFacebook} source="Facebook"/>
                            <ConditionalSocialMediaLogo active={isActiveInYouTube} follower={isSubscriberInYouTube} source="YouTube"/>
                            <ConditionalSocialMediaLogo active={isActiveInTwitter} follower={isSubscriberInTwitter} source="Twitter"/>
                            <ConditionalSocialMediaLogo active={isActiveInLinkedIn} follower={isSubscriberInLinkedIn} source="LinkedIn"/>
                            <ConditionalSocialMediaLogo active={isActiveInInstagram} follower={isSubscriberInInstagram} source="Instagram"/>
                            <ConditionalSocialMediaLogo active={isActiveInTiktok} follower={isSubscriberInTiktok} source="Tiktok"/>
                        </tbody>
                    </Table>
                </CardBody>
            </UILoader>
        </Card>
        </>
    )
}

export default ProfileSubscriptions
