//@@ Libs
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { churchLogoSelector, currentChurchSelector } from '@store/selectors'
import { useLayoutEffect } from 'react'

//@@ Internals
import { appsNavItems } from '@src/navigation/common/apps'
import { canViewMenuItem } from '@layouts/utils'
import { loadLogoUrl } from '@src/views/apps/churches/store'
import { ChurchAvatar } from '@components/apps/ChurchAvatar/ChurchAvatar'

export const ChurchNavLink = () => {
  //@@ CurrentChurch
  const currentChurch = useSelector(currentChurchSelector)
  const churchLogo = useSelector(churchLogoSelector)

  const dispatch = useDispatch()

  useLayoutEffect(() => {
    dispatch(loadLogoUrl({ name: currentChurch?.logo }))
  }, [currentChurch?.logo])

  //@@ Get church nav link item from common apps list
  const churchNavItem = appsNavItems.find(({ id }) => id === 'church')

  //@@ Return link if available
  return (
    canViewMenuItem(churchNavItem) && (
      <li className="nav-item">
        <Link to={churchNavItem.navLink} className="d-flex flex-column align-items-center nav-link">
          <ChurchAvatar logoUrl={churchLogo} size="sm" />
        </Link>
      </li>
    )
  )
}
