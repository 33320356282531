import { useRTL } from '@hooks/useRTL'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { usePopper } from 'react-popper'
import { applyHeight } from '@layouts/utils'
import { useState } from 'react'

export const useLayoutMenu = ({ isChild }) => {
  //@@ State
  const [menuOpen, setMenuOpen] = useState(false)
  const [popperElement, setPopperElement] = useState(null)
  const [referenceElement, setReferenceElement] = useState(null)

  //@@ Hooks
  const [isRtl] = useRTL()

  //@@ Poper Vars
  const popperOffsetHorizontal = isRtl ? 16 : -16
  const popperPlacement = isRtl ? 'bottom-end' : 'bottom-start'
  const popperPlacementSubMenu = isRtl ? 'left-start' : 'right-start'

  //@@ Hooks
  const { t } = useTranslation()
  const currentURL = useLocation().pathname
  const { update, styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: isChild ? popperPlacementSubMenu : popperPlacement,
    modifiers: [
      applyHeight,
      {
        enabled: true,
        name: 'offset',
        options: {
          offset: isChild ? [-8, 15] : [popperOffsetHorizontal, 5]
        }
      }
    ]
  })

  const handleMouseEnter = () => {
    setMenuOpen(true)
    update()
  }

  return {
    state: { menuOpen, currentURL, t, styles, attributes },
    methods: { setMenuOpen, handleMouseEnter, setReferenceElement, setPopperElement }
  }
}
