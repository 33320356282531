//@@ Libs
import { Card, CardBody, CardHeader, CardTitle, ListGroup, ListGroupItem } from 'reactstrap'
import { Home, Mail, Smartphone, User, MessageSquare } from 'react-feather'

//@@ Utils
import { getProfileAddress, getProfileFullNameWithSuffix, getProfilePhoneNumber } from '@src/views/apps/profiles/list/utils'

export const BasicInfo = ({ profile }) => {
  return profile ? (
    <Card>
      <CardHeader>
        <CardTitle>BASIC PROFILE</CardTitle>
      </CardHeader>
      <CardBody>
        <ListGroup>
          <ListGroupItem className="d-flex">
            <span className="me-1">
              <User size={16} />
            </span>
            <span>{getProfileFullNameWithSuffix(profile)}</span>
          </ListGroupItem>
          <ListGroupItem className="d-flex">
            <span className="me-1">
              <Mail size={16} />
            </span>
            <span>{profile?.email ?? '-'}</span>
          </ListGroupItem>
          <ListGroupItem className="d-flex">
            <span className="me-1">
              <Smartphone size={16} />
            </span>
            <span>{getProfilePhoneNumber(profile)}</span>
          </ListGroupItem>
          <ListGroupItem className="d-flex">
            <span className="me-1">
              <Home size={16} />
            </span>
            <span>{getProfileAddress(profile)}</span>
          </ListGroupItem>
          {profile.last_note &&
            <ListGroupItem className="d-flex">
              <span className="me-1">
                <MessageSquare size={16} />
              </span>
              <span>Last Note: {profile.last_note} on {(new Date(profile.last_note_on)).toLocaleDateString()}</span>
            </ListGroupItem>
          }
        </ListGroup>
      </CardBody>
    </Card>
  ) : ""
}
