/**
 * Parse number to string, by rounding it and then converting to locale string to split it by thousands
 * @param value
 * @returns {string}
 */
export const parseNumber = (value) => {
    if (!value && value !== 0) {
        return ''
    }
    return Math.round(value).toLocaleString()
}
