//@@ Libs
import { Button, Card, CardHeader, CardTitle, Col, Row } from 'reactstrap'
import { ChevronLeft } from 'react-feather'

//@@ Utils
import { getProfileFullName } from '@src/views/apps/profiles/list/utils'

//@@ Components
import UserTimeline from '@components/apps/UserTimeline/UserTimeline'
import { EngagementLevelsCard } from '@src/@core/components/apps/EngagementLevels/EngagementLevelsCard'

//@@ Hooks
import { useProfileTimeline } from '@src/views/apps/profiles/hooks/useProfileTimeline'

//@@ Styles
import '../index.scss'

export const EngagementProfileInfo = ({ profile, onBackResolve, timeline, churchID }) => {
  //@@ States
  const times =  useProfileTimeline({profile, timeline})

  return (
    <>
      <Row>
        <Col xs={12}>
          <Card>
            <CardHeader>
              <CardTitle className="d-flex align-items-center full-width justify-content-between">
                <Button.Ripple className="btn-icon rounded-circle" color="flat-primary" onClick={onBackResolve}>
                  <ChevronLeft size={16} />
                </Button.Ripple>
                <div>{getProfileFullName(profile)}</div>
                <div className="d-flex" />
              </CardTitle>
            </CardHeader>
          </Card>
        </Col>
        <Col xs={12}>
          <Row>
            <Col lg={4} className="d-none d-lg-block">
              <img
                src={require('@src/assets/images/backgrounds/profile_engagement.svg').default}
                alt="Connected sources"
                className="w-100"
              />
            </Col>

            <Col xs={12} lg={8}>
              <EngagementLevelsCard churchID={churchID} profile={profile} startedAt={profile?.created_at || profile?.join_date || '2000-01-01T00:00:00.000Z'} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col lg={4} className="d-none d-lg-block">
          <img
            src={require('@src/assets/images/backgrounds/timeline.svg').default}
            alt="Connected sources"
            className="w-100"
          />
        </Col>

        <Col xs={12} lg={8}>
          <UserTimeline timeline={times} title="EXTENDED PROFILE TIMELINE" className="profile-extended-list" />
        </Col>
      </Row>
    </>
  )
}
