//@@ Libs
import { useRef } from 'react'
import html2canvas from 'html2canvas'
import { pdf } from '@react-pdf/renderer'
import * as FileSaver from 'file-saver'
import printJS from '@src/forks/print-js/src'
import { Download, Printer } from 'react-feather'

//@@ Hooks
import { useLayout } from '@hooks/useLayout'

//@@ Constants
import { LAYOUT_TYPE } from '@src/constants'
import { DISABLED_PRINT_WARNING } from '@src/views/apps/reports/constants'
import { PdfReport } from '@components/pdf-report/PdfReport'

export const usePrint = ({ titles, descriptions, type, sources, church, printDisabled = false }) => {
  //@@ Ref for export pdf container
  const printRef = useRef(null)

  const { layout } = useLayout()

  //@@ Check is mobile vertical layout
  const isMobile = layout === LAYOUT_TYPE.vertical

  //@@ Prepare pdf to custom template form
  const preparePdf = async ({ isPrint } = { isPrint: false }) => {
    const loader = document.createElement('div')
    loader.style.cssText = `position:fixed;left:0;right:0;top:0;bottom:0;background:#283046;z-index:100;display:flex;align-items:center;justify-content:center`
    loader.innerHTML = `<div class="text-info fs-5">Prepare document</div>`

    const setAttr = () => {
      return new Promise((res) => {
        document.body.appendChild(loader)
        printRef.current.setAttribute('style', 'width:1200px')
        setTimeout(() => {
          res()
        }, 800)
      })
    }

    if (isMobile) {
      await setAttr()
    }

    const canvas = await html2canvas(printRef.current, {
      scale: isMobile ? 2 : 1.5,
      windowWidth: '1920px',
      allowTaint: true,
      useCORS: true
    })
    const data = canvas.toDataURL('image/png')
    printRef.current.setAttribute('style', '')
    loader.remove()

    return pdf(
      <PdfReport
        isMobile={isMobile}
        isPrint={isPrint}
        header={church?.name}
        title={titles[type]}
        description={descriptions[type]?.replace('<b>', '')?.replace('</b>', '')}
        printDataSource={data}
        sources={sources}
      />
    ).toBlob()
  }

  //@@ Print menu items
  const printMenuItems = [
    {
      title: 'Print',
      icon: <Printer className="me-75" size={21} />,
      disabled: printDisabled,
      warning: DISABLED_PRINT_WARNING,
      onClick: async () => {
        preparePdf({ isPrint: true }).then((blob) => {
          printJS({ printable: URL.createObjectURL(blob), showModal: true })
        })
      }
    },
    {
      title: 'Download',
      icon: <Download className="me-75" size={21} />,
      disabled: printDisabled,
      warning: DISABLED_PRINT_WARNING,
      onClick: async () => {
        preparePdf().then((res) => {
          FileSaver.saveAs(res, `${type}-report.pdf`)
        })
      }
    }
  ]

  return { state: { printRef, printMenuItems } }
}
