//@@ Utils
import { useSettingsForm } from '@src/views/apps/reports/hooks/useSettingsForm'
import { useSourcesForm } from '@src/views/apps/reports/hooks/useSourcesForm'
import { useReportMenu } from '@src/views/apps/reports/features/WebInsights/hooks/useReportMenu'
import { getSourceByValue, getSourcesForPdfReport } from '@src/views/apps/reports/utils'

//@@ Context
import { FormContext } from '../../formContext'

export const ReportFormWrapper = ({ type, children }) => {
  //@@ Form settings
  const {
    state: { settingsDictionaries, isLoading, settings: commonSettings },
    methods: {
      handleCharacteristicChange,
      handleCharacteristicAdd,
      handleCharacteristicValueChange,
      handleCharacteristicRemove,
      handleSettingChange
    }
  } = useSettingsForm()

  //@@ Sources settings
  const {
    startSource,
    endSource,
    sources,
    dictionaries: {
      sourcesDictionary,
      startSource: {
        actionDictionary: startSourceActionDictionary,
        propertiesDictionary: startSourcePropertiesDictionary
      },
      endSource: { actionDictionary: endSourceActionDictionary, propertiesDictionary: endSourcePropertiesDictionary }
    },
    handleChangeSource,
    handleChangeAction,
    handleChangeProperties
  } = useSourcesForm()

  //@@ Report menu
  const { menuItems, printRef } = useReportMenu({
    type,
    sources: getSourcesForPdfReport(
      getSourceByValue(sources, startSource.source)?.sourceName,
      getSourceByValue(sources, endSource.source)?.sourceName,
      sources
    )
  })

  return (
    <FormContext.Provider
      value={{
        type,
        printRef,
        menuItems,
        isLoading,
        settings: { ...commonSettings, startSource, endSource },
        settingsDictionaries: {
          ...settingsDictionaries,
          sourcesDictionary,
          startSourceActionDictionary,
          startSourcePropertiesDictionary,
          endSourceActionDictionary,
          endSourcePropertiesDictionary
        },
        onChangeSource: handleChangeSource,
        onChangeAction: handleChangeAction,
        onChangeProperties: handleChangeProperties,
        onSettingChange: handleSettingChange,
        onCharacteristicChange: handleCharacteristicChange,
        onCharacteristicAdd: handleCharacteristicAdd,
        onCharacteristicValueChange: handleCharacteristicValueChange,
        onCharacteristicRemove: handleCharacteristicRemove
      }}
    >
      {children}
    </FormContext.Provider>
  )
}
