//@@ Libs
import { useSelector } from 'react-redux'
import { Bell } from 'react-feather'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useLocation } from 'react-router-dom'
import { Badge, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'

//@@ Store
import { notificationsSelector } from '@src/views/apps/notifications/store/selectors'

//@@ Hooks
import { useNotificationsSubscription } from '@src/views/apps/notifications/hooks/useNotificationsSubscription'

//@@ Components
import { NotificationItem } from '@src/views/apps/notifications/components/NotificationItem'

export const Notifications = () => {
  //@@ Notifications store
  const notifications = useSelector(notificationsSelector)
  // const notifications = []
  //@@ Current url location
  const location = useLocation()

  //@@ Notifications subscription
  useNotificationsSubscription()

  return (
    <UncontrolledDropdown key={location.pathname} tag="li" className="dropdown-notification nav-item me-25">
      <DropdownToggle tag="a" className="nav-link" href="/" onClick={(e) => e.preventDefault()}>
        <Bell size={21} />
        {!!notifications?.length && (
          <Badge pill color="danger" className="badge-up">
            {notifications.length}
          </Badge>
        )}
      </DropdownToggle>
      {!!notifications?.length && (
        <DropdownMenu end tag="ul" className="dropdown-menu-media mt-0">
          <li className="dropdown-menu-header">
            <DropdownItem className="d-flex" tag="div" header>
              <h4 className="notification-title mb-0 me-auto">Notifications</h4>
              <Badge tag="div" color="light-primary" pill>
                {notifications.length} New
              </Badge>
            </DropdownItem>
          </li>

          <PerfectScrollbar
            component="li"
            className="media-list scrollable-container"
            options={{
              wheelPropagation: false
            }}
          >
            {notifications.map((notification) => {
              return <NotificationItem key={notification.id} notification={notification} />
            })}
          </PerfectScrollbar>
        </DropdownMenu>
      )}
    </UncontrolledDropdown>
  )
}
