//** React Imports
import * as React from 'react'
// Libs
import debounce from 'lodash/debounce'
// ** Store Imports
import { useDispatch, useSelector } from 'react-redux'
import { handleLayout, handleLastLayout } from '@store/layout'
// Internals
import { DEFAULT_DEBOUNCE_TIMER } from '@src/constants'

export const useLayout = () => {
  // ** Hooks
  const dispatch = useDispatch()
  const store = useSelector((state) => state.layout)

  const setLayout = (value) => {
    dispatch(handleLayout(value))
  }

  const setLastLayout = (value) => {
    dispatch(handleLastLayout(value))
  }

  const handleLayoutUpdate = React.useCallback(
    debounce(() => {
      // ** If layout is horizontal & screen size is equals to or below 1200
      if (store.layout === 'horizontal' && window.innerWidth <= 1200) {
        setLayout('vertical')
        setLastLayout('horizontal')
      }
      // ** If lastLayout is horizontal & screen size is equals to or above 1200
      if (store.lastLayout === 'horizontal' && window.innerWidth >= 1200) {
        setLayout('horizontal')
        setLastLayout('vertical')
      }
    }, DEFAULT_DEBOUNCE_TIMER),
    [store.layout]
  )

  // ** ComponentDidMount
  React.useEffect(() => {
    handleLayoutUpdate()
  }, [handleLayoutUpdate])

  React.useEffect(() => {
    // ** Window Resize Event
    window.addEventListener('resize', handleLayoutUpdate)
    return () => {
      window.removeEventListener('resize', handleLayoutUpdate)
    }
  }, [handleLayoutUpdate])

  return { layout: store.layout, setLayout, lastLayout: store.lastLayout, setLastLayout }
}
