import {lazy} from 'react'
import {Navigate} from 'react-router-dom'
import TermsAndConditions from "@src/views/pages/misc/TermsAndConditions"

const Error = lazy(() => import('../../views/pages/misc/Error'))
const ComingSoon = lazy(() => import('../../views/pages/misc/ComingSoon'))
const Maintenance = lazy(() => import('../../views/pages/misc/Maintenance'))
const AccountSettings = lazy(() => import('../../views/pages/account-settings'))
const NotAuthorized = lazy(() => import('../../views/pages/misc/NotAuthorized'))

const PagesRoutes = [
    {
        path: '/pages/account-settings',
        element: <AccountSettings/>,
        meta: {
            action: 'read',
            resource: 'profile'
        }
    },
    {
        path: '/misc/coming-soon',
        element: <ComingSoon/>,
        meta: {
            publicRoute: true,
            layout: 'blank'
        }
    },
    {
        path: '/misc/not-authorized',
        element: <NotAuthorized/>,
        meta: {
            publicRoute: true,
            layout: 'blank'
        }
    },
    {
        path: '/misc/maintenance',
        element: <Maintenance/>,
        meta: {
            publicRoute: true,
            layout: 'blank'
        }
    },
    {
        path: '/misc/error',
        element: <Error/>,
        meta: {
            publicRoute: true,
            layout: 'blank'
        }
    },
    {
        path: '/terms-and-conditions',
        element: <TermsAndConditions/>,
        meta: {
            publicRoute: true,
            layout: 'blank'
        }
    }
]

export default PagesRoutes
