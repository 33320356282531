const TIMEZONE = {
  eastern: 'America/New_York',
  central: 'America/Chicago',
  mountain: 'America/Phoenix',
  pacific: 'America/Los_Angeles',
  alaska: 'America/Anchorage',
  hawaii: 'Pacific/Honolulu'
}

const TIMEZONE_OPTIONS = [
  { label: 'Eastern Time', value: TIMEZONE.eastern },
  { label: 'Central Time', value: TIMEZONE.central },
  { label: 'Mountain Time', value: TIMEZONE.mountain },
  { label: 'Pacific Time', value: TIMEZONE.pacific },
  { label: 'Alaska Time', value: TIMEZONE.alaska },
  { label: 'Hawaii Time', value: TIMEZONE.hawaii }
]

const DEFAULT_SHORT_DATE_FORMAT = 'M/DD/YY'
const DEFAULT_FULL_DATE_FORMAT = 'MM/DD/YYYY'

export { TIMEZONE, TIMEZONE_OPTIONS, DEFAULT_SHORT_DATE_FORMAT, DEFAULT_FULL_DATE_FORMAT }
