//@@ Libs
import { useContext } from 'react'
import { Col, Label, Row, Spinner } from 'reactstrap'

//@@ Utils
import { getOptionsFromRawValues } from '@utils'

//@@ Context
import { FormContext } from '@src/views/apps/reports/features/WebInsights/formContext'

//@@ Components
import UILoader from '@components/ui-loader'
import { FormForTheseSelect } from '@src/views/apps/reports/components/FormElements/FormForTheseSelect'
import { Characteristic } from '@src/views/apps/reports/components/Characteristic/Characteristic'
import { FormSourceActions } from '@src/views/apps/reports/components/FormElements/FormSourceActions'
import { FormBetweenRange } from '@src/views/apps/reports/components/FormElements/FormBetweenRange'

export const IndividualSettings = () => {
  //@@ Import form settings context
  const {
    isLoading,
    settings,
    settingsDictionaries,
    onChangeSource,
    onChangeAction,
    onChangeProperties,
    onSettingChange,
    onCharacteristicChange,
    onCharacteristicAdd,
    onCharacteristicValueChange,
    onCharacteristicRemove
  } = useContext(FormContext)

  return (
    <UILoader loader={<Spinner />} blocking={isLoading}>
      <Row className="mb-1">
        <Col xs={12} sm={6}>
          <FormForTheseSelect
            label="To These"
            categoriesOptions={settingsDictionaries.membership}
            categoriesValue={getOptionsFromRawValues({
              rawValue: settings.membership,
              options: settingsDictionaries.membership
            })}
            individual={settings.individual}
            onSettingChange={onSettingChange}
          />
        </Col>

        <Col xs={12} sm={6}>
          <Row className="mb-1">
            <Label xs="4" for="withThese" className="pt-0 align-self-start fs-6">
              With These
            </Label>
            <Characteristic
              characteristics={settings.characteristics}
              settingsDictionaries={settingsDictionaries}
              onCharacteristicChange={onCharacteristicChange}
              onCharacteristicAdd={onCharacteristicAdd}
              onCharacteristicValueChange={onCharacteristicValueChange}
              onCharacteristicRemove={onCharacteristicRemove}
            />
          </Row>
        </Col>
      </Row>

      <FormSourceActions
        firstLabel="That Leads To This"
        lastLabel="With These"
        sourcesDictionary={settingsDictionaries.sourcesDictionary}
        actionDictionary={settingsDictionaries.endSourceActionDictionary}
        propertiesDictionary={settingsDictionaries.endSourcePropertiesDictionary}
        action={settings.endSource.action}
        source={settings.endSource.source}
        properties={settings.endSource.properties}
        onChangeSource={(opt) => onChangeSource(opt, 'end')}
        onChangeAction={(opt) => onChangeAction(opt, 'end')}
        onChangeProperties={(opt) => onChangeProperties(opt, 'end')}
      />

      <Row className="mt-2">
        <Col xs={6}>
          <FormBetweenRange
            fullWidth
            label="That Happened Between"
            settingDates={settings.betweenDates}
            onSettingChange={onSettingChange}
          />
        </Col>
      </Row>
    </UILoader>
  )
}
