//@@ Libs
import { Col, Row, Spinner } from 'reactstrap'

//@@ Utils
import { getOptionFromRawValue, getOptionsFromRawValues, getRawValueFromOption } from '@utils'
import { getSourceRequiredFields } from '@src/views/apps/reports/utils'

//@@ Constants
import { SHOW_FIRST_LAST_OPTIONS } from '@src/views/apps/reports/constants'

//@@ Components
import UILoader from '@components/ui-loader'
import { Characteristic } from '@src/views/apps/reports/components/Characteristic/Characteristic'
import { FormBetweenRange } from '@src/views/apps/reports/components/FormElements/FormBetweenRange'
import { FormForTheseSelect } from '@src/views/apps/reports/components/FormElements/FormForTheseSelect'
import { FormSelect } from '@src/views/apps/reports/components/FormElements/FormSelect'
import { FormSourceActions } from '@src/views/apps/reports/components/FormElements/FormSourceActions'
import { AdaptiveLabel } from '@src/views/apps/reports/components/AdaptiveLabel/AdaptiveLabel'

export const JourneyForm = ({
  isLoading,
  settings,
  settingsDictionaries,
  requiredFields,
  onSettingChange,
  onCharacteristicChange,
  onCharacteristicAdd,
  onCharacteristicValueChange,
  onCharacteristicRemove,
  onChangeSource,
  onChangeAction,
  onChangeProperties
}) => {
  return (
    <>
      <UILoader loader={<Spinner />} blocking={isLoading}>
        <Row className="mb-1">
          <Col xs={12} sm={6}>
            <FormSelect
              isRequired={requiredFields.includes('showMeFirstLast')}
              label="Show Me The"
              options={SHOW_FIRST_LAST_OPTIONS}
              value={getOptionFromRawValue({
                rawValue: settings.showMeFirstLast,
                options: SHOW_FIRST_LAST_OPTIONS
              })}
              onChange={(opt) => onSettingChange({ name: 'showMeFirstLast', value: getRawValueFromOption(opt) })}
            />
          </Col>
        </Row>

        <FormSourceActions
          firstLabel="Starting With This"
          lastLabel="With These"
          requiredFields={getSourceRequiredFields(requiredFields, 'start')}
          sourcesDictionary={settingsDictionaries.sourcesDictionary}
          actionDictionary={settingsDictionaries.startSourceActionDictionary}
          propertiesDictionary={settingsDictionaries.startSourcePropertiesDictionary}
          action={settings.startSource.action}
          source={settings.startSource.source}
          properties={settings.startSource.properties}
          onChangeSource={(opt) => onChangeSource(opt, 'start')}
          onChangeAction={(opt) => onChangeAction(opt, 'start')}
          onChangeProperties={(opt) => onChangeProperties(opt, 'start')}
        />

        <FormSourceActions
          firstLabel="That Leads To This"
          lastLabel="With These"
          requiredFields={getSourceRequiredFields(requiredFields, 'end')}
          sourcesDictionary={settingsDictionaries.sourcesDictionary}
          actionDictionary={settingsDictionaries.endSourceActionDictionary}
          propertiesDictionary={settingsDictionaries.endSourcePropertiesDictionary}
          action={settings.endSource.action}
          source={settings.endSource.source}
          properties={settings.endSource.properties}
          onChangeSource={(opt) => onChangeSource(opt, 'end')}
          onChangeAction={(opt) => onChangeAction(opt, 'end')}
          onChangeProperties={(opt) => onChangeProperties(opt, 'end')}
        />

        <Row className="mb-2">
          <Col xs={12} sm={6}>
            <FormBetweenRange
              fullWidth
              isRequired
              label="That Happened Between"
              settingDates={settings.betweenDates}
              onSettingChange={onSettingChange}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={6}>
            <FormForTheseSelect
              label="To These"
              categoriesOptions={settingsDictionaries.membership}
              categoriesValue={getOptionsFromRawValues({
                rawValue: settings.membership,
                options: settingsDictionaries.membership
              })}
              individual={settings.individual}
              onSettingChange={onSettingChange}
            />
          </Col>

          <Col xs={12} sm={6}>
            <Row className="mb-1">
              <AdaptiveLabel for="withThese" className="align-self-start">
                With These
              </AdaptiveLabel>
              <Characteristic
                characteristics={settings.characteristics}
                settingsDictionaries={settingsDictionaries}
                onCharacteristicChange={onCharacteristicChange}
                onCharacteristicAdd={onCharacteristicAdd}
                onCharacteristicValueChange={onCharacteristicValueChange}
                onCharacteristicRemove={onCharacteristicRemove}
              />
            </Row>
          </Col>
        </Row>
      </UILoader>
    </>
  )
}
