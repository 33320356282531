//@@ Libs
import { useSelector } from 'react-redux'
//@@ Constants
import { SKIN } from 'constants/main'
//@@ Styles
import './index.scss'

export const Logo = ({ height }) => {
  const skin = useSelector((state) => state.layout.skin)
  const logoSource = skin === SKIN.light ? 'SingleSource_logo_light.png' : 'SingleSource_logo_dark.png'
  return (
    <span className="main-logo">
      <img height={height} src={require(`@src/assets/images/logo/${logoSource}`).default} alt="logo" />
    </span>
  )
}
