// ** React Imports
import { Navigate } from 'react-router-dom'
import { useContext, Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// ** Context Imports
import { AbilityContext } from '@src/utility/context/Can'
import { isUserAuthed } from '../../../redux/authentication'
import { CHURCH_ROLE_NAME, ADMIN_ROLE_NAME } from '../../../configs/environment'

const PrivateRoute = ({ children, route }) => {
  // ** Hooks & Vars
  const ability = useContext(AbilityContext)
  const user = useSelector((state) => state.auth.userData)
  const isAuthed = useSelector((state) => state.auth.isAuthed)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(isUserAuthed())
  }, [route])

  if (route) {
    let action = null
    let resource = null
    let restrictedRoute = false

    if (route.meta) {
      action = route.meta.action
      resource = route.meta.resource
      restrictedRoute = route.meta.restricted
    }

    if (!isAuthed) {
      return <Navigate to="/login" />
    }
    if (user.hasOwnProperty('id')) {
      if (isAuthed && restrictedRoute) {
        return <Navigate to="/" />
      }
      if (isAuthed && restrictedRoute && user.role === 'client') {
        return <Navigate to="/access-control" />
      }
      if (!ability.can(action || 'read', resource)) {
        return <Navigate to="/misc/not-authorized" replace />
      }
    } else {
      return <>Loading</>
    }
  }

  return <Suspense fallback={null}>{children}</Suspense>
}

export default PrivateRoute
