//@@ Libs
import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'

//@@ Hooks
import { useProfileIdParam } from '@src/views/apps/profiles/hooks/useProfileIdParam'

//@@ Components
import { ProfileInfo } from '@src/views/apps/profiles/list/components/ProfileInfo'
import UILoader from '@src/@core/components/ui-loader/index'

//@@ Styles
import '@styles/react/apps/app-users.scss'

const ProfilePage = () => {
  //@@ Navigate hook
  const navigate = useNavigate()
  //@@ Selectors
  const churchID = useSelector((state) => state.auth.userData?.churchID)
  //@@ Profile query hook
  const { profile, profilesQuery, isFetching, profileInfoQuery } = useProfileIdParam()

  if (profilesQuery.isLoading || profileInfoQuery.isLoading || isFetching) {
    return (
      <UILoader
        loader={
          <div>
            <Spinner />
          </div>
        }
        blocking={profilesQuery.isLoading || isFetching}
        style={{ minHeight: '30rem' }}
        className="overflow-auto"
      ></UILoader>
    )
  }
  if (profilesQuery.isError || profileInfoQuery.isError) {
    return <Navigate to="/profiles" />
  } else {
    return <ProfileInfo profile={profile} onBackResolve={() => navigate('/profiles')} churchID={churchID} />
  }
}

export default ProfilePage
