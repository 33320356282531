//@@ Libs
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'

export const ProfileBreadCrumbs = ({ isEngagement }) => {
  //@@ Profile id in query params
  const { profileId } = useParams()

  return (
    <Card>
      <CardBody className="d-flex align-items-center">
        <Link className="d-flex align-items-center cursor-pointer" to="/profiles">
          Profiles
        </Link>
        <div className="mx-75">/</div>
        <Link className={!isEngagement ? 'text-primary' : 'cursor-pointer'} to={`/profiles/${profileId}`}>
          Profile Info
        </Link>
        {isEngagement && (
          <>
            <div className="mx-75">/</div>
            <div className="text-primary">Profile Engagement</div>
          </>
        )}
      </CardBody>
    </Card>
  )
}
