//@@ Libs
import moment from 'moment'
import sum from 'lodash/sum'

//@@ Constants
import { CHART_COLORS } from '@src/views/apps/reports/constants'
import { DEFAULT_FULL_DATE_FORMAT } from 'constants/datetime'

//@@ Participation chart options
export const getParticipationChartOptions = ({ isMobile, type, labels }) => {
  return {
    chart: {
      parentHeightOffset: 0,
      toolbar: {
        show: false
      },
      stacked: true
    },
    stroke: {
      show: false,
      curve: 'smooth'
    },
    colors: Object.values(CHART_COLORS),
    plotOptions: {
      bar:
        type === 'count'
          ? {
              columnWidth: '30%',
              borderRadius: [5],
              horizontal: isMobile
            }
          : {
              horizontal: isMobile
            }
    },
    legend: {
      show: true,
      position: 'bottom'
    },
    labels: labels ?? [],
    dataLabels: {
      enabled: type !== 'percent',
      formatter: (v) => {
        return v.toFixed(2)
      }
    },
    xaxis: {
      categories: labels?.map((v) => moment(`${v}`).format(DEFAULT_FULL_DATE_FORMAT)) ?? [],
      labels: {
        style: {
          fontSize: '14px'
        }
      }
    },
    yaxis: {
      labels: {
        formatter: (v) => (type === 'percent' ? `${v.toFixed(2)}%` : v),
        style: {
          fontSize: '14px'
        }
      },
      ...(type === 'percent'
        ? {
            max: (max) => {
              if (max > 100) {
                return 100
              }
              return max + 10 >= 100 ? 100 :  max + (max * .01)
            }
          }
        : {})
    },
    fill: {
      opacity: 1,
      type: 'solid'
    },
    tooltip: {
      enabled: true,
      shared: true,
      y: {
        formatter: (v, { series, dataPointIndex }) => {
          const total = sum(series.map((seriesValue) => seriesValue[dataPointIndex]))
          return total ? `${Math.round((v / total) * 100)}%` : '0%'
        }
      },
      intersect: false
    }
  }
}
