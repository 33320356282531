//@@ Libs
import { Card, CardBody } from 'reactstrap'
import Chart from 'react-apexcharts'
import { useContext } from 'react'

//@@ Utils
import { FormContext } from '@src/views/apps/reports/features/WebInsights/formContext'

export const FunnelChart = () => {
  //@@ Import form settings context
  const { printRef } = useContext(FormContext)

  //@@ Chart Options
  const options = {
    chart: {
      parentHeightOffset: 0,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '30%',
        borderRadius: [10]
      }
    },
    grid: {
      xaxis: {
        lines: {
          show: false
        }
      }
    },
    colors: '#836AF9',
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: ['7/12', '8/12', '9/12', '10/12', '11/12', '12/12'],
      labels: {
        style: {
          fontSize: '14px'
        }
      }
    },
    yaxis: {
      labels: {
        formatter: (v) => `$ ${v}`,
        style: {
          fontSize: '14px'
        }
      }
    }
  }

  //@@ Chart series
  const series = [
    {
      data: [275, 90, 190, 205, 125, 85]
    }
  ]

  return (
    <Card innerRef={printRef}>
      <CardBody>
        <Card>
          <CardBody>
            <div style={{ height: '400px' }}>
              <Chart options={options} series={series} type="bar" height={438} />
            </div>
          </CardBody>
        </Card>
      </CardBody>
    </Card>
  )
}
