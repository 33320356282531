import { parseNumber } from '@src/utility/numUtils'
//@@ Libs
import sum from 'lodash/sum'
import moment from 'moment'

//@@ Constants
import { CHART_COLORS } from '@src/views/apps/reports/constants'
import { DEFAULT_FULL_DATE_FORMAT } from 'constants/datetime'

//@@ Chart colors
const { blueColor, blueLightColor, greyLightColor, darkBlueColor, ...restColors } = CHART_COLORS

//@@ Common chart options
const getCommonChartOptions = ({ labels }) => {
  return {
    chart: {
      parentHeightOffset: 0,
      toolbar: {
        show: false
      },
      stacked: true
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: false,
      curve: 'smooth'
    },
    legend: {
      position: 'top',
      horizontalAlign: 'start'
    },
    colors: [blueColor, blueLightColor, greyLightColor, darkBlueColor, ...Object.values(restColors)],
    labels: labels ?? [],
    fill: {
      opacity: 1,
      type: 'solid'
    },
    tooltip: {
      enabled: true,
      shared: true,
      y: {
        formatter: (v, { series, dataPointIndex }) => {
          const total = sum(series.map((seriesValue) => seriesValue[dataPointIndex]))
          return total ? `${Math.round((v / total) * 100)}%` : '0%'
        }
      },
      intersect: false
    }
  }
}

//@@ Total chart options
export const getTotalChartOptions = ({ labels, category }) => {
  return {
    ...getCommonChartOptions({ labels }),
    grid: {
      xaxis: {
        lines: {
          show: true
        }
      }
    },
    xaxis: {
      categories: labels?.map((v) => moment(`${v}`).format(DEFAULT_FULL_DATE_FORMAT)) ?? [],
      labels: {
        style: {
          fontSize: '14px'
        }
      }
    },
    yaxis: {
      labels: {
        formatter: (v) => (category === 'Dollars Collected During' ? `$ ${parseNumber(v)}` : parseNumber(v)),
        style: {
          fontSize: '14px'
        }
      }
    }
  }
}

//@@ Average chart options
export const getAverageChartOptions = ({ labels, isMobile, category }) => {
  return {
    ...getCommonChartOptions({ labels }),
    plotOptions: {
      bar: {
        columnWidth: '30%',
        borderRadius: [5],
        horizontal: isMobile
      }
    },
    xaxis: {
      categories: labels?.map((v) => moment(`${v}`).format(DEFAULT_FULL_DATE_FORMAT)) ?? [],
      labels: {
        style: {
          fontSize: '14px'
        }
      }
    },
    yaxis: {
      labels: {
        formatter: (v) => {
          return isMobile ? v : category === 'Dollars Collected During' ? `$ ${v}` : v.toFixed()
        },
        style: {
          fontSize: '14px'
        }
      }
    }
  }
}
