//@@ Libs
import { useEffect } from 'react'
import classnames from 'classnames'
import { ArrowUp } from 'react-feather'
import debounce from 'lodash/debounce'
import { Navbar, Button } from 'reactstrap'

//@@ Configs
import themeConfig from '@configs/themeConfig'

//@@ Components
import Customizer from '@components/customizer'
import ScrollToTop from '@components/scrolltop'
import { HeaderNavbar } from '@layouts/components/header-navbar'
import { MainMenuVertical } from '@layouts/components/main-menu/MainMenuVertical'

//@@ Hooks
import { useLayoutState } from '@layouts/hooks/useLayoutState'

//@@ Constants
import { DEFAULT_DEBOUNCE_TIMER } from '@src/constants'

// ** Styles
import '@styles/base/core/menu/menu-types/vertical-menu.scss'
import '@styles/base/core/menu/menu-types/vertical-overlay-menu.scss'

const VerticalLayout = (props) => {
  //@@ Props
  const { navbar, children, menuData } = props

  //@@ Hooks
  const { state, methods } = useLayoutState()

  //@@ Update Window Width
  const handleWindowWidth = debounce(() => {
    methods.setWindowWidth(window.innerWidth)
  }, DEFAULT_DEBOUNCE_TIMER)

  //@@ This function will detect the Route Change and will hide the menu on menu item click
  useEffect(() => {
    if (state.menuVisibility && state.windowWidth < 1200) {
      methods.setMenuVisibility(false)
    }
  }, [location])

  //@@ Sets Window Size & Layout Props
  useEffect(() => {
    window.addEventListener('resize', handleWindowWidth)
    return () => {
      window.removeEventListener('resize', handleWindowWidth)
    }
  }, [state.windowWidth])

  useEffect(() => {
    methods.setIsMounted(true)
    return () => {
      methods.setIsMounted(false)
    }
  }, [])

  //@@ Vars
  const footerClasses = {
    static: 'footer-static',
    sticky: 'footer-fixed',
    hidden: 'footer-hidden'
  }

  const navbarWrapperClasses = {
    floating: 'navbar-floating',
    sticky: 'navbar-sticky',
    static: 'navbar-static',
    hidden: 'navbar-hidden'
  }

  const navbarClasses = {
    floating: state.contentWidth === 'boxed' ? 'floating-nav container-xxl' : 'floating-nav',
    sticky: 'fixed-top',
    static: 'navbar-static-top',
    hidden: 'd-none'
  }

  const bgColorCondition = state.navbarColor !== '' && state.navbarColor !== 'light' && state.navbarColor !== 'white'

  if (!state.isMounted) {
    return null
  }

  return (
    <div
      className={classnames(
        `wrapper-vertical vertical-layout ${navbarWrapperClasses[state.navbarType] || 'navbar-floating'} ${
          footerClasses[state.footerType] || 'footer-static'
        }`,
        {
          // Modern Menu
          'vertical-menu-modern': state.windowWidth >= 1200,
          'menu-collapsed': state.menuCollapsed && state.windowWidth >= 1200,
          'menu-expanded': !state.menuCollapsed && state.windowWidth > 1200,

          // Overlay Menu
          'vertical-overlay-menu': state.windowWidth < 1200,
          'menu-hide': !state.menuVisibility && state.windowWidth < 1200,
          'menu-open': state.menuVisibility && state.windowWidth < 1200
        }
      )}
      {...(state.isHidden ? { 'data-col': '1-column' } : {})}
    >
      <Navbar
        expand="lg"
        container={false}
        light={state.skin !== 'dark'}
        dark={state.skin === 'dark' || bgColorCondition}
        color={bgColorCondition ? state.navbarColor : undefined}
        className={classnames(
          `header-navbar web-kit-sticky navbar align-items-center ${
            navbarClasses[state.navbarType] || 'floating-nav'
          } navbar-shadow`
        )}
      >
        <div className="navbar-container d-flex content">
          {navbar ? (
            navbar({ skin: state.skin, setSkin: methods.setSkin, setMenuVisibility: methods.setMenuVisibility })
          ) : (
            <HeaderNavbar />
          )}
        </div>
      </Navbar>
      {children}

      {/* Vertical Nav Menu Overlay */}
      <div
        className={classnames('sidenav-overlay', {
          show: state.menuVisibility
        })}
        onClick={() => methods.setMenuVisibility(false)}
      />
      {/* Vertical Nav Menu Overlay */}

      {themeConfig.layout.customizer === true ? (
        <Customizer
          skin={state.skin}
          isRtl={state.isRtl}
          layout={state.layout}
          setSkin={methods.setSkin}
          setIsRtl={methods.setIsRtl}
          isHidden={state.isHidden}
          setLayout={methods.setLayout}
          footerType={state.footerType}
          navbarType={state.navbarType}
          transition={state.transition}
          setIsHidden={methods.setIsHidden}
          themeConfig={themeConfig}
          navbarColor={state.navbarColor}
          contentWidth={state.contentWidth}
          setTransition={methods.setTransition}
          setFooterType={methods.setFooterType}
          setNavbarType={methods.setNavbarType}
          setLastLayout={methods.setLastLayout}
          menuCollapsed={state.menuCollapsed}
          setNavbarColor={methods.setNavbarColor}
          setContentWidth={methods.setContentWidth}
          setMenuCollapsed={methods.setMenuCollapsed}
        />
      ) : null}

      <MainMenuVertical isHidden={state.isHidden} menuData={menuData} />

      {themeConfig.layout.scrollTop === true ? (
        <div className="scroll-to-top">
          <ScrollToTop showOffset={300} className="scroll-top d-block">
            <Button className="btn-icon" color="primary">
              <ArrowUp size={14} />
            </Button>
          </ScrollToTop>
        </div>
      ) : null}
    </div>
  )
}

export default VerticalLayout
