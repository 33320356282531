// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import staff from '@src/views/admin/apps/staff/store'
import churchesTable from '@src/views/apps/churches/store/data-tables'
import churches from '@src/views/apps/churches/store'
import churchUsers from '@src/views/apps/churchUsers/store'
import pageInfo from '@src/views/apps/profiles/store'
import connectedSources from '@src/views/apps/connected-sources/store/index'
import datastore from './datastore'
import csv from './csv'
import users from './usersSlice/usersSlice'
import { goalsSlice as goals } from '@src/views/apps/goals/store'
import { singleSourceApiSlice } from '@src/redux/singleSourceApiSlice'
import notifications from '@src/views/apps/notifications/store'
import profileResolution from '@src/views/apps/profile-merge/store'

const rootReducer = {
  auth,
  connectedSources,
  staff,
  navbar,
  layout,
  churchesTable,
  churches,
  churchUsers,
  pageInfo,
  datastore,
  csv,
  goals,
  [singleSourceApiSlice.reducerPath]: singleSourceApiSlice.reducer,
  notifications,
  profileResolution,
  users
}
export default rootReducer
