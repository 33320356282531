// ** Reactstrap Imports
import { CardTitle, CardText, Card, CardBody, Row } from 'reactstrap'

import { Logo } from '@src/@core/components/logo/Logo'

const TermsAndConditionsPage = () => {
  return (
    <Card className="mt-0 mb-0">
      <CardTitle tag="h4">
        <div className="text-center mt-2">
          <div className="terms-logo">
            <Logo height="65px" />
          </div>
        </div>
        <div className="text-center mt-3">Data Privacy Policy</div>
      </CardTitle>
      <CardBody>
        <CardText>
          We at Know Your Cross, LLC. (“we,” “us,” or “our”) created this SingleSource Data Privacy Policy (“Privacy
          Policy”) because we know that our Subscribers care about how information they provide to us is used and
          shared. This Privacy Policy is written to inform users how SingleSource collects and uses Subscriber
          information related to the SingleSource platform.
          <br />
          <br />
          By subscribing to SingleSource, users are agreeing to the terms of this Data Privacy Policy. This Privacy
          Policy does not apply to any products, services, websites, or content offered by third parties or that have
          their own privacy policies.
          <br />
          <br />
          <b>Authorized Purpose</b>
          <br></br>
          Subscribers to the SingleSource Software will voluntarily pass on and disclose certain of its Confidential
          Information to SingleSource representatives and to the SingleSource platform. “Confidential Information” shall
          include all data, passwords and credentials, software access, marketing plans, business plans, financial
          information, and other information disclosed or submitted, orally, in writing, or by any other media, by
          Subscriber to SingleSource.
          <br />
          <br />
          Confidential information shall be used for Authorized Purposes only, which includes supporting our
          relationship with the Subscriber and providing services under any agreement we have with Subscriber.
          SingleSource shall disclose Confidential Information only to its administrators, officers, and in furtherance
          of an Authorized Purpose. With the exception of Authorized Purposes, SingleSource will not disclose, publish
          or otherwise reveal any Confidential Information received from the Subscriber to any other party whatsoever
          without the specific prior written authorization of Subscriber.
          <br />
          <br />
          <u>
            <b>Information We Collect</b>
          </u>
          <br />
          We collect personal information in the course of providing the Services to you. The information we collect
          falls into three main categories: (1) information you choose to provide to us, (2) information we collect from
          you automatically, and (3) information we collect from other sources.
          <br />
          <br />
          <b>1. Information You Choose to Provide to Us</b> <br />
          <br />
          <b>Subscriber Account Registration</b> <br />
          We collect your organization name and other contact information when you create an account. We also collect
          information relating to the actions that you perform to log into your account to enable easy and secure access
          to your information.
          <br />
          <br />
          <b>Customer Information</b> <br />
          We collect name, contact, and additional information from some of our Subscriber’s third-party data sources.
          This information is used to populate customer “profiles.” Third-party information is managed and protected by
          the terms, conditions, and policies of the third-party data source. All data that results from the integration
          of third-party sources into SingleSource shall be considered proprietary and is covered by this Privacy
          Policy.
          <br />
          <br />
          <b>2. Information We Collect From You Automatically</b>
          <br />
          <br />
          <b>Cookies and First Party Tracking</b> <br />
          We use first party cookies for the benefit of your organization. “Cookies” are small pieces of information
          that a website sends to a computer’s hard drive while a website is viewed. We use cookies solely to give
          information back to you about visitors to your website. We do not sell any information to third parties.
          Information collection is only for Authorized Purposes, as defined above.
          <br />
          <br />
          We use proprietary code to monitor how your customers interact with your website. This may include click
          activity, or information they type into your online forms. This may also include information about their
          device or browser, operating system, Internet Protocol (IP) address (a number that is automatically assigned
          to a computer when the Internet is used), domain name, referring website, and/or a date/time stamp for
          visitors. <br />
          <br />
          <b>3. Information We Collect From Other Sources</b> <br />
          <br />
          Your customers may choose to connect to your organizations’ digital location using a third-party identity
          provider account. SingleSource may collect, store, and use, in accordance with this Privacy Policy, any and
          all information that your organization has authorized the third party to provide to SingleSource. Such
          information may include your first and last name, unique identifier, e-mail address and picture. In the case
          where one source of data is a Giving platform, SingleSource will only collect giving dates and amounts.
          SingleSource will not obtain or process any billing information, like credit card or banking information. We
          will not sell any giving information to third parties. Information collection will only be used for Authorized
          Purposes.
          <br />
          <br />
          <b>Data Ownership:</b> <br />
          SingleSource shall return all data received from Subscriber within ten (10) days of such request from
          Subscriber. At Subscriber’s option, any Confidential Information may be destroyed in lieu of return.
          <br />
          <br />
          <b>Data Protection:</b> <br />
          We value your trust in providing us access to your Confidential Information. Accordingly, we take appropriate
          measures to protect all Confidential Information associated with the SingleSource platform, including using
          encryption, firewalls, and other security measures. We also regularly review and update our security measures
          to protect user data. Despite our best efforts, no method of transmission over the internet or method of
          electronic storage is 100% secure and reliable, as a result, we cannot guarantee absolute security of data.{' '}
          <br />
          <br />
          <b>Data Sharing Practices:</b> <br />
          We do not share Confidential Information with third-party vendors or other organizations. <br />
          <br />
          <b>Opt-Out Options:</b> <br />
          Your customers have the option to opt-out of data collection at any time. That action should happen at the
          source of the data, not through SingleSource. <br />
          <br />
          <b>Data Retention Policies:</b> <br />
          We retain user data for the length of the SingleSource Contract. After this time period, user data will be
          deleted within 10 days of the contract end date. <br />
          <br />
          <b>Access to Data:</b> <br />
          Subscribers have access to their data based on the terms of the SingleSource Contract. <br />
          <br />
          <b>Transparency:</b> <br />
          We are transparent about our data collection and usage practices. If you have any questions or concerns,
          please contact us at administrator@singlesource.tech. <br />
          <br />
          <b>Updates to this Policy:</b> <br />
          We regularly review and update this data privacy policy to ensure that it remains current and effective. The
          latest version of this policy is always available on our website. <br />
          <br />
          <i>Last Revised and Effective Date: February 8, 2023</i>
        </CardText>
      </CardBody>
    </Card>
  )
}

const TermsAndConditions = () => {
  return <TermsAndConditionsPage />
}

export default TermsAndConditions