//@@ Libs
import { Fragment } from 'react'
import classnames from 'classnames'
import { Button } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

//@@ Store
import { removeNotification } from '@src/views/apps/notifications/store'

//@@ Constants
import { AVATAR_CONTENT_BY_STATUS, NOTIFICATION_TYPE } from '@src/views/apps/notifications/constants'
import { GOAL_OPTIONS } from '@src/views/dashboard/ecommerce/constants'

//@@ Components
import Avatar from '@components/avatar'

export const NotificationItem = ({ notification }) => {
  //@@ Store vars
  const dispatch = useDispatch()

  //@@ Get notification label by type
  const getLabel = () => {
    switch (notification.notificationType) {
      case NOTIFICATION_TYPE.PROFILE_RESOLUTION: {
        return 'There is a profile that is a potential duplicate.'
      }
      case NOTIFICATION_TYPE.GOAL_ACHIEVED: {
        return `Congratulations! You've reached your ${
          GOAL_OPTIONS.find(({ value }) => value === notification?.label)?.label ?? ''
        } Goal!`
      }
      default: {
        return ''
      }
    }
  }

  //@@ Get notification link by type
  const getLink = () => {
    switch (notification.notificationType) {
      case NOTIFICATION_TYPE.PROFILE_RESOLUTION: {
        return `/profile-merge/${notification.objectID}`
      }
      case NOTIFICATION_TYPE.GOAL_ACHIEVED: {
        return `/dashboard/ecommerce/${notification.objectID}`
      }
      default: {
        return '#'
      }
    }
  }

  //@@ Get notification wrapper by type
  const ComponentWrapper = notification.notificationType === NOTIFICATION_TYPE.PROFILE_RESOLUTION ? 'div' : Link

  //@@ Remove notification func
  const goalNotificationRemove = () => {
    const { id } = notification
    dispatch(removeNotification({ id }))
  }

  return (
    <ComponentWrapper
      className="d-flex"
      to={getLink()}
      onClick={
        notification.notificationType === NOTIFICATION_TYPE.PROFILE_RESOLUTION ? undefined : goalNotificationRemove
      }
    >
      <div
        className={classnames('list-item d-flex', {
          'align-items-center': true
        })}
      >
        <Fragment>
          <div className="me-1">
            <Avatar {...AVATAR_CONTENT_BY_STATUS?.[notification.notificationType]} />
          </div>
          <div className="list-item-body flex-grow-1">
            <span className="media-heading">{getLabel()}</span>
            {notification.notificationType === NOTIFICATION_TYPE.PROFILE_RESOLUTION && (
              <Button tag={Link} to={getLink()} color="warning" className="btn-icon">
                Resolve Now
              </Button>
            )}
            {/*<small className="notification-text">{item.subtitle}</small>*/}
          </div>
        </Fragment>
      </div>
    </ComponentWrapper>
  )
}
