import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit'
import { DataStore, Storage } from 'aws-amplify'

export const FILE_UPLOAD_STATUS = {
  PENDING: 'pending',
  FINISHED: 'finished',
  STARED: 'started',
  FAILED: 'failed'
}

export const clearDb = createAsyncThunk('datastore/clear', async () => {
  await DataStore.clear()
})

export const startDb = createAsyncThunk('datastore/start', async () => {
  await DataStore.start()
})

export const uploadFile = createAsyncThunk('datastore/uploadFile', async ({ file, options }) => {
  const response = await Storage.put(file.name, file, options)
  return response
})

export const resetFileID = createAction('datastore/resetFileID')

const dataStoreSlice = createSlice({
  name: 'datastore',
  initialState: {
    fileUpload: null,
    fileID: null
  },
  reducers: {
    resetDataStoreState: (state) => {
      state.fileID = null
      state.fileUpload = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadFile.pending, (state) => {
        state.fileID = null
        state.fileUpload = FILE_UPLOAD_STATUS.PENDING
      })
      .addCase(uploadFile.rejected, (state) => {
        state.fileID = null
        state.fileUpload = FILE_UPLOAD_STATUS.FAILED
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.fileID = action.payload?.key
        state.fileUpload = FILE_UPLOAD_STATUS.FINISHED
      })
      .addCase(resetFileID, (state) => {
        state.fileID = null
      })
  }
})
export const { resetDataStoreState } = dataStoreSlice.actions
export default dataStoreSlice.reducer
