//@@ Libs
import React from 'react'
import classNames from 'classnames'

//@@ Constants
import { contentStyles, imageSizes } from './constants'

//@@ Components
import Avatar from '@components/avatar'

//@@ Styles
import './ChurchAvatar.scss'

export const ChurchAvatar = ({avatarColor = 'transparent', logoUrl, size = 'lg'}) => {
  return (
    <Avatar
      color={avatarColor || (!logoUrl && 'light-primary')}
      className="rounded"
      icon={
        <img
          alt='avatar'
          className={classNames('h-100 w-100', {paddedImg: size === 'lg'})}
          src={logoUrl || require('@src/assets/images/logo/default-church-logo.png').default}
        />
      }
      contentStyles={contentStyles}
      style={imageSizes[size]}
    />
  )
}
