//@@ Libs
import startCase from 'lodash/startCase'

//@@ Exclude start & end journey source from response
export const filterEventsFromJourneyResponse = ({ eventSource, eventCategory, settings }) => {
  const startSourceIncludesEvent =
    startCase(eventSource) === startCase(settings.startSource.source) &&
    startCase(eventCategory) === startCase(settings.startSource.action)
  const endSourceIncludesEvent =
    startCase(eventSource) === startCase(settings.endSource.source) &&
    startCase(eventCategory) === startCase(settings.endSource.action)

  return !(startSourceIncludesEvent || endSourceIncludesEvent)
  return true
}
