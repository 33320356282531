//@@ Libs
import { Card, CardHeader, CardTitle, CardBody } from 'reactstrap'
import classNames from 'classnames'

//@@ Components
import Timeline from '@components/timeline'

const UserTimeline = ({ timeline, title = 'PROFILE TIMELINE', className = '' }) => {
  return (
    <Card className={classNames(className, 'mb-0')}>
      <CardHeader>
        <CardTitle tag="h4">{title}</CardTitle>
      </CardHeader>
      <CardBody className="pt-1">
        <Timeline data={timeline} className="ms-50" />
      </CardBody>
    </Card>
  )
}

export default UserTimeline
