//@@ Libs
import { Card, CardBody, Table } from 'reactstrap'
import { useContext } from 'react'

//@@ Utils
import { FormContext } from '@src/views/apps/reports/features/WebInsights/formContext'

//@@ Mock results data
const mockData = [
  {
    email: 'erich@example.com',
    last_name: 'Rich',
    first_name: 'Emily-Rose'
  },
  {
    email: 'kbloom@example.com',
    last_name: 'Bloom',
    first_name: 'Kamila'
  },
  {
    email: 'cellis@example.com',
    last_name: 'Ellis',
    first_name: 'Chyna'
  },
  {
    email: 'ayang@example.com',
    last_name: 'Yang',
    first_name: 'Atlas'
  },
  {
    email: 'rebecca@example.com',
    last_name: 'Olive',
    first_name: 'Rebecca'
  },
  {
    email: 'maryann@example.com',
    last_name: 'Hose',
    first_name: 'Mary Ann'
  },
  {
    email: 'lparkinson@example.com',
    last_name: 'Parkinson',
    first_name: 'Lily'
  },
  {
    email: 'gperson@example.com',
    last_name: 'Person',
    first_name: 'Giorgia'
  },
  {
    email: 'csalinas@example.com',
    last_name: 'Salinas',
    first_name: 'Callen'
  }
]
export const CohortResults = () => {
  //@@ Import form settings context
  const { printRef } = useContext(FormContext)

  return (
    <Card innerRef={printRef}>
      <CardBody>
        <Table responsive>
          <thead>
            <tr>
              <th>First name</th>
              <th>Last name</th>
              <th>E-mail</th>
            </tr>
          </thead>
          <tbody>
            {mockData.map(({ first_name, last_name, email }, i) => {
              return (
                <tr key={i}>
                  <td>{first_name}</td>
                  <td>{last_name}</td>
                  <td>{email}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  )
}
