//@@ Libs
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {DataStore, Auth, SortDirection} from 'aws-amplify'

//@@ Store
import {ChurchModel, UserProfileModel} from '@src/models'
import {authUserSelector} from '@store/selectors'
import {addUsersAC} from '@src/redux/usersSlice/usersSlice'

//@@ Constants
import {api} from '@src/services/api'
import {getJWTToken} from "@src/utility/auth/tokens"

export const getChurchUsers = createAsyncThunk(
    'appChurches/getUsersForChurch',
    async ({churchID, sortColumn, sort, page, perPage, q}, {dispatch}) => {
        const totalUsers = await DataStore.query(UserProfileModel, (u) => u.churchID('eq', churchID))

        const data = await DataStore.query(
            UserProfileModel,
            (u) => {
                return u.churchID('eq', churchID).or((m) => {
                    return m.firstName('contains', q).lastName('contains', q).email('contains', q).phone('contains', q)
                })
            },
            {
                sort: (model) => {
                    const awsSortingDir = sort === 'desc' ? SortDirection.DESCENDING : SortDirection.ASCENDING
                    return model[sortColumn](awsSortingDir)
                },
                page,
                limit: perPage
            }
        )
        dispatch(addUsersAC(data))
        return {data, total: totalUsers.length}
    }
)

export const addChurch = createAsyncThunk('appChurches/create', async (data) => {
    await DataStore.save(new ChurchModel(data))
    return data
})

// https://github.com/aws-amplify/amplify-js/issues/1067
export const checkIsEmailRegistered = createAsyncThunk('appChurches/checkIsEmailRegistered', async ({email}) => {
    let isEmailRegistered = false
    try {
        await Auth.confirmSignUp(email, '000', {forceAliasCreation: false})
    } catch (e) {
        switch (e?.code) {
            case 'NotAuthorizedException':
            case 'AliasExistsException':
            case 'CodeMismatchException':
            case 'ExpiredCodeException':
                isEmailRegistered = true
                break
            default:
                isEmailRegistered = false
        }
    }
    return isEmailRegistered
})

// church data is optional
export const inviteChurchUser = createAsyncThunk(
    'appChurches/inviteUser',
    async ({churchID, userEmail, churchName}, {getState, rejectWithValue}) => {
        const state = getState()
        const currentUser = authUserSelector(state)
        const token = await getJWTToken()

        try {
            const response = await api.post('/send-email-invite', {
                    churchID,
                    userEmail,
                    inviterName: `${currentUser.firstName} ${currentUser.lastName}`,
                    churchName
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            return response
        } catch (e) {
            return rejectWithValue(e)
        }
    }
)

const churchesSlice = createSlice({
    name: 'churchUsers',
    initialState: {
        data: [],
        total: 0
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getChurchUsers.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.total = action.payload.total
        })
    }
})

export default churchesSlice.reducer
