//@@ Libs
import moment from 'moment'

//@@ Constants
import { CHART_COLORS } from '@src/views/apps/reports/constants'
import { DEFAULT_FULL_DATE_FORMAT } from 'constants/datetime'

//@@ Common chart options
const getCommonChartOptions = ({ isMobile }) => {
  return {
    chart: {
      parentHeightOffset: 0,
      toolbar: {
        show: false
      }
    },
    grid: {
      xaxis: {
        lines: {
          show: false
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    plotOptions: {
      bar: {
        columnWidth: '30%',
        borderRadius: [5],
        horizontal: isMobile
      }
    }
  }
}

//@@ Between chart options
export const getBetweenChartOptions = ({ isMobile, labels }) => {
  return {
    ...getCommonChartOptions({ isMobile }),
    labels,
    colors: CHART_COLORS.series2,
    xaxis: {
      categories: labels.map((v) => moment(`${v}`).format(DEFAULT_FULL_DATE_FORMAT)),
      labels: {
        style: {
          fontSize: '14px'
        },
        formatter: isMobile
          ? (v) => {
              return `${v.toFixed(2)}%`
            }
          : (v) => v,
        rotateAlways: isMobile,
        ...(isMobile ? { minHeight: 60 } : {})
      }
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '14px'
        },
        formatter: (v) => {
          return isMobile ? v : `${v.toFixed()}%`
        }
      },
      max: (max) => {
        if (max > 100) {
          return 100
        }
        return max + 10 >= 100 ? 100 : max + 10
      }
    },
    tooltip: {
      x: {
        formatter: (v) => {
          return v
        }
      },
      y: {
        formatter: (v) => {
          return `${v.toFixed()}%`
        }
      }
    }
  }
}

//@@ Compare chart options
export const getCompareChartOptions = ({ isMobile, labels }) => {
  return {
    ...getCommonChartOptions({ isMobile }),
    colors: CHART_COLORS.series3,
    labels,
    xaxis: {
      categories: labels.map((v) => moment(`${v}`).format(DEFAULT_FULL_DATE_FORMAT)),
      labels: {
        style: {
          fontSize: '14px'
        },
        formatter: (v) => {
          return isMobile ? `${v.toFixed()}%` : v
        },
        rotateAlways: isMobile,
        ...(isMobile ? { minHeight: 60 } : {})
      }
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '14px'
        },
        formatter: (v) => {
          return isMobile ? v : `${v.toFixed()}%`
        }
      },
      max: (max) => {
        if (max > 100) {
          return 100
        }
        return max + 10 >= 100 ? 100 : max + 10
      }
    },
    tooltip: {
      x: {
        formatter: (v) => {
          return v
        }
      },
      y: {
        formatter: (v) => {
          return `${v.toFixed()}%`
        }
      }
    }
  }
}
