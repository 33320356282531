//@@ Libs
import { Card, CardBody, CardHeader, CardTitle, Spinner } from 'reactstrap'
import { useMemo } from 'react'

//@@ Utils
import { simplifiedMilliseconds } from '../utils'
import {
  getCategoriesParsedMsg,
  getCharacteristicsParsedMsg
} from '@src/views/apps/reports/utils'
import { useGetReportChart } from '@src/views/apps/reports/hooks/useGetReportChart'

//@@ Components
import UILoader from '@components/ui-loader'
import { sourceParsedMessage } from '@src/views/apps/reports/features/Database/utils/sourceParse'

export const TimeChart = ({ printRef, settings, queryState, changeQueryState, churchID, type, sources }) => {
  //@@ Chart state
  const {
    state: { isLoading, chartData }
  } = useGetReportChart({
    initialData: 0,
    dataTransform: (data) => data,
    type,
    settings,
    queryState,
    changeQueryState,
    churchID
  })

  //@@ Parse query results
  const title = useMemo(() => {
    return `Showing the ${settings?.showMeCategory ?? '-'} amount of time it takes starting with ${sourceParsedMessage(
      settings.startSource,
      sources
    )} and ending with ${sourceParsedMessage(settings.endSource, sources)} ${getCategoriesParsedMsg(
      settings.membership,
      settings.individual
    )} ${getCharacteristicsParsedMsg(settings.characteristics)}.`
  }, [queryState])

  //@@ If Intervals[] parse it
  const getResults = (interval) => {
    if (Array.isArray(interval)) {
      return interval.map((v) => simplifiedMilliseconds(v)).join(', ')
    }
    return simplifiedMilliseconds(interval)
  }

  return (
    <UILoader loader={<Spinner />} blocking={isLoading}>
      <Card innerRef={printRef}>
        <CardHeader>
          <CardTitle>Results</CardTitle>
        </CardHeader>
        <CardBody>
          <CardTitle className="text-primary">{title}</CardTitle>
          <div className="fs-3 fw-bolder">
            { chartData !== 0 ? getResults(chartData) : !isLoading && <h2 className="text-center text-warning">No Data Found</h2> }          </div>
        </CardBody>
      </Card>
    </UILoader>
  )
}
