//@@ Libs
import startCase from 'lodash/startCase'
import moment from 'moment'

//@@ Constants
import { CATEGORIES_LABEL, PLATFORM_CATEGORIES } from 'constants/apps'
import { TIMELINE_WEB_TRACKING_EVENT_TYPE } from '@components/apps/UserTimeline/constants'

//@@ Transform profile timeline dto to entity
// FIXME: Date parsing logic should be centralized
export const transformTimelineDtoToEntity = (v) => {
  if (v?.eventType === TIMELINE_WEB_TRACKING_EVENT_TYPE.button_click) {
    return {
      title: `Submitted form ${v?.eventData?.title ? `on ${startCase(v.eventData.title)}` : ''} via Church Website`,
      meta: v?.date ? moment(v.date).format(`M/DD/YY${moment(v.date).hours() === 0 ? '' : ' LT'}`) : v?.created_at ? moment(v.created_at).format(`M/DD/YY${moment(v.created_at).hours() === 0 ? '' : ' LT'}`) : '-'
    }
  }

  if (v?.eventType === TIMELINE_WEB_TRACKING_EVENT_TYPE.page_opened) {
    const nameOfTitle = v?.title === null ? v?.event : v?.title
    return {
      title: `Visited ${nameOfTitle ? startCase(nameOfTitle) : ''} via Church Website`,
      meta: v?.date ? moment(v.date).format(`M/DD/YY${moment(v.date).hours() === 0 ? '' : ' LT'}`) : v?.created_at ? moment(v.created_at).format(`M/DD/YY${moment(v.created_at).hours() === 0 ? '' : ' LT'}`) : '-'
    }
  }

  if (v?.eventType === TIMELINE_WEB_TRACKING_EVENT_TYPE.url_click) {
    const nameOfTitle = v?.title === null ? v?.event : v?.title
    return {
      title: `Clicked on ${nameOfTitle ? startCase(nameOfTitle) : ''} via Church Website`,
      meta: v?.date ? moment(v.date).format(`M/DD/YY${moment(v.date).hours() === 0 ? '' : ' LT'}`) : v?.created_at ? moment(v.created_at).format(`M/DD/YY${moment(v.created_at).hours() === 0 ? '' : ' LT'}`) : '-'
    }
  }


  // FIXME: This condition could be too wide in the near future
  if (v.eventSource === 'YouTube') {
    return {
      title: `Commented ${v?.event} via ${startCase(v?.eventSource)}`,
      meta: v?.date ? moment(v.date).format(`M/DD/YY${moment(v.date).hours() === 0 ? '' : ' LT'}`) : v?.created_at ? moment(v.created_at).format(`M/DD/YY${moment(v.created_at).hours() === 0 ? '' : ' LT'}`) : '-'
    }
  }

  if (v.eventSource === 'zoom') {
    return {
      title: `Attended ${v?.event} via ${startCase(v?.eventSource)}`,
      meta: v?.date ? moment(v.date).format(`M/DD/YY${moment(v.date).hours() === 0 ? '' : ' LT'}`) : v?.created_at ? moment(v.created_at).format(`M/DD/YY${moment(v.created_at).hours() === 0 ? '' : ' LT'}`) : '-'
    }
  }

  // FIXME This needs to be refactored
  return {
    title:
      v?.eventCategory === 'giving'
        ? `Gave $${v?.amount ?? '-'} Offering via ${startCase(v?.gift_source?.trim() !== '' ? v?.gift_source : v?.type ? v?.type : v?.eventSource)}`
        : `${
            v?.event && (v.event === 'Got Saved' || v.event === 'Got Baptized')
              ? ''
              : v?.status
              ? v?.eventCategory
                ? CATEGORIES_LABEL?.[v.eventCategory] ?? startCase(v?.status)
                : startCase(v?.status)
              : v?.eventCategory
              ? CATEGORIES_LABEL?.[v.eventCategory] : 'Attended'
          } ${
            v?.event
              ? startCase(v?.event)
              : startCase(PLATFORM_CATEGORIES.find(({ value }) => v?.eventCategory === value)?.label ?? '-')
          } ${v?.eventSource === 'physical church location' ? 'at' : 'via'} ${startCase(v?.eventSource ?? '')}`,
    meta: v?.date ? moment(v.date).format(`M/DD/YY${moment(v.date).hours() === 0 ? '' : ' LT'}`) : v?.created_at ? moment(v.created_at).format(`M/DD/YY${moment(v.created_at).hours() === 0 ? '' : ' LT'}`) : v?.join_time ? moment(v.join_time).format(`M/DD/YY${moment(v.join_time).hours() === 0 ? '' : ' LT'}`) : '-'
  }
}
