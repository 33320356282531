//@@ Libs
import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'

//@@ Hooks
import { useTimeline } from '@src/views/apps/profiles/hooks/useTimeline'
import { useProfileIdParam } from '@src/views/apps/profiles/hooks/useProfileIdParam'

//@@ Components
import { ProfileBreadCrumbs } from '@src/views/apps/profiles/list/components/ProfileBreadCrumbs'
import { EngagementProfileInfo } from '@src/views/apps/profiles/list/components/EngagementProfileInfo'
import UILoader from '@src/@core/components/ui-loader/index'

//@@ Styles
import '@styles/react/apps/app-users.scss'

const EngagementPage = () => {
  //@@ Navigate hook
  const navigate = useNavigate()
  //@@ Selectors
  const churchID = useSelector((state) => state.auth.userData?.churchID)
  //@@ Profile query hook
  const { profile, profilesQuery, isFetching } = useProfileIdParam()
  //@@ Timeline hook
  const { data: timeline } = useTimeline({ churchID, profileId: profile?.id, queryOptions: { skip: !profile?.id } })

  // if (profilesQuery.isLoading || !timeline) {
  //   return <Spinner />
  // }

  if (profilesQuery.isLoading || isFetching || !timeline) {
    return (
      <UILoader
        loader={
          <div>
            <Spinner />
          </div>
        }
        blocking={profilesQuery.isLoading || isFetching}
        style={{ minHeight: '30rem' }}
        className="overflow-auto"
      ></UILoader>
    )
  }

  if (!profile || profilesQuery.isError) {
    return <Navigate to="/profiles" />
  }

  return (
    <>
      <ProfileBreadCrumbs isEngagement={true} />
      <EngagementProfileInfo
        profile={profile}
        onBackResolve={() => navigate(`/profiles/${profile.id}`)}
        timeline={timeline}
        churchID={churchID}
      />
    </>
  )
}

export default EngagementPage
