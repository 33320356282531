//@@ Store
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DataStore } from 'aws-amplify'
import { SourceModel } from '@src/models'

//@@ Utils
import { createActionTypeFactoryFn } from '@src/utility/Utils'

//@@ Constants
import { LOADING_STATE } from 'constants/api'

const SLICE_NAME = 'appConnectedSources'
const createActionType = createActionTypeFactoryFn(SLICE_NAME)

export const getConnectedSources = createAsyncThunk(createActionType(SLICE_NAME), async () => {
  try {
    const data = await DataStore.query(SourceModel)
    return {
      data
    }
  } catch (e) {
    console.error(e)
  }
})

const connectedSourcesSlice = createSlice({
  name: SLICE_NAME,
  initialState: {
    data: [],
    loadingState: LOADING_STATE.IDLE
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getConnectedSources.pending, (state) => {
      state.loadingState = LOADING_STATE.PENDING
    })
    builder.addCase(getConnectedSources.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.loadingState = LOADING_STATE.FINISHED
    })
  }
})

export default connectedSourcesSlice.reducer
