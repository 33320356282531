// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const NotificationType = {
  "PROFILE_RESOLUTION": "PROFILE_RESOLUTION",
  "GOAL_ACHIEVED": "GOAL_ACHIEVED"
};

const GoalTimePeriod = {
  "DAILY": "DAILY",
  "WEEKLY": "WEEKLY",
  "MONTHLY": "MONTHLY",
  "THREEMONTH": "THREEMONTH",
  "YEARLY": "YEARLY",
  "CUSTOM": "CUSTOM"
};

const GoalCategory = {
  "GIVING": "GIVING",
  "ATTENDANCE": "ATTENDANCE",
  "CELLGROUP": "CELLGROUP",
  "BIBLESTUDY": "BIBLESTUDY",
  "MEMBERSHIP": "MEMBERSHIP",
  "MINISTRY": "MINISTRY",
  "SOCIALMEDIA": "SOCIALMEDIA",
  "SALVATIONS": "SALVATIONS",
  "BAPTISMS": "BAPTISMS"
};

const GoalType = {
  "NUMBER": "NUMBER",
  "ENGAGEMENT": "ENGAGEMENT",
  "ENGAGEMENTLEVEL": "ENGAGEMENTLEVEL"
};

const UserRole = {
  "CUSTOMER": "CUSTOMER",
  "CHURCHUSER": "CHURCHUSER",
  "ADMIN": "ADMIN"
};

const { NotificationModel, GoalModel, SourceModel, UserProfileModel, ChurchModel } = initSchema(schema);

export {
  NotificationModel,
  GoalModel,
  SourceModel,
  UserProfileModel,
  ChurchModel,
  NotificationType,
  GoalTimePeriod,
  GoalCategory,
  GoalType,
  UserRole
};