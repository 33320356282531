//@@ Libs
import { useEffect } from 'react'
import classnames from 'classnames'
import { ArrowUp } from 'react-feather'
import { Navbar, Button } from 'reactstrap'

//@@ Configs
import themeConfig from '@configs/themeConfig'

//@@ Components
import Customizer from '@components/customizer'
import ScrollToTop from '@components/scrolltop'
import FooterComponent from './components/footer'
import { HeaderNavbar } from '@layouts/components/header-navbar'
import { MainMenu } from '@layouts/components/main-menu/MainMenu'

//@@ Hooks
import { useLayoutState } from '@layouts/hooks/useLayoutState'

//@@ Styles
import '@styles/base/core/menu/menu-types/horizontal-menu.scss'

const HorizontalLayout = (props) => {
  //@@ Props
  const { navbar, menuData, footer, children, menu } = props

  //@@ Layout hook
  const { state, methods } = useLayoutState()

  //@@ UseEffect Cleanup
  const cleanup = () => {
    methods.setIsMounted(false)
    methods.setNavbarScrolled(false)
  }

  //@@ ComponentDidMount
  useEffect(() => {
    methods.setIsMounted(true)
    window.addEventListener('scroll', function () {
      if (window.pageYOffset > 65 && state.navbarScrolled === false) {
        methods.setNavbarScrolled(true)
      }
      if (window.pageYOffset < 65) {
        methods.setNavbarScrolled(false)
      }
    })
    return () => cleanup()
  }, [])

  //@@ Vars
  const footerClasses = {
    static: 'footer-static',
    sticky: 'footer-fixed',
    hidden: 'footer-hidden'
  }

  const navbarWrapperClasses = {
    floating: 'navbar-floating',
    sticky: 'navbar-sticky',
    static: 'navbar-static'
  }

  const navbarClasses = {
    floating: state.contentWidth === 'boxed' ? 'floating-nav container-xxl' : 'floating-nav',
    sticky: 'fixed-top'
  }

  const bgColorCondition = state.navbarColor !== '' && state.navbarColor !== 'light' && state.navbarColor !== 'white'

  if (!state.isMounted) {
    return null
  }

  return (
    <div
      className={classnames(
        `wrapper horizontal-layout horizontal-menu ${navbarWrapperClasses[state.navbarType] || 'navbar-floating'} ${
          footerClasses[state.footerType] || 'footer-static'
        } menu-expanded`
      )}
      {...(state.isHidden ? { 'data-col': '1-column' } : {})}
    >
      <Navbar
        expand="lg"
        container={false}
        className={classnames('header-navbar navbar-fixed align-items-center navbar-shadow navbar-brand-center', {
          'navbar-scrolled': state.navbarScrolled
        })}
      >
        <div className="navbar-container d-flex content">
          {navbar ? navbar({ skin: state.skin, setSkin: methods.setSkin }) : <HeaderNavbar />}
        </div>
      </Navbar>

      <MainMenu
        menu={menu}
        bgColorCondition={bgColorCondition}
        isHidden={state.isHidden}
        menuData={menuData}
        navbarClasses={navbarClasses}
      />

      {children}
      {themeConfig.layout.customizer === true ? (
        <Customizer
          skin={state.skin}
          isRtl={state.isRtl}
          layout={state.layout}
          setSkin={methods.setSkin}
          setIsRtl={methods.setIsRtl}
          isHidden={state.isHidden}
          setLayout={methods.setLayout}
          footerType={state.footerType}
          navbarType={state.navbarType}
          transition={state.transition}
          setIsHidden={methods.setIsHidden}
          themeConfig={themeConfig}
          navbarColor={state.navbarColor}
          contentWidth={state.contentWidth}
          setTransition={methods.setTransition}
          setFooterType={methods.setFooterType}
          setNavbarType={methods.setNavbarType}
          setLastLayout={methods.setLastLayout}
          setNavbarColor={methods.setNavbarColor}
          setContentWidth={methods.setContentWidth}
        />
      ) : null}
      <footer
        className={classnames(`footer footer-light ${footerClasses[state.footerType] || 'footer-static'}`, {
          'd-none': state.footerType === 'hidden'
        })}
      >
        {footer ? footer : <FooterComponent footerType={state.footerType} footerClasses={footerClasses} />}
      </footer>

      {themeConfig.layout.scrollTop === true ? (
        <div className="scroll-to-top">
          <ScrollToTop showOffset={300} className="scroll-top d-block">
            <Button className="btn-icon" color="primary">
              <ArrowUp size={14} />
            </Button>
          </ScrollToTop>
        </div>
      ) : null}
    </div>
  )
}
export default HorizontalLayout
