//@@ Libs
import { Link } from 'react-router-dom'
//@@ Components
import { Logo } from '@components/logo/Logo'

export const LogoBar = () => {
  return (
    <Link to="/" className="logo-bar d-flex align-items-center">
      <Logo />
    </Link>
  )
}
