//@@ Libs
import { Col, Label, Row, Spinner } from 'reactstrap'
import Select from 'react-select'

//@@ Utils
import { getOptionFromRawValue, getOptionsFromRawValues, getRawValueFromOption, selectThemeColors } from '@utils'
import { useLayout } from '@hooks/useLayout'

//@@ Constants
import { SHOW_ME_OPTIONS } from '@src/views/apps/reports/constants'
import { LAYOUT_TYPE } from '@src/constants'

//@@ Components
import UILoader from '@components/ui-loader'
import { FormSourceActions } from '@src/views/apps/reports/components/FormElements/FormSourceActions'
import { FormForTheseSelect } from '@src/views/apps/reports/components/FormElements/FormForTheseSelect'
import { Characteristic } from '@src/views/apps/reports/components/Characteristic/Characteristic'
import { FormBetweenRange } from '@src/views/apps/reports/components/FormElements/FormBetweenRange'

export const TimeForm = ({
  isLoading,
  settings,
  settingsDictionaries,
  onSettingChange,
  onCharacteristicChange,
  onCharacteristicAdd,
  onCharacteristicValueChange,
  onCharacteristicRemove,
  onChangeSource,
  onChangeAction,
  onChangeProperties
}) => {
  //@@ Use layout hook
  const { layout } = useLayout()

  return (
    <>
      <UILoader loader={<Spinner />} blocking={isLoading}>
        <Row className="mb-2">
          <Label xs="12" sm={2} for="showMe" className={`pt-0 fs-${layout === LAYOUT_TYPE.vertical ? 4 : 6}`}>
            Show Me The
            <span className="requiredFormLabelAsterisk">*</span>
          </Label>
          <Col xs="12" sm={4}>
            <Select
              name="showMe"
              theme={selectThemeColors}
              className="react-select"
              classNamePrefix="select"
              placeholder="search"
              options={SHOW_ME_OPTIONS}
              isClearable={false}
              value={getOptionFromRawValue({ rawValue: settings.showMeCategory, options: SHOW_ME_OPTIONS })}
              onChange={(opt) => onSettingChange({ name: 'showMeCategory', value: getRawValueFromOption(opt) })}
            />
          </Col>
          <Label xs={4} className={`pt-1 fs-${layout === LAYOUT_TYPE.vertical ? 4 : 6} pt-sm-0`}>
            It Takes
          </Label>
        </Row>

        <FormSourceActions
          firstLabel="Starting With This"
          lastLabel="With These"
          requiredFields={['action', 'source', 'properties']}
          sourcesDictionary={settingsDictionaries.sourcesDictionary}
          actionDictionary={settingsDictionaries.startSourceActionDictionary}
          propertiesDictionary={settingsDictionaries.startSourcePropertiesDictionary}
          action={settings.startSource.action}
          source={settings.startSource.source}
          properties={settings.startSource.properties}
          onChangeSource={(opt) => onChangeSource(opt, 'start')}
          onChangeAction={(opt) => onChangeAction(opt, 'start')}
          onChangeProperties={(opt) => onChangeProperties(opt, 'start')}
        />

        <FormSourceActions
          firstLabel="Ending With This"
          lastLabel="With These"
          requiredFields={['action', 'source', 'properties']}
          sourcesDictionary={settingsDictionaries.sourcesDictionary}
          actionDictionary={settingsDictionaries.endSourceActionDictionary}
          propertiesDictionary={settingsDictionaries.endSourcePropertiesDictionary}
          action={settings.endSource.action}
          source={settings.endSource.source}
          properties={settings.endSource.properties}
          onChangeSource={(opt) => onChangeSource(opt, 'end')}
          onChangeAction={(opt) => onChangeAction(opt, 'end')}
          onChangeProperties={(opt) => onChangeProperties(opt, 'end')}
        />

        <Row className="mb-2">
          <Col xs={12} sm={6}>
            <FormBetweenRange
              isRequired
              fullWidth
              label="That Happened Between"
              settingDates={settings.betweenDates}
              onSettingChange={onSettingChange}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={6}>
            <FormForTheseSelect
              label="To These"
              categoriesOptions={settingsDictionaries.membership}
              categoriesValue={getOptionsFromRawValues({
                rawValue: settings.membership,
                options: settingsDictionaries.membership
              })}
              individual={settings.individual}
              onSettingChange={onSettingChange}
            />
          </Col>

          <Col xs={12} sm={6}>
            <Row className="mb-1">
              <Label xs="4" for="withThese" className="pt-0 align-self-start fs-6">
                With These
              </Label>
              <Characteristic
                characteristics={settings.characteristics}
                settingsDictionaries={settingsDictionaries}
                onCharacteristicChange={onCharacteristicChange}
                onCharacteristicAdd={onCharacteristicAdd}
                onCharacteristicValueChange={onCharacteristicValueChange}
                onCharacteristicRemove={onCharacteristicRemove}
              />
            </Row>
          </Col>
        </Row>
      </UILoader>
    </>
  )
}
