import { useEffect, useState } from 'react'
import moment from 'moment'
import { getReadableDate } from '@utils'
import { transformTimelineDtoToEntity } from '@components/apps/UserTimeline/utils'

import { DEFAULT_SHORT_DATE_FORMAT } from 'constants/datetime'

export const useProfileTimeline = ({ profile, timeline }) => {
  const [times, setTimes] = useState([])

  useEffect(() => {
    const _times = timeline?.map(transformTimelineDtoToEntity) ?? []

    if (profile?.hasOwnProperty('1st_visit_date')) {
      _times.push({ meta: moment(profile['1st_visit_date']).format(DEFAULT_SHORT_DATE_FORMAT), title: 'First Visited Church' })
    }

    _times.sort(function(a, b) {
      return new Date(b.meta) - new Date(a.meta)
    })
    setTimes(_times)
  }, [profile, timeline])

  return times
}
