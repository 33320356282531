//@@ Libs
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Row, Spinner, Table } from 'reactstrap'
import { useContext, useState } from 'react'
import Chart from 'react-apexcharts'
import { Navigate } from 'react-router-dom'

//@@ Utils
import { FormContext } from '@src/views/apps/reports/features/WebInsights/formContext'

//@@ Components
import UserTimeline from '@components/apps/UserTimeline/UserTimeline'
import UILoader from '@src/@core/components/ui-loader/index'

//@@ Hooks
import { useProfileIdParam } from '@src/views/apps/profiles/hooks/useProfileIdParam'
import { useProfileTimeline } from '@src/views/apps/profiles/hooks/useProfileTimeline'

//@@ Results mock
const mockedPeopleResults = [
  {
    id: 1,
    email: 'erich@example.com',
    last_name: 'Rich',
    first_name: 'Emily-Rose',
    avatar: 'https://avatars.planningcenteronline.com/uploads/person/110341552-1655410065/avatar.2.png'
  },
  {
    id: 2,
    email: 'kbloom@example.com',
    last_name: 'Bloom',
    first_name: 'Kamila',
    avatar: 'https://avatars.planningcenteronline.com/uploads/person/110341556-1655410065/avatar.1.png'
  },
  {
    id: 3,
    email: 'cellis@example.com',
    last_name: 'Ellis',
    first_name: 'Chyna',
    avatar: 'https://avatars.planningcenteronline.com/uploads/person/110341561-1655410066/avatar.1.png'
  },
  {
    id: 4,
    email: 'ayang@example.com',
    last_name: 'Yang',
    first_name: 'Atlas',
    avatar: 'https://avatars.planningcenteronline.com/uploads/person/110341542-1655410063/avatar.1.png'
  },
  {
    id: 5,
    email: 'rebecca@example.com',
    last_name: 'Olive',
    first_name: 'Rebecca',
    avatar: 'https://avatars.planningcenteronline.com/uploads/person/110341562-1655410066/avatar.2.png'
  },
  {
    id: 6,
    email: 'maryann@example.com',
    last_name: 'Hose',
    first_name: 'Mary Ann',
    avatar: 'https://avatars.planningcenteronline.com/uploads/person/110341554-1655410065/avatar.1.png'
  },
  {
    id: 7,
    email: 'lparkinson@example.com',
    last_name: 'Parkinson',
    first_name: 'Lily',
    avatar: 'https://avatars.planningcenteronline.com/uploads/person/110341543-1655410063/avatar.1.png'
  },
  {
    id: 8,
    email: 'gperson@example.com',
    last_name: 'Person',
    first_name: 'Giorgia',
    avatar: 'https://avatars.planningcenteronline.com/uploads/person/110341562-1655410066/avatar.2.png'
  },
  {
    id: 9,
    email: 'csalinas@example.com',
    last_name: 'Salinas',
    first_name: 'Callen',
    avatar: 'https://avatars.planningcenteronline.com/uploads/person/110341553-1655410065/avatar.1.png'
  }
]

//@@ Chart options
const options = {
  chart: {
    parentHeightOffset: 0,
    toolbar: {
      show: false
    }
  },
  plotOptions: {
    bar: {
      borderRadius: [10]
    }
  },
  grid: {
    xaxis: {
      lines: {
        show: false
      }
    }
  },
  colors: '#836AF9',
  dataLabels: {
    enabled: false
  },
  xaxis: {
    categories: ['MON, 11', 'THU, 14', 'FRI, 15', 'MON, 18', 'WED, 20', 'FRI, 21', 'MON, 23'],
    labels: {
      style: {
        fontSize: '14px'
      }
    }
  },
  yaxis: {
    labels: {
      formatter: (v) => `$ ${v}`,
      style: {
        fontSize: '14px'
      }
    }
  }
}

//@@ Chart Series
const series = [
  {
    data: [700, 350, 480, 600, 210, 550, 150]
  }
]

//@@ Timeline mock
const timeline = [
  {
    date: '2022-06-15T00:00:00',
    event: 'Bible Study',
    last_name: 'Rich',
    first_name: 'Emily-Rose',
    amount: 20,
    eventType: 'payments',
    eventSource: 'givelify',
    id: 'b8e17799-a559-4e19-8c3a-7346b86f4f9c',
    eventCategory: 'giving'
  },
  {
    date: '2022-06-07T19:00:00',
    zoom_meeting_id: '3771589804',
    enddate: '2022-06-07T21:00:00',
    last_name: 'Rich',
    first_name: 'Emily-Rose',
    status: 'Attended',
    eventType: 'events',
    eventSource: 'zoom',
    id: '6b45919a-2e7e-4441-9359-9fc92573565f',
    email: 'erich@example.com',
    eventCategory: 'cell_group'
  },
  {
    date: '2022-06-01T19:00:00',
    zoom_meeting_id: '3771589805',
    enddate: '2022-06-01T21:00:00',
    last_name: 'Rich',
    first_name: 'Emily-Rose',
    status: 'Attended',
    eventType: 'events',
    eventSource: 'zoom',
    id: '1fe69d80-b608-41a1-af5c-10e4cf7123fd',
    email: 'erich@example.com',
    eventCategory: 'bible_study'
  },
  {
    date: '2022-05-04T19:00:00',
    zoom_meeting_id: '3771589805',
    enddate: '2022-05-04T21:00:00',
    last_name: 'Rich',
    first_name: 'Emily-Rose',
    status: 'Attended',
    eventType: 'events',
    eventSource: 'zoom',
    id: '76ab1a9a-51d3-423e-8a08-5ee47cb778c0',
    email: 'erich@example.com',
    eventCategory: 'bible_study'
  },
  {
    date: '2022-04-19T19:00:00',
    zoom_meeting_id: '3771589804',
    enddate: '2022-04-19T21:00:00',
    last_name: 'Rich',
    first_name: 'Emily-Rose',
    status: 'Attended',
    eventType: 'events',
    eventSource: 'zoom',
    id: 'af58ba37-d143-45df-b2ee-a2ec6748ec63',
    email: 'erich@example.com',
    eventCategory: 'cell_group'
  }
]

export const IndividualResults = () => {
  //@@ Import form settings context
  const { printRef } = useContext(FormContext)
  //@@ Profile query hook
  const { profile, profilesQuery, isFetching } = useProfileIdParam()
  //@@ Results state
  const [selectedPerson, setSelectedPerson] = useState(mockedPeopleResults[0])
  const [selectedTab, setSelectedTab] = useState('events')

  const times =  useProfileTimeline({profile, timeline})

  const handlePersonSelect = (person) => {
    setSelectedPerson(person)
  }

  if (profilesQuery.isLoading || isFetching || !timeline) {
    return (
      <UILoader
        loader={
          <div>
            <Spinner />
          </div>
        }
        blocking={profilesQuery.isLoading || isFetching}
        style={{ minHeight: '30rem' }}
        className="overflow-auto"
      ></UILoader>
    )
  }

  if (!profile || profilesQuery.isError) {
    return <Navigate to="/profiles" />
  }

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Choose Individual</CardTitle>
        </CardHeader>
        <CardBody>
          <Table responsive hover>
            <thead>
              <tr>
                <th>First name</th>
                <th>Last name</th>
                <th>E-mail</th>
              </tr>
            </thead>
            <tbody>
              {mockedPeopleResults.map((person, i) => {
                return (
                  <tr key={i} className="cursor-pointer" onClick={() => handlePersonSelect(person)}>
                    <td>{person.first_name}</td>
                    <td>{person.last_name}</td>
                    <td>{person.email}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      {selectedPerson && (
        <div>
          <Card>
            <CardHeader className="flex-column align-items-stretch">
              <div className="d-flex">
                <Button
                  color={selectedTab === 'events' ? 'primary' : undefined}
                  className="me-2"
                  onClick={() => setSelectedTab('events')}
                >
                  Events
                </Button>
                <Button
                  color={selectedTab === 'timeline' ? 'primary' : undefined}
                  onClick={() => setSelectedTab('timeline')}
                >
                  Timeline
                </Button>
              </div>
              <div className="d-flex flex-column align-items-center">
                <div className="avatar avatar-lg">
                  <img src={selectedPerson.avatar} />
                </div>
                <span>{selectedPerson.email}</span>
              </div>
            </CardHeader>
          </Card>

          <div ref={printRef}>
            <Row>
              <Col xs={12} md={4}>
                <Card style={{ height: '480px' }}>
                  <CardHeader>
                    <CardTitle>Properties</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="mb-2">
                      <div className="fw-bolder">Customer ID</div>
                      <div>{selectedPerson.email}</div>
                    </div>

                    <div className="my-2">
                      <div className="fw-bolder">Country</div>
                      <div>-</div>
                    </div>

                    <div className="my-2">
                      <div className="fw-bolder">City</div>
                      <div>-</div>
                    </div>

                    <div className="my-2">
                      <div className="fw-bolder">Campaign Medium</div>
                      <div>-</div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={12} md={8}>
                {selectedTab === 'events' ? (
                  <Card>
                    <CardBody>
                      <Chart options={options} series={series} type="bar" height={438} />
                    </CardBody>
                  </Card>
                ) : (
                  <UserTimeline timeline={times} />
                )}
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  )
}
