import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DataStore, Storage } from 'aws-amplify'
import { ChurchModel } from '@src/models'
import { createProfile } from '@src/redux/csv'

export const getChurches = createAsyncThunk('appChurches/getAll', async () => {
  const data = await DataStore.query(ChurchModel)
  return data
})

export const getChurch = createAsyncThunk('appChurches/getChurch', async (id) => {
  const data = await DataStore.query(ChurchModel, id)
  return data
})

export const addChurch = createAsyncThunk('appChurches/create', async (data, { dispatch }) => {
  const new_data = await DataStore.save(new ChurchModel(data))
  await dispatch(createProfile({ church: new_data['id'] }))
  return new_data
})

export const updateChurch = createAsyncThunk('appChurches/update', async ({ id, data }) => {
  const original = await DataStore.query(ChurchModel, id)
  const response = await DataStore.save(
    ChurchModel.copyOf(original, (updated) => {
      for (const key in data) {
        updated[key] = data[key]
      }
    })
  )
  return response
})

export const updateChurchConnectedSources = createAsyncThunk(
  'appChurches/updateConnectedSources',
  async ({ id, source, state }) => {
    const original = await DataStore.query(ChurchModel, id)
    const response = await DataStore.save(
      ChurchModel.copyOf(original, (updated) => {
        if (!state) {
          updated.connectedSources = original.connectedSources.filter((id) => id !== source)
        } else {
          updated.connectedSources = [...(original.connectedSources ?? []), source]
        }
      })
    )
    return response
  }
)

export const deleteChurch = createAsyncThunk('appChurches/delete', async (churchID) => {
  await DataStore.delete(ChurchModel, churchID)
  return churchID
})

export const loadLogoUrl = createAsyncThunk('appChurches/loadLogo', async ({ name, options }) => {
  if (name) {
    return await Storage.get(name, { ...options })
  }

  return null
})

const churchesSlice = createSlice({
  name: 'churches',
  initialState: {
    data: [],
    church: null,
    logoUrl: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getChurches.fulfilled, (state, action) => {
      state.data = action.payload
    })
    builder.addCase(addChurch.fulfilled, (state, action) => {
      state.data = state.data.concat([action.payload])
    })
    builder.addCase(getChurch.fulfilled, (state, action) => {
      state.church = action.payload
    })
    builder.addCase(updateChurch.fulfilled, (state, action) => {
      state.church = action.payload
    })
    builder.addCase(updateChurchConnectedSources.fulfilled, (state, action) => {
      state.church = action.payload
    })
    builder.addCase(deleteChurch.fulfilled, (state, action) => {
      state.data = state.data.filter((i) => i.id !== action.payload)
    })
    builder.addCase(loadLogoUrl.fulfilled, (state, action) => {
      state.logoUrl = action.payload
    })
  }
})

export default churchesSlice.reducer
