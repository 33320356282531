//@@ Libs
import { useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import startCase from 'lodash/startCase'

//@@ Hooks
import { useLayout } from '@hooks/useLayout'

//@@ Constants
import { LOADING_STATE } from 'constants/api'
import { api } from '@src/services/api'
import { SOURCES_BY_DATA_REPORTS } from '@src/views/apps/reports/constants'
import { LAYOUT_TYPE } from '@src/constants'
import { getJWTToken } from '@src/utility/auth/tokens'

const pollForData = async ({ body, token, query_id }) => {
  const url = query_id ? `get-report-chart-polling?query_id=${query_id}` : 'get-report-chart-polling'
  return api
    .post(url, body,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    .then(({ status, data }) => {
      if (status === 202) {
        const q_id = data.query_id || query_id
        if (!q_id) {
          throw new Error('Query ID is missing')
        }
        return new Promise((resolve) => {
          setTimeout(() => resolve(pollForData({ body, token, query_id: q_id })), 5000)
        })
      }
      if (status !== 200) {
        throw new Error('Error fetching data')
      }

      return { data, status }
    })
    .catch((e) => {
      console.error('Error fetching data:', e)
      return e
    })
}

const fetchData = async ({ body, token, polling }) => {
  if (!polling) return api
    .post('get-report-chart', body,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
  else return pollForData({ body, token })
}

export const useGetReportChart = ({
                                    initialData,
                                    dataTransform,
                                    type,
                                    settings,
                                    churchID,
                                    queryState,
                                    changeQueryState,
                                    setSourcesFromData,
                                    setCsvRows
                                  }) => {
  //@@ Chart state
  const [chartData, setChartData] = useState(initialData)
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState('OK')
  //@@ Layout
  const { layout } = useLayout()

  //@@ Load chart data on run query
  useEffect(() => {
    if (queryState === LOADING_STATE.FINISHED) {
      return
    }
    let count = 1

    setIsLoading(true)
    const getReportChart = () => {
      getJWTToken()
        .then(token => fetchData({ body: { type, settings, churchID }, token, polling: type === 'journey' }))
        .then((response) => {
          if (response.status === 200) {

            const data = response.data
            setChartData(typeof data !== 'number' && isEmpty(data) ? initialData : dataTransform(data))
            setIsLoading(false)
            changeQueryState(LOADING_STATE.FINISHED)

            if (SOURCES_BY_DATA_REPORTS.includes(type) && !isEmpty(data)) {
              setSourcesFromData(data?.data?.map(({ sourceName }) => startCase(sourceName)))
            }
            if (data) {
              if (type === 'consolidate') {
                const csvData = [['Date', 'Source', 'Total', 'Average']]
                data.data.forEach((val, i) => {
                  val.total.forEach((v1, i1) => {
                    csvData.push([data.labels[i1], val['sourceName'], v1, val.average[i1]])
                  })
                })
                setCsvRows(csvData)
              } else if (type === 'participation') {
                const csvData = [['Date', 'Source', 'Percentage']]
                data.data.forEach((val) => {
                  val.data.forEach((v1, i1) => {
                    csvData.push([data.labels[i1], val['sourceName'], v1.toFixed(2)])
                  })
                })
                setCsvRows(csvData)
              } else {
                setCsvRows(data.people_list.map(({
                                                   first_name,
                                                   last_name,
                                                   email
                                                 }) => [first_name, last_name, email]))
              }
            }
            if (data?.length === 0) {
              setMessage('No data found')
            }
            count = 0
          }
          if (response.status === 204) {
            setChartData(initialData)
            setMessage('Not applicable')
          }
          setIsLoading(false)
          changeQueryState(LOADING_STATE.FINISHED)
        })
        .catch((e) => {
          if (e?.code === 'ERR_NETWORK' && !e?.response?.data && count < 16 && count !== 0) {
            count++
            getReportChart()
          } else {
            setIsLoading(false)
            changeQueryState(LOADING_STATE.FINISHED)
            count = 0
          }
        })
    }
    getReportChart()

    return () => {
      count = 0
    }
  }, [queryState])
  return { state: { chartData, isLoading, isMobile: layout === LAYOUT_TYPE.vertical, message } }
}
