//@@ Libs
import { lazy } from 'react'

//@@ Constants
import { ABILITIES } from '@src/constants/main'
import { SUBJECTS } from '@src/constants/subjects'

//@@ Components
import {
  ConsolidateDatabase,
  EngagementDatabase,
  JourneyDatabase,
  ParticipationDatabase,
  TimeDatabase
} from '@src/views/apps/reports/features/Database/features'
import ProfilePage from '@src/views/apps/profiles/view/ProfilePage'
import EngagementPage from '@src/views/apps/profiles/view/components/Engagement/EngagementPage'
import { FunnelReport } from '@src/views/apps/reports/features/WebInsights/features/Funnel/Funnel'
import { CohortReport } from '@src/views/apps/reports/features/WebInsights/features/Cohort/Cohort'
import { IndividualReport } from '@src/views/apps/reports/features/WebInsights/features/Individual/Individuals'

//@@ Lazy imports
const Reports = lazy(() => import('../../views/apps/reports/Reports'))

const StaffList = lazy(() => import('../../views/admin/apps/staff/list'))
const StaffView = lazy(() => import('../../views/admin/apps/staff/view'))

const Churches = lazy(() => import('../../views/apps/churches/list'))
const Church = lazy(() => import('../../views/apps/churches/view'))
const Profiles = lazy(() => import('../../views/apps/profiles/list'))

const ConnectedSources = lazy(() => import('../../views/apps/connected-sources'))

const Analytics = lazy(() => import('../../views/apps/reports/features/WebInsights/features/Metrics/Analytics'))

const GoalsInfoPage = lazy(() => import('../../views/apps/goals/goals-info/GoalsInfo'))

const ProfileMerge = lazy(() => import('../../views/apps/profile-merge/ProfileMerge'))

//@@ App Routes
const AppRoutes = [
  {
    element: <GoalsInfoPage />,
    path: '/dashboard/ecommerce/:id',
    meta: {
      action: ABILITIES.read,
      resource: SUBJECTS.dashboard
    }
  },
  {
    element: <ProfileMerge />,
    path: '/profile-merge/:id',
    meta: {
      action: ABILITIES.read,
      resource: SUBJECTS.profileMerge
    }
  },
  {
    element: <StaffList />,
    path: '/apps/staff/view',
    meta: {
      action: ABILITIES.read,
      resource: SUBJECTS.staff
    }
  },
  {
    element: <StaffView />,
    path: '/apps/staff/view/:id',
    meta: {
      action: ABILITIES.read,
      resource: SUBJECTS.staff
    }
  },
  {
    element: <Churches />,
    path: '/churches',
    meta: {
      action: ABILITIES.read,
      resource: SUBJECTS.churches
    }
  },
  {
    element: <Church />,
    path: '/church',
    meta: {
      action: ABILITIES.read,
      resource: SUBJECTS.church
    },
    children: [
      {
        path: ':userId',
        element: <Church />
      }
    ]
  },
  {
    element: <Profiles />,
    path: '/profiles',
    meta: {
      action: ABILITIES.read,
      resource: SUBJECTS.church
    }
  },
  {
    element: <ProfilePage />,
    path: '/profiles/:profileId',
    meta: {
      action: ABILITIES.read,
      resource: SUBJECTS.church
    }
  },
  {
    element: <EngagementPage />,
    path: '/profiles/:profileId/engagement',
    meta: {
      action: ABILITIES.read,
      resource: SUBJECTS.church
    }
  },
  {
    element: <Church />,
    path: '/churches/:id',
    meta: {
      action: ABILITIES.read,
      resource: SUBJECTS.churches
    },
    children: [
      {
        path: ':userId',
        element: <Church />
      }
    ]
  },
  {
    element: <Reports />,
    path: '/reports',
    meta: {
      action: ABILITIES.read,
      resource: SUBJECTS.reports
    }
  },
  {
    element: <ConsolidateDatabase />,
    path: '/reports/single-source/consolidate',
    meta: {
      action: ABILITIES.read,
      resource: SUBJECTS.reports
    },
    children: [
      {
        path: ':reportId',
        element: <ConsolidateDatabase />
      }
    ]
  },
  {
    element: <EngagementDatabase />,
    path: '/reports/single-source/engagement',
    meta: {
      action: ABILITIES.read,
      resource: SUBJECTS.reports
    },
    children: [
      {
        path: ':reportId',
        element: <EngagementDatabase />
      }
    ]
  },
  {
    element: <JourneyDatabase />,
    path: '/reports/single-source/journey',
    meta: {
      action: ABILITIES.read,
      resource: SUBJECTS.reports
    },
    children: [
      {
        path: ':reportId',
        element: <JourneyDatabase />
      }
    ]
  },
  {
    element: <ParticipationDatabase />,
    path: '/reports/single-source/participation',
    meta: {
      action: ABILITIES.read,
      resource: SUBJECTS.reports
    },
    children: [
      {
        path: ':reportId',
        element: <ParticipationDatabase />
      }
    ]
  },
  {
    element: <TimeDatabase />,
    path: '/reports/single-source/time',
    meta: {
      action: ABILITIES.read,
      resource: SUBJECTS.reports
    },
    children: [
      {
        path: ':reportId',
        element: <TimeDatabase />
      }
    ]
  },
  {
    element: <Analytics />,
    path: '/reports/web/metrics',
    meta: {
      action: ABILITIES.read,
      resource: SUBJECTS.reports
    },
    children: [
      {
        path: ':reportId',
        element: <Analytics />
      }
    ]
  },
  {
    element: <FunnelReport />,
    path: '/reports/web/funnel',
    meta: {
      action: ABILITIES.read,
      resource: SUBJECTS.reports
    },
    children: [
      {
        path: ':reportId',
        element: <FunnelReport />
      }
    ]
  },
  {
    element: <CohortReport />,
    path: '/reports/web/cohort',
    meta: {
      action: ABILITIES.read,
      resource: SUBJECTS.reports
    },
    children: [
      {
        path: ':reportId',
        element: <CohortReport />
      }
    ]
  },
  {
    element: <IndividualReport />,
    path: '/reports/web/individual',
    meta: {
      action: ABILITIES.read,
      resource: SUBJECTS.reports
    },
    children: [
      {
        path: ':reportId',
        element: <IndividualReport />
      }
    ]
  },
  {
    element: <ConnectedSources />,
    path: '/connected-sources',
    meta: {
      action: ABILITIES.read,
      resource: SUBJECTS.connectedSources
    }
  },
  {
    element: <Analytics />,
    path: '/analytics',
    meta: {
      action: ABILITIES.read,
      resource: SUBJECTS.analytics
    }
  }
]

export default AppRoutes
