// Libs
import { User, Home, Link, PieChart, Flag } from 'react-feather'

//Internals
import { ABILITIES } from '@src/constants/main'
import { SUBJECTS } from '@src/constants/subjects'
import { EXCLUDED_FROM_COMMON_NAVIGATION_IDS } from '@src/navigation/common/excludedFromCommonNavigation'

// All nav items used for different navigation
export const appsNavItems = [
  {
    header: 'Apps & Pages'
  },
  {
    id: 'home',
    title: 'Home',
    icon: <Home size={21} />,
    navLink: '/dashboard/ecommerce',
    action: ABILITIES.read,
    resource: SUBJECTS.dashboard
  },
  {
    id: 'analytics',
    title: 'Analytics',
    icon: <PieChart size={21} />,
    navLink: '/analytics/',
    action: ABILITIES.read,
    resource: SUBJECTS.analytics
  },
  {
    id: 'reports',
    title: 'Reports',
    icon: <Flag size={21} />,
    navLink: '/reports/',
    action: ABILITIES.read,
    resource: SUBJECTS.reports
  },
  {
    id: 'profiles',
    title: 'Profiles',
    icon: <User size={21} />,
    navLink: '/profiles/',
    action: ABILITIES.read,
    resource: SUBJECTS.church
  },
  {
    id: 'churches',
    title: 'Churches',
    icon: <Home size={21} />,
    navLink: '/churches',
    action: ABILITIES.read,
    resource: SUBJECTS.churches
  },
  {
    id: 'staff',
    title: 'Staff',
    icon: <User size={21} />,
    navLink: '/apps/staff/view',
    action: ABILITIES.read,
    resource: SUBJECTS.staff
  },
  {
    id: 'church-users',
    title: 'Church Users',
    icon: <User size={21} />,
    navLink: '/church-users/',
    action: ABILITIES.read,
    resource: SUBJECTS.churchUsers
  },
  {
    id: 'church',
    title: 'Church',
    icon: (
      <img
        alt="logo"
        src={require('@src/assets/images/logo/default-church-logo.png').default}
        style={{ height: '40px' }}
      />
    ),
    navLink: '/church/',
    action: ABILITIES.read,
    resource: SUBJECTS.church
  },
  {
    id: 'connected-sources',
    title: 'Connected Sources',
    icon: <Link size={20} />,
    navLink: '/connected-sources/',
    action: ABILITIES.read,
    resource: SUBJECTS.connectedSources
  }
]

// Default export for only common navigation items
export default appsNavItems.filter(({ id }) => !EXCLUDED_FROM_COMMON_NAVIGATION_IDS.includes(id))
