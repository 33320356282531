//@@ Libs
import { Button, Col, Input, Label, Row } from 'reactstrap'
import { Plus, X } from 'react-feather'
import Select from 'react-select'

//@@ Utils
import { getOptionFromRawValue, selectThemeColors } from '@utils'

//@@ Constants
import { CHARACTERISTICS_OPTIONS } from '@src/views/apps/reports/constants'

//@@ Styles
import './index.scss'

export const Characteristic = ({
  characteristics,
  settingsDictionaries,
  onCharacteristicAdd,
  onCharacteristicChange,
  onCharacteristicValueChange,
  onCharacteristicRemove
}) => {
  //@@ Check for all characteristics selected
  const isAllCharacteristicsSelected = CHARACTERISTICS_OPTIONS.length === characteristics.length

  return (
    <Col xs={8}>
      {characteristics.map(({ characteristic, value }, i) => {
        const options = CHARACTERISTICS_OPTIONS.filter(
          (v) => !characteristics.find((opt) => opt.characteristic === v?.value)
        )

        return (
          <Row key={i}>
            <Col xs="12" md={6} className="mb-2">
              <Select
                name="with"
                theme={selectThemeColors}
                className="react-select"
                classNamePrefix="select"
                placeholder="search"
                value={getOptionFromRawValue({
                  rawValue: characteristic,
                  options: CHARACTERISTICS_OPTIONS
                })}
                options={options}
                isClearable={false}
                onChange={(opt) => onCharacteristicChange(opt, i)}
              />
            </Col>
            {characteristic && (
              <Col xs="12" md={6} className="mb-2 d-flex align-items-center">
                {characteristic === 'age' ? (
                  <>
                    <Label className="me-75 fs-6">From</Label>
                    <Input
                      type="number"
                      value={value?.from ?? ''}
                      className="form-characteristic-input"
                      onChange={(e) => onCharacteristicValueChange({ from: e.target.value }, i)}
                    />

                    <Label className="mx-75 fs-6">To</Label>
                    <Input
                      type="number"
                      value={value?.to ?? ''}
                      className="form-characteristic-input"
                      onChange={(e) => onCharacteristicValueChange({ to: e.target.value }, i)}
                    />
                  </>
                ) : (
                  <Select
                    name="withChar"
                    theme={selectThemeColors}
                    className="react-select"
                    classNamePrefix="select"
                    placeholder="search"
                    value={getOptionFromRawValue({
                      rawValue: value,
                      options: settingsDictionaries?.[characteristic] ?? []
                    })}
                    options={settingsDictionaries?.[characteristic] ?? []}
                    isClearable={false}
                    onChange={(opt) => onCharacteristicValueChange(opt, i)}
                  />
                )}
                <Button className="btn-icon ms-1" outline color="danger" onClick={() => onCharacteristicRemove(i)}>
                  <X size={12} />
                </Button>
              </Col>
            )}
          </Row>
        )
      })}

      {!isAllCharacteristicsSelected && (
        <Col xs={12}>
          <Button outline color="primary" onClick={onCharacteristicAdd}>
            <Plus size={14} />
            <span className="align-middle ms-25">Add Characteristic</span>
          </Button>
        </Col>
      )}
    </Col>
  )
}
