//@@ Libs
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { DataStore, Hub } from 'aws-amplify'

//@@ Store
import { GoalModel } from '@src/models'
import { getMainGoalSelector } from '@src/views/apps/goals/store/selectors'

const fetchChurchGoals = async ({ churchID }) => {
  return await DataStore.query(GoalModel, (goal) => goal.churchID('eq', churchID))
}

const updateDatastoreGoal = async (data) => {
  const original = await DataStore.query(GoalModel, data.id)
  const saved = await DataStore.save(
    GoalModel.copyOf(original, (updated) => {
      for (const key in data) {
        updated[key] = data[key]
      }
    })
  )

  const waitSync = () => {
    return new Promise((res) => {
      const subscription$ = DataStore.observe(GoalModel).subscribe((v) => {
        if (v.opType === 'UPDATE') {
          subscription$.unsubscribe()
          res(saved)
        }
      })
    })
  }
  await waitSync()
  return saved
}

export const updateGoal = createAsyncThunk('appGoals/update', async (data) => {
  const updatedGoal = await updateDatastoreGoal(data)
  const goals = await fetchChurchGoals({ churchID: updatedGoal.churchID })
  return { updatedGoal, goals }
})

export const getGoals = createAsyncThunk('appGoals/getAll', async ({ churchID }, { dispatch }) => {
  const goals = await fetchChurchGoals({ churchID })
  const primaryGoal = goals.find(({ isPrimary }) => isPrimary)

  // if there is no main goal -> set the first goal as main
  if (goals.length && !primaryGoal) {
    const [firstGoal] = goals
    const { payload } = await dispatch(updateGoal({ id: firstGoal.id, isPrimary: true }))
    return payload.goals
  }

  return goals
})

export const addGoal = createAsyncThunk('appGoals/create', async (data) => {
  return await DataStore.save(new GoalModel(data))
})

export const setPrimaryGoal = createAsyncThunk(
  'appGoals/setPrimaryGoal',
  async ({ goalId }, { getState, dispatch }) => {
    const state = getState()
    const primaryGoal = getMainGoalSelector(state)

    // make previous main goal non-primary
    await updateDatastoreGoal({
      id: primaryGoal.id,
      isPrimary: false
    })

    // set current goal as main goal
    const { payload } = await dispatch(
      updateGoal({
        id: goalId,
        isPrimary: true
      })
    )
    return payload.updatedGoal
  }
)

export const deleteGoal = createAsyncThunk('appGoals/delete', async (id, { dispatch }) => {
  const goalToDelete = await DataStore.query(GoalModel, id)
  DataStore.delete(goalToDelete)

  // if main goal was deleted, the first goal in the list will be set as main after fetching
  await dispatch(getGoals({ churchID: goalToDelete.churchID }))
  return id
})

export const goalsSlice = createSlice({
  name: 'goals',
  initialState: {
    data: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGoals.fulfilled, (state, action) => {
      state.data = action.payload
    })
    builder.addCase(addGoal.fulfilled, (state, action) => {
      state.data = [...state.data, action.payload]
    })
    builder.addCase(updateGoal.fulfilled, (state, action) => {
      const { goals } = action.payload
      state.data = goals
    })
    builder.addCase(deleteGoal.fulfilled, (state, action) => {
      state.data = state.data.filter(({ id }) => id !== action.payload)
    })
  }
}).reducer
