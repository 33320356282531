//@@ Libs
import {useState} from 'react'
import {useSelector} from 'react-redux'
import {Download, Save, Share} from 'react-feather'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {useNavigate, useParams} from 'react-router-dom'
import * as FileSaver from 'file-saver'

//@@ Constants
import {
    CSV_EXPORT_HEADER,
    CSV_EXPORT_TYPES,
    DISABLED_PRINT_WARNING,
    METRIC_TYPE_DESCRIPTION,
    METRIC_TYPE_TITLES
} from '@src/views/apps/reports/constants'

//@@ Utils
import {api} from '@src/services/api'
import {usePrint} from '@src/views/apps/reports/hooks/usePrint'
import {arrayToCsv} from '@utils'
import {getJWTToken} from "@src/utility/auth/tokens"

//@@ Alert init
const MySwal = withReactContent(Swal)

export const useDatabaseMenu = ({type, sources, printDisabled, csvRows}) => {
    //@@ Ref for export pdf container
    const church = useSelector((state) => state.churches.church)

    //@@ Router query params
    const {reportId} = useParams()
    const navigate = useNavigate()

    //@@ Use print & pdf download
    const {
        state: {printRef, printMenuItems}
    } = usePrint({
        titles: METRIC_TYPE_TITLES,
        descriptions: METRIC_TYPE_DESCRIPTION,
        type,
        sources,
        church,
        printDisabled
    })

    //@@ State
    const [isSaveModalOpen, setIsSaveModalOpen] = useState(false)
    const [isEdit, setIsEdit] = useState(false)

    //@@ Modal open state switch
    const handleOpenSaveModal = () => setIsSaveModalOpen(true)
    const handleCloseSaveModal = () => {
        setIsSaveModalOpen(false)
        if (isEdit) {
            setIsEdit(false)
        }
    }

    //@@ Save report handler
    const handleReportSave = () => {
        setIsEdit(true)
        handleOpenSaveModal()
    }

    //@@ Export in Csv
    const handleExport = () => {
        const csv = new Blob([arrayToCsv([...csvRows])], {type: 'text/csv;charset=utf-8;'})
        FileSaver.saveAs(csv, `${type}-export.csv`)
    }

    //@@ Actions for dropdown menu
    const menuItems = [
        ...printMenuItems,
        ...(CSV_EXPORT_TYPES.includes(type)
            ? [
                {
                    title: 'Export',
                    icon: <Share className="me-75" size={21}/>,
                    disabled: printDisabled,
                    warning: DISABLED_PRINT_WARNING,
                    onClick: handleExport
                }
            ]
            : []),
        ...(!!reportId
            ? [
                {
                    title: 'Save',
                    icon: <Save className="me-75" size={21}/>,
                    onClick: handleReportSave
                }
            ]
            : []),
        {
            title: 'Save As',
            icon: <Save className="me-75" size={21}/>,
            onClick: handleOpenSaveModal
        }
    ]

    //@@ Saving success alert
    const handleSavingSuccess = (id) => {
        return MySwal.fire({
            title: 'Report Saved',
            text: `This report has been saved in your profile under Saved Reports.`,
            icon: 'success',
            position: 'bottom-start',
            timer: 2500
        }).then(() => {
            handleCloseSaveModal()
            if (id) {
                navigate(id)
            }
        })
    }

    //@@ Settings save handler
    const handleSettingsSave = async ({name, type, settings, id}) => {
        const token = await getJWTToken()
        api.post('set-report-data', {name, type, settings, churchID: church.id, id, active: true},
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(({data}) => {
            const isDifferReport = !id || id !== reportId
            handleSavingSuccess(isDifferReport ? data.id : null)
        })
    }

    return {
        state: {menuItems, printRef, isSaveModalOpen, isEdit},
        methods: {handleCloseSaveModal, handleSettingsSave}
    }
}
