//@@ Libs
import { useSelector } from 'react-redux'

//@@ Store
import { authUserSelector } from '@store/selectors'

//@@ Constants
import { ADMIN_ROLE_NAME } from '@configs/environment'

//@@ Components
import { LogoBar } from '@layouts/components/header-navbar/LogoBar'
import UserDropdown from '@layouts/components/header-navbar/UserDropdown'
import { ChurchNavLink } from '@layouts/components/header-navbar/ChurchNavLink'
import { Notifications } from '@src/views/apps/notifications/Notifications'

//@@ Styles
import './index.scss'

export const HeaderNavbar = () => {
  //@@ Select user data from auth store
  const user = useSelector(authUserSelector)

  return (
    <ul className="d-flex align-items-center header-navbar-container nav navbar-nav justify-content-between flex-grow-1">
      <div className="d-flex align-items-center">
        <ChurchNavLink />
        {/* TODO hide temporary ss-166 */}
        {/*<SettingsDropdown />*/}
        {user?.role !== ADMIN_ROLE_NAME && <Notifications />}
      </div>
      <LogoBar />
      <div className="d-flex align-items-center">
        <UserDropdown />
      </div>
    </ul>
  )
}
