import { createAction, createSlice } from '@reduxjs/toolkit'

const SLICE_NAME = 'appProfileResolution'

export const resolveProfile = createAction('resolve_profile')

const profileResolutionReducer = createSlice({
  name: SLICE_NAME,
  initialState: {
    data: [],
    profileID: '110341552'
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(resolveProfile, (s) => {
      s.profileID = null
    })
  }
})

export default profileResolutionReducer.reducer
