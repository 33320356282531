//@@ Libs
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Settings, Power } from 'react-feather'
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap'

//@@ Components
import Avatar from '@components/avatar'

//@@ Store
import { useDispatch, useSelector } from 'react-redux'
import {useLogout} from '@store/authentication'

//@@ Default Avatar Image
const defaultAvatar = require('@src/assets/images/avatars/avatar-blank.png').default

const UserDropdown = () => {
  //@@ Store Vars
  const dispatch = useDispatch()
  const store = useSelector((state) => state.auth)

  //@@ State
  const [userData, setUserData] = useState(null)

  //@@ Set user data on mount
  useEffect(() => {
    if (store.userData) {
      setUserData(store.userData)
    }
  }, [store.userData])

  //@@ User avatar
  const userAvatar = (userData && userData.avatar) || defaultAvatar

  const {handleLogout} = useLogout()

  return (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
      <DropdownToggle href="/" tag="a" className="nav-link dropdown-user-link" onClick={(e) => e.preventDefault()}>
        <div className="user-nav d-sm-flex d-none">
          <span className="user-name fw-bold">{userData && userData['firstName']}</span>
          <span className="user-status">{userData && userData['lastName']}</span>
        </div>
        <Avatar img={userAvatar} imgHeight="40" imgWidth="40" status="online" />
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem tag={Link} to="/pages/account-settings">
          <Settings size={14} className="me-75" />
          <span className="align-middle">Settings</span>
        </DropdownItem>
        <DropdownItem tag={Link} to="/login" onClick={handleLogout}>
          <Power size={14} className="me-75" />
          <span className="align-middle">Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UserDropdown
