//@@ Libs
import { Col, Label, Row, Spinner } from 'reactstrap'
import Select from 'react-select'

//@@ Utils
import { getOptionFromRawValue, getOptionsFromRawValues, getRawValueFromOption, selectThemeColors } from '@utils'

//@@ Constants
import { TOTAL_OPTIONS } from '@src/views/apps/reports/constants'

//@@ Components
import UILoader from '@components/ui-loader'
import { Characteristic } from '@src/views/apps/reports/components/Characteristic/Characteristic'
import { FormSelect } from '@src/views/apps/reports/components/FormElements/FormSelect'
import { FormBetweenRange } from '@src/views/apps/reports/components/FormElements/FormBetweenRange'
import { FormForTheseSelect } from '@src/views/apps/reports/components/FormElements/FormForTheseSelect'
import { AdaptiveLabel } from '@src/views/apps/reports/components/AdaptiveLabel/AdaptiveLabel'

//@@ Styles
import '@styles/react/libs/flatpickr/flatpickr.scss'

export const ConsolidateForm = ({
  isLoading,
  settings,
  settingsDictionaries,
  onSettingChange,
  onCharacteristicChange,
  onCharacteristicAdd,
  onCharacteristicValueChange,
  onCharacteristicRemove
}) => {
  return (
    <>
      <UILoader loader={<Spinner />} blocking={isLoading}>
        <FormSelect
          isRequired
          value={getOptionFromRawValue({ rawValue: settings.showMeCategory, options: TOTAL_OPTIONS })}
          options={TOTAL_OPTIONS}
          label="Show Me The Total And The Average"
          onChange={(opt) => onSettingChange({ name: 'showMeCategory', value: getRawValueFromOption(opt) })}
        />

        <Row className="mb-2">
          <AdaptiveLabel>
            The Following Service/Event
            <span className="requiredFormLabelAsterisk">*</span>
          </AdaptiveLabel>
          <Col sm="8" xs={12}>
            <Select
              isClearable={false}
              name="service"
              theme={selectThemeColors}
              className="react-select"
              classNamePrefix="select"
              placeholder="search"
              options={settingsDictionaries.uniqueEvents}
              value={getOptionFromRawValue({
                rawValue: settings.uniqueEvent,
                options: settingsDictionaries.uniqueEvents
              })}
              onChange={(opt) => onSettingChange({ name: 'uniqueEvent', value: getRawValueFromOption(opt) })}
            />
          </Col>
        </Row>

        <FormBetweenRange
          isRequired
          label="Between"
          settingDates={settings.betweenDates}
          onSettingChange={onSettingChange}
        />

        <FormForTheseSelect
          label="For These"
          categoriesOptions={settingsDictionaries.membership}
          categoriesValue={getOptionsFromRawValues({
            rawValue: settings.membership,
            options: settingsDictionaries.membership
          })}
          individual={settings.individual}
          onSettingChange={onSettingChange}
        />

        <Row className="mb-2">
          <AdaptiveLabel className="align-self-start">With These</AdaptiveLabel>
          <Characteristic
            characteristics={settings.characteristics}
            settingsDictionaries={settingsDictionaries}
            onCharacteristicChange={onCharacteristicChange}
            onCharacteristicAdd={onCharacteristicAdd}
            onCharacteristicValueChange={onCharacteristicValueChange}
            onCharacteristicRemove={onCharacteristicRemove}
          />
        </Row>
      </UILoader>
    </>
  )
}
