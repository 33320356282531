//@@ Libs
import { Link } from 'react-router-dom'
import classnames from 'classnames'

//@@ Utils
import { hasActiveChild } from '@layouts/utils'

//@@ Hooks
import { useLayoutMenu } from '@layouts/hooks/useLayoutMenu'

//@@ Horizontal Menu Items Component
import HorizontalNavMenuItems from './HorizontalNavMenuItems'

const HorizontalNavMenuGroup = (props) => {
  //@@ Props
  const { item, submenu, isChild } = props

  //@@ Hooks
  const {
    state: { menuOpen, currentURL, t, styles, attributes },
    methods: { setMenuOpen, handleMouseEnter, setReferenceElement, setPopperElement }
  } = useLayoutMenu({ isChild })

  return (
    <li
      ref={setReferenceElement}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setMenuOpen(false)}
      className={classnames('dropdown', {
        show: menuOpen,
        'nav-item': submenu === false,
        'dropdown-submenu': submenu === true,
        'sidebar-group-active active': hasActiveChild(item, currentURL)
      })}
    >
      <Link
        to="/"
        onClick={(e) => e.preventDefault()}
        className={classnames('dropdown-toggle d-flex align-items-center', {
          'dropdown-item': submenu === true,
          'nav-link': submenu === false
        })}
      >
        {item.icon}
        <span>{t(item.title)}</span>
      </Link>
      <ul
        ref={setPopperElement}
        style={{ ...styles.popper }}
        {...attributes.popper}
        className={classnames('dropdown-menu', { 'first-level': submenu === false })}
      >
        <HorizontalNavMenuItems
          isChild={true}
          submenu={true}
          parentItem={item}
          menuOpen={menuOpen}
          items={item.children}
          setMenuOpen={setMenuOpen}
        />
      </ul>
    </li>
  )
}

export default HorizontalNavMenuGroup
