import { useCallback } from 'react'
// ** Redux Imports
import {useDispatch} from "react-redux"
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'

import { useAuth } from '@hooks/useAuth'
import { Auth, DataStore } from 'aws-amplify'
import { addUser } from '@src/views/admin/apps/staff/store'
import { ChurchModel, UserProfileModel } from '@src/models'
import { updateUser } from '@src/redux/usersSlice/usersSlice'
import { CHURCH_ROLE_NAME } from '@configs/environment'
import { clearDb, startDb } from "@store/datastore"


const config = useJwt.jwtConfig

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

export const getUserData = createAsyncThunk('auth/getUserData', async (id) => {
  let userProfile = (await DataStore.query(UserProfileModel, (e) => e.cognitoId('eq', id)))[0]

  if (!userProfile?.hasOwnProperty('id')) {
    userProfile = (await DataStore.query(UserProfileModel, (e) => e.cognitoId('eq', id)))[0]
  }

  return userProfile
})

export const setMFA = createAsyncThunk('auth/setMFA', async (user) => {
  const cognitoUser = user || useAuth.user
  const userProfile = await DataStore.query(UserProfileModel, (u) => u.cognitoId('eq', cognitoUser.username))
  const mfaType = userProfile[0].mfaType
  try {
    return await Auth.setPreferredMFA(cognitoUser, mfaType)
  } catch (e) {
    console.log(e)
    throw e
  }
})

export const signIn = createAsyncThunk('auth/signin', async ({ email, password }, { getState, dispatch }) => {
  try {
    const user = await Auth.signIn(email, password)
    return user
  } catch (e) {
    console.log(e)
    throw e
  }
})

export const signUp = createAsyncThunk('auth/signUp', async ({ data, attributes, church }, { dispatch, getState }) => {
  //need to save the profile info into the cognito "profile" attribute for now
  //on first login, the actual profile will be created
  const profileAttr = JSON.stringify({
    firstName: attributes.firstName,
    lastName: attributes.lastName,
    churchID: church,
    mfaType: getState().auth.selectedMfa,
    role: attributes.role ? attributes.role : null 
  })
  let user
  if (getState().auth.selectedMfa === 'TOTP') {
    user = await Auth.signUp({ ...data, attributes: { email: attributes.email, profile: profileAttr } })
  } else {
    user = await Auth.signUp({ ...data, attributes: { email: attributes.email, phone_number: attributes.phone, profile: profileAttr } })
  }
  return { ...data, phone_number: attributes.phone }
})

export const forgotPassword = createAsyncThunk('auth/forgot', async (user) => {
  return await Auth.forgotPassword(user)
})

export const setupTOTP = createAsyncThunk('auth/setupTOTP', async () => {
  return await Auth.setupTOTP(useAuth.user)
})
export const setPhoneNumber = createAsyncThunk('auth/setPhoneNumber', async (cognitoUser) => {
  const userProfile = await DataStore.query(UserProfileModel, (e) => e.cognitoId('eq', cognitoUser.username))
  return await Auth.updateUserAttributes(cognitoUser, { phone_number: userProfile[0].phone })
})
export const confirmSignIn = createAsyncThunk('auth/confirmSignIn', async ({ code, type }) => {
  const response = await Auth.confirmSignIn(useAuth.user, code, type)
  return response.data
})

export const verifyTOTP = createAsyncThunk('auth/verifyTOTP', async (code) => {
  const data = await Auth.verifyTotpToken(useAuth.user, code)
  return data
})

export const isUserAuthed = createAsyncThunk('auth/isUserAuthed', async (data, { dispatch, getState }) => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    await dispatch(getUserData(user.username))
    if (!user.attributes?.phone_number) {
      await dispatch(setPhoneNumber(user))
    }
    if (user.preferredMFA === 'NOMFA') {
      await dispatch(setMFA(user))
    }
    return getState().auth.userData
  } catch (err) {
    console.log(err)
    throw err
  }
})

export const confirmSignUp = createAsyncThunk('auth/confirmSignUp', async ({ username, code }) => {
  const r = await Auth.confirmSignUp(username, code)
  return r
})

//db ready and role valudated functions to work with appsync cognito authentication  
export const setDbReady = createAsyncThunk('auth/setDbReady', async (dbStatus) => {
  return dbStatus
})

export const validateUserRole = createAsyncThunk('auth/validateUserRole', async (data, {dispatch, getState}) => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    if (user.attributes.profile) {
      //this is first time user is logging in, make a profile for them
      const profileAttr = JSON.parse(user.attributes.profile)
      await dispatch(
        addUser({
          cognitoId: user.attributes.sub,
          email: user.attributes.email,
          phone: user.attributes.phone_number,
          isActive: true,
          ...profileAttr
        })
      )
      //delete the profile attribute from cognito user (which means it was added into graphql)
      await Auth.deleteUserAttributes(user, ['profile'])
    }

    //proceed with role validation
    await dispatch(getUserData(user.username))
    const userProfile = getState().auth.userData
    const churchData = await DataStore.query(ChurchModel, userProfile.churchID)

    let roleError = ""
    if (userProfile.role === CHURCH_ROLE_NAME && churchData.suspended) {
      roleError = 'Your church is suspended. Please, contact administrator'
    }
    if (userProfile && !userProfile.isActive) {
      roleError = 'Your account is not active. Please, contact administrator'
    }
    if (roleError !== "") {
      // role validation failed, clear db and then raise error
      // force clear db
      dispatch(clearDb())
      throw roleError
    }

    return user
  } catch (e) {
    console.log(e)
    throw e
  }
})

const initialState = {
  //@todo remove this and store data in users slice to avoid data duplication
  userData: initialUser(),
  isPartialyLogged: false,
  forgotPasswordSent: false,
  totpCode: false,
  signInConfirmed: false,
  tokenVerified: false,
  isAuthed: null,
  navigateTo: null,
  selectedMfa: null,
  mfaSet: false,
  totpVerified: false,
  abilities: [],
  userStateChecked: false,
  rejectedMessage: null,
  //db ready and role validated states to work with appsync cognito authentication  
  isDbReady: false,
  isRoleValidated: false
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    resetRejectedMessage: (state) => {
      state.rejectedMessage = null
    },
    resetState: (state) => {
      state.totpCode = false
      state.isPartialyLogged = false
      state.tokenVerified = false
      state.totpVerified = false
      state.signInConfirmed = false
      //db ready and role valudated status to work with appsync cognito authentication  
      state.isRoleValidated = false
    },
    setMfaType: (state, action) => {
      state.selectedMfa = action.payload
    },
    setNavigateTo: (state, action) => {
      state.navigateTo = action.payload
    },
    handleLogin: (state, action) => {
      state.userData = action.payload
      state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
    },
    handleLogout: (state) => {
      state.userData = {}
      state.isAuthed = false
      state.isPartialyLogged = false
      state.isRoleValidated = false
      state.isDbReady = false
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      localStorage.clear()
      // ** Remove user, accessToken & refreshToken from localStorage
    }
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.fulfilled, (state, action) => {
      useAuth.user = action.payload
      state.isPartialyLogged = true
      state.rejectedMessage = null
    })
    builder.addCase(signIn.rejected, (state, action) => {
      useAuth.user = null
      state.isPartialyLogged = false
      state.rejectedMessage = action.error.message
    })
    builder.addCase(forgotPassword.fulfilled, (state) => {
      state.forgotPasswordSent = true
    })
    builder.addCase(setupTOTP.fulfilled, (state, action) => {
      state.totpCode = action.payload
      state.totpVerified = false
    })
    builder.addCase(confirmSignIn.fulfilled, (state, action) => {
      state.signInConfirmed = true
      state.totpCode = false
      state.isPartialyLogged = false
      state.isRoleValidated = false
      state.userData = action.payload
      try {
        state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
        state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      } catch (e) {}
    })
    builder.addCase(confirmSignIn.rejected, (state) => {
      state.signInConfirmed = false
    })
    builder.addCase(isUserAuthed.fulfilled, (state, action) => {
      state.isAuthed = true
      state.userData = action.payload
      state.userStateChecked = true
      state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      //also clear any partial login variables
      state.isPartialyLogged = false
      state.signInConfirmed = false
      state.isRoleValidated = false
    })

    builder.addCase(isUserAuthed.rejected, (state) => {
      state.isAuthed = false
      state.userStateChecked = true
    })
    builder.addCase(signUp.fulfilled, (state) => {
      state.navigateTo = '/pages/two-steps-cover'
    })
    builder.addCase(confirmSignUp.fulfilled, (state) => {
      state.totpCode = false
      state.isPartialyLogged = false
      state.isRoleValidated = false
      state.navigateTo = '/'
      localStorage.clear()
    })
    builder.addCase(setMFA.fulfilled, (state) => {
      state.mfaSet = true
    })
    builder.addCase(setMFA.rejected, (state) => {
      state.mfaSet = false
    })
    builder.addCase(setPhoneNumber.rejected, (STATE, action) => {
      console.log(action)
    })
    builder.addCase(verifyTOTP.fulfilled, (state, action) => {
      state.totpVerified = true
      state.totpCode = false
      state.isPartialyLogged = false
      state.isRoleValidated = false
      state.isAuthed = true
      localStorage.clear()
    })
    builder
      .addCase(getUserData.fulfilled, (state, action) => {
        state.userData = action.payload
      })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        // syncing authed user data if it was updated
        //@ todo remove state.userData and keep authed user data in users slice
        if (state.userData?.id === payload.userData?.id) {
          state.userData = payload.userData
        }
      })

    // db ready and role validated cases to work with appsync cognito authentication  
    builder.addCase(setDbReady.fulfilled, (state, action) => {
      state.isDbReady = action.payload
    })
    builder.addCase(validateUserRole.fulfilled, (state, action) => {
      state.isRoleValidated = true
    })
    builder.addCase(validateUserRole.rejected, (state, action) => {
      useAuth.user = null
      state.userData = {}
      state.isPartialyLogged = false
      state.signInConfirmed = false
      state.rejectedMessage = action.error.message
      state.isRoleValidated = false
      state.isDbReady = false //upon failing role validation, db should be cleared, also need to set isDbReady to false
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      localStorage.clear() //failing role validation force clear everthing in local storage      
    })
  }
})

// FIXME: This has a bug
// If a user logs out and then logs in again without refreshing the page, it fails

export const useLogout = () => {
    const dispatch = useDispatch()
    const handleLogout = useCallback(() => {
        dispatch(clearDb())
        dispatch(authSlice.actions.handleLogout())
    }, [dispatch])
    return {
        handleLogout
    }
}

export const { handleLogin, handleLogout, setMfaType, setNavigateTo, resetState, resetRejectedMessage } =
  authSlice.actions

export default authSlice.reducer
