import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {getJWTToken} from "@src/utility/auth/tokens"

export const singleSourceApiSlice = createApi({
  reducerPath: 'singleSourceApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env?.['REACT_APP_API_NAME'],
    prepareHeaders: async (headers) => {
        const token = await getJWTToken()
        if (token) {
            headers.set('Authorization', `Bearer ${token}`)
        }
        return headers

    }
  }),
  endpoints: (builder) => ({
    getUserTimeline: builder.query({
      query: (body) => ({
        url: '/get-user-timeline',
        method: 'POST',
        body
      })
    }),
    getChurchProfiles: builder.query({
      query: (body) => {
        return {
          url: '/get-church-profiles-offset',
          method: 'POST',
          body
        }
      },
      transformResponse: (res) => {
        return res
      }
    }),
    getChurchProfileInfo: builder.query({
      query: (body) => {
        return {
          url: '/get-church-profile-info',
          method: 'POST',
          body
        }
      },
      transformResponse: (res) => {
        return res
      }
    }),
    getChurchProfileSubscriptions: builder.query({
      query: (body) => {
        return {
          url: '/get-church-profile-subscriptions',
          method: 'POST',
          body
        }
      },
      transformResponse: (res) => {
        return res
      }
    })
  })
})

export const { useGetUserTimelineQuery, useGetChurchProfilesQuery, useGetChurchProfileInfoQuery, useGetChurchProfileSubscriptionsQuery } = singleSourceApiSlice
