//@@ Constants
import { WEB_INSIGHTS_TYPE } from '@src/views/apps/reports/constants'

//@@ Utils
import { useInsightsActions } from '@src/views/apps/reports/features/WebInsights/hooks/useInsightsActions'

//@@ Components
import { ReportWrapper } from '@src/views/apps/reports/features/WebInsights/components/ReportWrapper/ReportWrapper'
import { ReportFormWrapper } from '@src/views/apps/reports/features/WebInsights/components/ReportFormWrapper/ReportFormWrapper'
import { CohortForm } from '@src/views/apps/reports/features/WebInsights/features/Cohort/components/CohortForm'
import { CohortResults } from '@src/views/apps/reports/features/WebInsights/features/Cohort/components/CohortResults'

export const CohortReport = () => {
  //@@ Insights actions
  const { isShown, handleShowToggle } = useInsightsActions()

  return (
    <>
      <ReportFormWrapper type={WEB_INSIGHTS_TYPE.cohort}>
        <ReportWrapper onSaveClick={handleShowToggle}>
          <CohortForm />
        </ReportWrapper>
        {isShown && <CohortResults />}
      </ReportFormWrapper>
    </>
  )
}
