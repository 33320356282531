//@@ Constants
import { METRIC_TYPE } from '@src/views/apps/reports/constants'

//@@ Components
import { ConsolidateForm } from '@src/views/apps/reports/features/Database/features/Consolidate/components/ConsolidateForm'
import { ParticipationForm } from '@src/views/apps/reports/features/Database/features/Participation/components/ParticipationForm'
import { EngagementForm } from '@src/views/apps/reports/features/Database/features/Engagement/components/EngagementForm'
import { JourneyForm } from '@src/views/apps/reports/features/Database/features/Journey/components/JourneyForm'
import { TimeForm } from '@src/views/apps/reports/features/Database/features/Time/components/TimeForm'

export const DatabaseFormWrapper = (props) => {
  //@@ Return form by report type
  switch (props.type) {
    case METRIC_TYPE.consolidate: {
      return <ConsolidateForm {...props} />
    }
    case METRIC_TYPE.participation: {
      return <ParticipationForm {...props} />
    }
    case METRIC_TYPE.engagement: {
      return <EngagementForm {...props} />
    }
    case METRIC_TYPE.journey: {
      return <JourneyForm {...props} />
    }
    case METRIC_TYPE.time: {
      return <TimeForm {...props} />
    }
    default: {
      return null
    }
  }
}
