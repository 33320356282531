//@@ Libs
import {useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {useEffect, useState} from 'react'

//@@ Utils
import {api} from '@src/services/api'
import {getJWTToken} from "@src/utility/auth/tokens"

export const useReportData = () => {
    //@@ Select church
    const church = useSelector((state) => state.churches.church)
    //@@ Router query params
    const {reportId} = useParams()

    //@@ Report state
    const [report, setReport] = useState(null)

    //@@ Load report data if on concrete report page
    useEffect(() => {
        if (reportId) {
            getJWTToken().then(token => api.post('get-report-data', {churchID: church?.id, reportID: reportId},
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })).then(({data}) => {
                setReport(data)
            })
        }
    }, [reportId])

    return report
}
