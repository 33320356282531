const CONNECTIONS_MODALS = {
  upload: 'upload',
  template: 'template'
}

const UPLOAD_TYPE = {
  upload: 'Profile',
  payments: 'payments',
  events: 'events'
}

const UPLOAD_TYPE_OPTIONS = [
  { value: UPLOAD_TYPE.upload, label: 'Profiles' },
  { value: UPLOAD_TYPE.payments, label: 'Giving' },
  { value: UPLOAD_TYPE.events, label: 'Events' }
]

const PLATFORM_CATEGORIES = [
  { label: 'Giving', value: 'giving' },
  { label: 'Sunday Morning Attendance', value: 'attendance' },
  { label: 'Cell Group', value: 'cell_group' },
  { label: 'Bible Study', value: 'bible_study' },
  { label: 'Membership', value: 'membership' },
  { label: 'Ministry', value: 'ministry' },
  { label: 'Social Media', value: 'social_media' }
]

const CATEGORIES_LABEL = {
  giving: 'Gave',
  attendance: 'Attended',
  cell_group: 'Attended',
  ministry: 'Joined',
  membership: 'Joined',
  events: 'Attended',
  comment: 'Commented On',
  bible_study:'Attended'
}

export { CONNECTIONS_MODALS, UPLOAD_TYPE, PLATFORM_CATEGORIES, UPLOAD_TYPE_OPTIONS, CATEGORIES_LABEL }
