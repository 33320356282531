//@@ Libs
import { Navbar } from 'reactstrap'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'

//@@ Hooks
import { useSkin } from '@hooks/useSkin'

//@@ Utils
import { canViewMenuItem } from '@layouts/utils'

//@@ Styles
import './index.scss'

export const MainMenuVertical = ({ isHidden, menuData }) => {
  const { skin } = useSkin()

  if (isHidden) return null

  const accessibleMenuItems = menuData.filter(canViewMenuItem)

  return !isHidden ? (
    <Navbar
      tag="div"
      expand="sm"
      light={skin !== 'dark'}
      dark={skin === 'dark'}
      className="position-fixed web-kit-sticky bottom-0 start-0 end-0 vertical-menu-navbar main-menu-vertical"
    >
      <div
        className={classNames(
          'd-flex w-100',
          // if there are 2 menu items, justify-content-between isn't gonna work because the menu items will be displayed on screen edges
          accessibleMenuItems.length > 2 ? 'justify-content-between' : 'justify-content-center'
        )}
      >
        {accessibleMenuItems.map((item, idx) => {
          return (
            <NavLink key={idx} to={item.navLink} className="d-flex flex-column align-items-center text-center link">
              <div>{item.icon}</div>
              <div className="link-title">{item.title}</div>
            </NavLink>
          )
        })}
      </div>
    </Navbar>
  ) : null
}
