//@@ Constants
import { METRIC_TYPE } from '@src/views/apps/reports/constants'

//@@ Components
import { ConsolidateChart } from '@src/views/apps/reports/features/Database/features/Consolidate/components/ConsolidateChart'
import { ParticipationChart } from '@src/views/apps/reports/features/Database/features/Participation/components/ParticipationChart'
import { EngagementChart } from '@src/views/apps/reports/features/Database/features/Engagement/components/EngagementChart'
import { JourneyChart } from '@src/views/apps/reports/features/Database/features/Journey/components/JourneyChart'
import { TimeChart } from '@src/views/apps/reports/features/Database/features/Time/components/TimeChart'

export const DatabaseChartWrapper = (props) => {
  //@@ Return chart by database report type
  switch (props.type) {
    case METRIC_TYPE.consolidate: {
      return <ConsolidateChart {...props} />
    }
    case METRIC_TYPE.participation: {
      return <ParticipationChart {...props} />
    }
    case METRIC_TYPE.engagement: {
      return <EngagementChart {...props} />
    }
    case METRIC_TYPE.journey: {
      return <JourneyChart {...props} />
    }
    case METRIC_TYPE.time: {
      return <TimeChart {...props} />
    }
    default: {
      return null
    }
  }
}
