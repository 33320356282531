//@@ Libs
import { Link } from 'react-router-dom'
//@@ Constants
import { COPYRIGHT } from '@src/constants/main'

const Footer = () => {
  return (
    <p className="clearfix mb-0">
      <span className="float-md-start d-block d-md-inline-block mt-25">
        COPYRIGHT © {new Date().getFullYear()} <Link to="/">{COPYRIGHT}</Link>
        <span className="d-none d-sm-inline-block">, All rights Reserved</span>
      </span>
      <a href="https://knowyourcross.net" target="_blank" className="float-md-end d-none d-md-block">
        Powered by Know Your Cross, Inc.
      </a>
    </p>
  )
}

export default Footer
