const CheckIcon = () => {
    return (
        <img
            alt="Check"
            src={require('@src/assets/images/icons/check.png').default}
            height="34"
            width="34"
        />
    )
}

export default CheckIcon