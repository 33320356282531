//@@ Libs
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DataStore, SortDirection } from 'aws-amplify'
import { UserProfileModel } from '@src/models'

//@@ Store
import { createUser, deleteUser, updateUser } from '@src/redux/usersSlice/usersSlice'
import { ADMIN_ROLE_NAME } from '@configs/environment'

export const getData = createAsyncThunk('appUsers/getData', async (params) => {
  const data = await DataStore.query(
    UserProfileModel,
    (model) => {
      let filteredModel = model.or((c) => c.role('eq', ADMIN_ROLE_NAME).role('eq', null))

      if (params?.status !== '') {
        filteredModel = filteredModel.or((c) => c.isActive('eq', params?.status === 'active'))
      }

      return filteredModel.or((m) => {
        return m
          .firstName('contains', params?.q)
          .lastName('contains', params?.q)
          .email('contains', params?.q)
          .phone('contains', params?.q)
      })
    },
    {
      sort: (model) => {
        const awsSortingDir = params?.sort === 'desc' ? SortDirection.DESCENDING : SortDirection.ASCENDING
        return model[params?.sortColumn](awsSortingDir)
      },
      page: params?.page,
      limit: params?.perPage
    }
  )

  return {
    params,
    data
  }
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await dispatch(createUser({ user }))
  await dispatch(getData(getState().users.params))
  return user
})

export const deleteStaffUser = createAsyncThunk('appUsers/deleteUser', async ({ userId }, { dispatch, getState }) => {
  await dispatch(deleteUser({ userId }))
  await dispatch(getData(getState().users.params))
  return userId
})

export const activateUser = createAsyncThunk('appUsers/activateUser', async ({ id, role }, { dispatch }) => {
  await dispatch(updateUser({ userId: id, userData: { role, isActive: true } }))
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    /** array of user ids in the table */
    data: [],
    total: 1,
    params: {},
    selectedUser: null,
    userActivated: null,
    activationOpen: false,
    userForActivation: null
  },
  reducers: {
    setActivationOpen: (state, action) => {
      state.activationOpen = action.payload
      if (action.payload === false) {
        state.userForActivation = null
      }
    },
    setUserForActivation: (state, action) => {
      state.userForActivation = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(activateUser.fulfilled, (state) => {
        state.userActivated = true
        state.activationOpen = false
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.data = state.data.filter((userId) => userId !== action.payload)
      })
  }
})
export const { setActivationOpen, setUserForActivation } = appUsersSlice.actions
export default appUsersSlice.reducer
