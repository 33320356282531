const DEFAULT_DEBOUNCE_TIMER = 800

const ABILITIES = {
  read: 'read',
  manage: 'manage'
}

const SKIN = {
  dark: 'dark',
  light: 'light'
}

const COPYRIGHT = 'SingleSource'

export { DEFAULT_DEBOUNCE_TIMER, ABILITIES, SKIN, COPYRIGHT }
